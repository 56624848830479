import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  IonCard,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useParams } from "react-router";
import logo from "../pages/images/image6.jpg";

import { List, Avatar, Divider, Col, Row, Tag, message, Modal } from "antd";
import { Drawer, Form, Button, Input, Select, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";

const DescriptionItem = (title: any, content: any) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const ComplienceOfficer: React.FC = () => {
  const [data, setdata] = useState<any[]>([]);
  const { TextArea } = Input;
  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedcompofficer",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/compofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              axios
                .get(
                  "https://dev.northroom.co.za/api/spfund/users/projectlistall/compofficer"
                )
                .then(function (response) {
                  console.log(response.data);

                  setdata(response.data);
                })
                .catch(function (error) {
                  // handle error
                  console.log(error);
                })
                .then(function () {
                  // always executed
                });
            });
        });
    } catch (error: any) {
      message.error(error.data);
    }
  }
  async function ExceptionReport(object: any) {
    object.CompOfficerExceptioncomment = exception;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedcompofficerexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/compofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error(error);
    }
  }

  useIonViewWillEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/users/projectlistall/compofficer"
      )
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  //modal
  const [exception, setException] = useState("");
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setException("");
    setVisible(false);
  };

  const handleComment = (object: any) => {
    console.log(object);
    ExceptionReport(object);
    setVisible(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Compliance Officer Dashboard"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <>
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={<a>User Application ID:{dataobject.id}</a>}
                      description="Awaiting Compliance Verification"
                    />

                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            ExceptionReport(dataobject);
                          }}
                          type="primary"
                        >
                          Create Exception Report
                        </Button>
                      </IonCol>

                      <IonCol></IonCol>
                    </IonRow>

                    <Tag>
                      <a href={dataobject.CreditagreementForm} download>
                        Credit Agreement
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.BaselineForm} download>
                        Baseline Report
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.JVForm} download>
                        Joint Venture Agreement
                      </a>
                    </Tag>
                    {/* <Tag>
      <a href={dataobject.Brief} download>Project Brief</a>
      </Tag> */}

                    <Tag>
                      <a href={dataobject.BBEECert} download>
                        B-BBEE Certificate
                      </a>
                    </Tag>
                  </List.Item>

                  <List.Item>
                    <Tag>
                      <a href={dataobject.Spif} download>
                        Signed SPIF Agreement
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.BOQ} download>
                        Priced Bills of Quantities{" "}
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.ImplementationPlan} download>
                        Implementation Plan
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.Letterofappointment} download>
                        Letter of appointment
                      </a>
                    </Tag>

                    <Tag>
                      <a href={dataobject.BankingStatements} download>
                        Banking statements
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.ManagementAccounts} download>
                        Management accounts
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.Insurance} download>
                        Proof of Insurance
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.CSD} download>
                        CSD registration report
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.Companyregdocuments} download>
                        Company registration documents{" "}
                      </a>
                    </Tag>
                  </List.Item>
                  <List.Item>
                    <Tag>
                      <a href={dataobject.CIDBDoc} download>
                        CIDB Certificate
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.NHBRC} download>
                        NHBRC certificate
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.TaxClearanceDoc} download>
                        Tax Clearance Certificate and tax pin
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.Vat} download>
                        VAT Certificate
                      </a>
                    </Tag>

                    <Tag>
                      <a href={dataobject.DepartLabor} download>
                        Department of Labour
                      </a>
                    </Tag>
                    {/* <Tag>
      <a href={dataobject.DepartLabor} download>Proof of Address</a>
      </Tag>  */}
                    <Tag>
                      <a href={dataobject.personnel} download>
                        Company Personnel{" "}
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.AllDirectors} download>
                        Director ID
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.MartialSTatus} download>
                        Marital status
                      </a>
                    </Tag>
                    <Tag>
                      <a href={dataobject.companyprofile} download>
                        Company profile
                      </a>
                    </Tag>
                  </List.Item>
                  <Modal
                    title="Exception"
                    visible={visible}
                    onCancel={handleCancel}
                    width={500}
                    centered
                    onOk={() => {
                      handleComment(dataobject);
                    }}
                  >
                    <IonRow>
                      <IonCol>
                        <TextArea
                          value={exception}
                          rows={4}
                          onChange={(e) => { setException(e.target.value) }}
                          name="exception"
                          placeholder="exception comment"
                        />
                      </IonCol>
                    </IonRow>
                  </Modal>

                </>
              ))
            }
          />
        </>
      </IonContent>
    </IonPage>
  );
};

export default ComplienceOfficer;
