import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Tooltip,
  Empty,
  Select,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag, Steps } from "antd";
import { DatePicker, Space, Button, Modal } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { getDocsArray } from "../store/actions";
const dateFormat = "YYYY/MM/DD";

const TECVoting: React.FC = (props: any) => {
  const [vote, setVote] = useState("Unvoted");
  const [data, setdata] = useState<any[]>([]);
  const { Step } = Steps;
  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const [globalObject, setGlobalObject] = useState({});
  const [AllFiles, setAllFiles] = useState<any[]>([]);
  const [comment, setComment] = useState("");
  const [isModalProjectVisable, setisModalProjectVisable] = useState(false);

  const [recordOfDecision, setRecordOfDecision] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isModal2Visible, setIsModal2Visible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    ApproveProject(globalObject);
    setIsModalVisible(false);
  };
  const handleProjectOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal2 = (myVote: any) => {
    setIsModal2Visible(true);
    setVote(myVote);
  };

  const handleOk2 = () => {
    setIsModal2Visible(false);
  };

  const handleCancel2 = () => {
    setIsModal2Visible(false);
  };

  const recordText = <span>View record of decision.</span>;

  const voteText = <span>Voting decision.</span>;

  const commentText = (
    <span>Cast your vote before providing a recommendation.</span>
  );

  const buttonWidth = 70;

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    setRecordOfDecision(vote + "-" + comment);
    object.RECORDOFDECISION = vote + "-" + comment;
    console.log(object);
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedTEC",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/TEC"
            )
            .then(function (response) {
              console.log(response.data);
              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
    // if (vote === "No") {
    //   try {
    //     await axios.post(
    //       "https://dev.northroom.co.za/api/spfund/users/messageuser/",
    //       {
    //         userid: user.id,
    //         message:
    //           user.FullName +
    //           " Your project has been declined, await contact from your project manager",
    //       }
    //     );
    //   } catch (error:any) {
    //     console.error(error);
    //   }
    // } else if (vote === "Yes") {
    //   try {
    //     await axios.post(
    //       "https://dev.northroom.co.za/api/spfund/users/messageuser/",
    //       {
    //         userid: user.id,
    //         message:
    //           user.FullName +
    //           " Your project has passed the TEC vote and will now move onto the next phase",
    //       }
    //     );
    //   } catch (error:any) {
    //     console.error(error);
    //   }
    // }

    handleOk2();
  }
  async function ExceptionReport(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedTECrexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/TEC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
              setRecordOfDecision(response.data.VotingComment);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
  }
  async function DownloadPack(object: any) {
    //turn docs file links into an array

    let linkarr = [];

    linkarr.push(object.Spif);
    linkarr.push(object.BOQ);
    linkarr.push(object.ImplementationPlan);
    linkarr.push(object.Letterofappointment);
    linkarr.push(object.BankingStatements);
    linkarr.push(object.ManagementAccounts);
    linkarr.push(object.Insurance);
    linkarr.push(object.CIDBDoc);
    linkarr.push(object.NHBRC);
    linkarr.push(object.TaxClearanceDoc);
    linkarr.push(object.Vat);
    linkarr.push(object.DepartLabor);
    linkarr.push(object.personnel);
    linkarr.push(object.AllDirectors);
    linkarr.push(object.MartialSTatus);
    linkarr.push(object.BBEECert);
    linkarr.push(object.Companyregdocuments);
    linkarr.push(object.ApplicationForm);
    linkarr.push(object.BaselineForm);
    linkarr.push(object.CIPCForm);
    linkarr.push(object.CreditagreementForm);
    linkarr.push(object.companyprofile);
    linkarr.push(object.ProofAddress);
    linkarr.push(object.DeclarationForm);
    linkarr.push(object.JVForm);
    linkarr.push(object.EligibilityCriteriaForm);
    linkarr.push(object.Resolution);
    linkarr.push(object.ShareholdingConfirmation);
    linkarr.push(object.TaxPin);
    linkarr.push(object.BOQ2);
    linkarr.push(object.CompletionCertificate);
    linkarr.push(object.CPO);
    linkarr.push(object.RFQ);
    linkarr.push(object.SuppliersQ);
    linkarr.push(object.Cinvoice);
    linkarr.push(object.DebtAge);
    linkarr.push(object.EditorsAge);
    linkarr.push(object.PoCl);
    linkarr.push(object.MonthBankState);
    linkarr.push(object.BillofQuanties);
    linkarr.push(object.SLA);
    linkarr.push(object.CletterofAppointment);
    linkarr.push(object.ProjectImplentaionPlan);
    linkarr.push(object.CinvoicePaidbyPayMaster);
    linkarr.push(object.PaymentCert);
    linkarr.push(object.DebtandCred);

    linkarr.map((mainurl) => {
      if (mainurl.length !== 0) {
        axios({
          url: mainurl,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            mainurl.replace(
              " https://dev.northroom.co.za/api/spfund/static/",
              ""
            )
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    });
  }
  //   const downloadFiles = (e:any) => {
  //   e.preventDefault();
  //   AllFiles.map(url => {
  //   let randomnumber = Math.floor((Math.random()*100)+1);
  //        window.open(url,randomnumber )
  //   })
  // }

  useIonViewWillEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/users/projectlistall/TEC")
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
        setRecordOfDecision(response.data.VotingComment);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  const handleSizeChange = (e: any) => {
    setVote(e.target.value);
  };

  const handleComment = (e: any) => {
    setComment(e.target.value);
  };
  //select
  const { Option } = Select;

  const onDownloadChange = (val: any) => {
    console.log("Link!")
    window.open(val, "_blank");
  };

  function getSelect(object: any) {

    let content =
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select file to download"
        optionFilterProp="children"
        onChange={onDownloadChange}
        filterOption={(input, option) =>
          //@ts-ignore
          option.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {getDocsArray(object).map((item) => {

          return (
            <Option value={item.uri} key={item.name + object.id}>{item.name}</Option>
          );
        })}
      </Select>

    return content
  }
  //end select

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="TEC review comment"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol class="ion-padding" size="12">
            <Steps current={0} status="error">
              <Step title="TEC meeting" description="In progress" />
              <Step title="Client and project brief" description="Incomplete" />
              <Step
                title="Applicant-Project manager interaction"
                description="Capture developer impact and historical project breakdown"
              />
              <Step title="Final risk assessment" description="Incomplete" />
            </Steps>
          </IonCol>
        </IonRow>
        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Project Application ID:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                        </p>
                      }
                      description={dataobject.CurrentStatus}
                    />



                    {getSelect(dataobject)}

                    <Row style={{ paddingLeft: "50px" }}>
                      <Col style={{ paddingRight: "50px", paddingTop: "1%" }}>
                        <div style={{ paddingBottom: "2%" }}>
                          <Tooltip
                            style={{
                              width: buttonWidth,
                              marginLeft: buttonWidth * 4 + 24,
                            }}
                            placement="right"
                            title={commentText}
                          >
                            <Button
                              type="primary"
                              onClick={showModal2}
                              style={{ width: "10vw" }}
                              disabled
                            >
                              TEC review comment
                            </Button>
                          </Tooltip>
                          {/* The voting comment has been labeled and will hereby be reffered to as TEC recommendation */}
                        </div>

                        <div>
                          <Tooltip
                            style={{
                              width: buttonWidth,
                              marginLeft: buttonWidth * 4 + 24,
                            }}
                            placement="right"
                            title={recordText}
                          >
                            <Button
                              type="primary"
                              onClick={() => {
                                showModal();
                                setGlobalObject(dataobject);
                              }}
                              style={{ width: "10vw" }}
                            >
                              Record of Decision
                            </Button>
                          </Tooltip>
                          <Modal
                            title="Record of Decision"
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                          >
                            <TextArea
                              value={recordOfDecision}
                              placeholder=""
                              rows={4}
                              onChange={(e) => {
                                setRecordOfDecision(e.target.value);
                              }}
                            />
                          </Modal>
                        </div>
                      </Col>
                      <Col style={{ paddingRight: "50px", paddingTop: "10px" }}>
                        <p>Vote on Document Agreement</p>
                        <Tooltip
                          style={{
                            width: buttonWidth,
                            marginLeft: buttonWidth * 4 + 24,
                          }}
                          placement="right"
                          title={voteText}
                        >
                          <Radio.Group
                            key={dataobject.id}
                            value={dataobject.vote}
                            onChange={handleSizeChange}
                          >
                            <Radio.Button value="Unvoted">Unvoted</Radio.Button>
                            <Radio.Button
                              onClick={() => {
                                dataobject.TECvote = "Yes";
                                showModal2("Yes");
                                setGlobalObject(dataobject);
                              }}
                              value="Yes"
                            >
                              Yes
                            </Radio.Button>
                            <Radio.Button
                              onClick={() => {
                                dataobject.TECvote = "No";
                                showModal2("No");
                                setGlobalObject(dataobject);
                              }}
                              value="No"
                            >
                              No
                            </Radio.Button>
                          </Radio.Group>
                        </Tooltip>
                      </Col>
                    </Row>
                  </List.Item>
                </>
              ))
            }
          />
        )}
        <Modal
          title="Provide review comment"
          visible={isModal2Visible}
          onOk={() => {
            ApproveProject(globalObject);
          }}
          onCancel={handleCancel2}
        >
          <TextArea
            value={comment}
            placeholder=""
            rows={4}
            onChange={(e) => {
              handleComment(e);
            }}
          />
        </Modal>
        <Modal
          title="Project Summary"
          visible={isModalProjectVisable}
          onOk={handleProjectOk}
          onCancel={handleCancel2}
        ></Modal>
      </IonContent>
    </IonPage>
  );
};

export default TECVoting;
