import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  withIonLifeCycle,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import {
  Card,
  Input,
  Switch,
  Descriptions,
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Button,
  Steps,
  Tooltip,
  PageHeader,
  Upload,
  message,
  Collapse,
  Checkbox,
} from "antd";
import { getuserObject } from "../store/actions";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import "./Page2.css";
import { Link } from "react-router-dom";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { useState } from "react";
import { UIStore } from "../store/UIStore";
import Modal from "antd/lib/modal/Modal";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

const TypeOfApplication: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  async function SubmitCompliance() {
    console.log(CurrentProject);
    console.log(selectedType);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/setapplicationtype",
          {
            Fundingtype: selectedType,
            id: CurrentProject.id,
          }
        )
        .then((response) => {
          message.success("Submission Succeeded");
          console.log(response);
          props.history.push("/projects");
        });
    } catch (error: any) {
      console.error(error);
    }
  }

  const { Step } = Steps;

  const { name } = useParams<{ name: string }>();

  const { Panel } = Collapse;

  function callback(key: any) {
    console.log(key);
  }

  const [isModalHelpVisible, setIsModalHelpVisible] = useState(false);

  const showHelpModal = () => {
    setIsModalHelpVisible(true);
  };
  const { Option } = Select;

  const [selectedType, setSelectedType] = useState("");
  const [onGoingProject, setonGoingProject] = useState("");

  function handleChange(value: any) {
    setSelectedType(value.toUpperCase());
    console.log(value.toUpperCase());
  }

  const handleHelpOk = () => {
    SubmitCompliance();
    setIsModalHelpVisible(false);
  };

  const handleHelpCancel = () => {
    setIsModalHelpVisible(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const genExtra = (item: any) => {
    if (item === true) {
      return (
        <>
          Document Submitted <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
        </>
      );
    } else {
      return (
        <>
          Document Outstanding
          <ExclamationCircleTwoTone twoToneColor="#FF0000" />{" "}
        </>
      );
    }
  };

  const text = <span>Submit your initial documents</span>;

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  useIonViewDidEnter(() => { });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  // async function SubmitApplicationForm() {

  //   message
  //     .loading('Action in progress..', 5)
  //     .then(() => message.success('Submission Succeeded', 5));

  // }
  function onCheckBoxChange(e: any) {
    setonGoingProject(e.target.checked);
  }

  function beforeUpload(file: any) {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload Pdf files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Document must smaller than 5MB!");
    }

    return file.type === "application/pdf" && file.size / 1024 / 1024 < 5
      ? true
      : Upload.LIST_IGNORE;
  }

  const propsCPO = {
    name: "propsCPO",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.CPO = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsRFQ = {
    name: "propsRFQ",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.RFQ = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsSuppliersQ = {
    name: "propsSuppliersQ",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.SuppliersQ = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsCinvoice = {
    name: "propsCinvoice",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.Cinvoice = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsDebtAge = {
    name: "propsDebtAge",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.DebtAge = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsEditorsAge = {
    name: "propsEditorsAge",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.EditorsAge = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsPoCl = {
    name: "propsPoCl",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.PoCl = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsMonthBankState = {
    name: "propsMonthBankState",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.MonthBankState = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsBillofQuanties = {
    name: "propsBillofQuanties",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.BillofQuanties = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsSLA = {
    name: "propsSLA",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.SLA = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsCashFlowPro = {
    name: "propsCashFlowPro",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.CashFlowPro = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsCletterofAppointment = {
    name: "propsCletterofAppointment",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.CletterofAppointment = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsProjectImplentaionPlan = {
    name: "propsProjectImplentaionPlan",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.ProjectImplentaionPlan = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsCinvoicePaidbyPayMaster = {
    name: "propsCinvoicePaidbyPayMaster",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.CinvoicePaidbyPayMaster = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsPaymentCert = {
    name: "propsPaymentCert",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.PaymentCert = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsDebtandCred = {
    name: "propsDebtandCred",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.DebtandCred = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  let ongoingContent = <></>;
  let content = <></>;

  switch (selectedType) {
    case "PURCHASEORDERFUNDING ":
      content = (
        <>
          <Panel
            header="Copy of purchase order"
            key="1PO"
            extra={genExtra(CurrentProject.CPO.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload beforeUpload={beforeUpload} maxCount={1} {...propsCPO}>
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Copy of Request For Quotation(RFQ)"
            key="2PO"
            extra={genExtra(CurrentProject.RFQ.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload beforeUpload={beforeUpload} maxCount={1} {...propsRFQ}>
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Copy of suppliers quotation"
            key="3PO"
            extra={genExtra(CurrentProject.SuppliersQ.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsSuppliersQ}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
        </>
      );
      break;
    case "INVOICEDISCOUNTING":
      content = (
        <>
          <Panel
            header="Copy of invoice"
            key="1I"
            extra={genExtra(CurrentProject.Cinvoice.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsCinvoice}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Debtors age analyses"
            key="2I"
            extra={genExtra(CurrentProject.DebtAge.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsDebtAge}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Creditors age analyses"
            key="3I"
            extra={genExtra(CurrentProject.EditorsAge.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsEditorsAge}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Copy of purchase order(PO)/Contract letter(Cl)"
            key="4I"
            extra={genExtra(CurrentProject.PoCl.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload beforeUpload={beforeUpload} maxCount={1} {...propsPoCl}>
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="6 Month bank statement"
            key="5I"
            extra={genExtra(CurrentProject.MonthBankState.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsMonthBankState}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
        </>
      );
      break;
    case "CONSTRUCTIONFUNDING":
      content = (
        <>
          <Panel
            header="Bill of quantities"
            key="1C"
            extra={genExtra(CurrentProject.BillofQuanties.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsBillofQuanties}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Service Level Agreement(SLA)"
            key="2C"
            extra={genExtra(CurrentProject.SLA.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload beforeUpload={beforeUpload} maxCount={1} {...propsSLA}>
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Cash flow projections"
            key="3C"
            extra={genExtra(CurrentProject.CashFlowPro.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsCashFlowPro}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Copy of letter of appointment"
            key="4C"
            extra={genExtra(CurrentProject.CletterofAppointment.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsCletterofAppointment}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
          <Panel
            header="Project implementation plan"
            key="5C"
            extra={genExtra(CurrentProject.ProjectImplentaionPlan.length > 5)}
          >
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ paddingLeft: "7vw" }}>
                  <strong>Upload Documents</strong>
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel>
                  <strong></strong>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol></IonCol>

              <IonCol>
                <Upload
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  {...propsProjectImplentaionPlan}
                >
                  <div style={{ paddingLeft: "6vw" }}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload Files
                    </Button>
                  </div>
                </Upload>
              </IonCol>

              <IonCol></IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
                <Checkbox onChange={onChange}>Not Applicable</Checkbox>
              </IonCol>
            </IonRow>
          </Panel>
        </>
      );
      break;

    default:
      break;
  }

  if (onGoingProject) {
    ongoingContent = (
      <>
        <Panel
          header="Copy of invoices paid by paymaster"
          key="1OG"
          extra={genExtra(CurrentProject.CinvoicePaidbyPayMaster.length > 5)}
        >
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol>
              <IonLabel>
                <strong></strong>
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel style={{ paddingLeft: "7vw" }}>
                <strong>Upload Documents</strong>
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel>
                <strong></strong>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
            <IonCol>
              <Upload
                beforeUpload={beforeUpload}
                maxCount={1}
                {...propsCinvoicePaidbyPayMaster}
              >
                <div style={{ paddingLeft: "6vw" }}>
                  <Button icon={<UploadOutlined />}>
                    Click to Upload Files
                  </Button>
                </div>
              </Upload>
            </IonCol>

            <IonCol></IonCol>
          </IonRow>
          <IonRow>
            <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
              <Checkbox onChange={onChange}>Not Applicable</Checkbox>
            </IonCol>
          </IonRow>
        </Panel>
        <Panel
          header="Payment certificates"
          key="2OG"
          extra={genExtra(CurrentProject.PaymentCert.length > 5)}
        >
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol>
              <IonLabel>
                <strong></strong>
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel style={{ paddingLeft: "7vw" }}>
                <strong>Upload Documents</strong>
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel>
                <strong></strong>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
            <IonCol>
              <Upload
                beforeUpload={beforeUpload}
                maxCount={1}
                {...propsPaymentCert}
              >
                <div style={{ paddingLeft: "6vw" }}>
                  <Button icon={<UploadOutlined />}>
                    Click to Upload Files
                  </Button>
                </div>
              </Upload>
            </IonCol>

            <IonCol></IonCol>
          </IonRow>
          <IonRow>
            <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
              <Checkbox onChange={onChange}>Not Applicable</Checkbox>
            </IonCol>
          </IonRow>
        </Panel>
        <Panel
          header="Debtors and creditors analyses(age analyse)"
          key="3OG"
          extra={genExtra(CurrentProject.DebtandCred.length > 5)}
        >
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol>
              <IonLabel>
                <strong></strong>
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel style={{ paddingLeft: "7vw" }}>
                <strong>Upload Documents</strong>
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel>
                <strong></strong>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
            <IonCol>
              <Upload
                beforeUpload={beforeUpload}
                maxCount={1}
                {...propsDebtandCred}
              >
                <div style={{ paddingLeft: "6vw" }}>
                  <Button icon={<UploadOutlined />}>
                    Click to Upload Files
                  </Button>
                </div>
              </Upload>
            </IonCol>

            <IonCol></IonCol>
          </IonRow>
          <IonRow>
            <IonCol style={{ justifyContent: "center", alignContent: "center" }}>
              <Checkbox onChange={onChange}>Not Applicable</Checkbox>
            </IonCol>
          </IonRow>
        </Panel>
      </>
    );
  } else {
    ongoingContent = <></>;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            title="Type of Application"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <Space direction="vertical">
          <IonRow>
            <IonCol class="ion-padding" size="12">
              <Steps current={2} status="error">
                <Step
                  title="Assign Project manager"
                  description="In Progress"
                />
                <Step
                  title="Submission of initial documentation"
                  description="No current submissions"
                />
                <Step
                  title="Type of application"
                  description="Pending document submission"
                />
                <Step
                  title="Submit proposal"
                  description="No current submissions"
                />
              </Steps>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <IonCol>
                <div style={{ padding: "1%" }}>
                  <Select
                    defaultValue="Please select a Type of Application"
                    style={{ width: 300 }}
                    onChange={handleChange}
                  >
                    <Option value="PURCHASEORDERFUNDING ">
                      Purchase Order Funding
                    </Option>
                    <Option value="INVOICEDISCOUNTING">
                      Invoice Discounting
                    </Option>
                    <Option value="CONSTRUCTIONFUNDING">
                      Construction Funding
                    </Option>
                  </Select>
                  <Checkbox
                    style={{ marginLeft: "1vw" }}
                    onChange={onCheckBoxChange}
                  >
                    Ongoing Project
                  </Checkbox>
                </div>
              </IonCol>
              <Collapse
                defaultActiveKey={["1"]}
                onChange={callback}
                style={{
                  width: "70vw",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  paddingBottom: "2%",
                }}
              >
                {content}
                {ongoingContent}
              </Collapse>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class=" ion-text-center ">
              <Tooltip placement="top" title={text}>
                <Button
                  size={"large"}
                  type="primary"
                  onClick={() => {
                    showHelpModal();
                  }}
                >
                  Submit
                </Button>
                <Modal
                  title=""
                  visible={isModalHelpVisible}
                  onOk={handleHelpOk}
                  onCancel={handleHelpCancel}
                >
                  <p>
                    <strong>Submitted successfully</strong>
                  </p>
                  <p>
                    Your documents have been submitted to SP Impact Fund please
                    be patient while they are verified.
                  </p>
                </Modal>
              </Tooltip>
            </IonCol>
          </IonRow>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default TypeOfApplication;
