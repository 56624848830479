import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Tooltip,
  Select,
  Empty,
  Table,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import {
  PlusOutlined,
  ProjectOutlined,
  ReloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { setcurrentproject } from "../store/actions";
import { UIStore } from "../store/UIStore";

const RiskMatrix: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalRiskPriority, setModalRiskPriority] = React.useState("");
  const [modalRiskType, setModalRiskType] = React.useState("");
  const [modalRiskDescription, setModalRiskDescription] = React.useState("");
  const [modalRiskImpact, setModalRiskImpact] = React.useState("");
  const [modalLikelyOccurrence, setModalLikelyOccurrence] = React.useState("");
  const [modalRiskOwner, setModalRiskOwner] = React.useState("");
  const [modalMitigation, setModalMitigation] = React.useState("");
  const [modalMitigationTime, setModalMitigationTime] = React.useState("");
  const create = <span>Fill in project history </span>;
  const { TextArea } = Input;

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const CreateProject = async () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    SetRisk((oldArray: any) => [
      ...oldArray,
      {
        RiskPriority: modalRiskPriority,
        RiskType: modalRiskType,
        RiskDescription: modalRiskDescription,
        RiskImpact: modalRiskImpact,
        LikelyOccurrence: modalLikelyOccurrence,
        RiskOwner: modalRiskOwner,
        Mitigation: modalMitigation,
        MitigationTime: modalMitigationTime,
      },
    ]);

    setModalRiskType("");
    setModalRiskDescription("");
    setModalRiskPriority("");
    setModalRiskImpact("");
    setModalLikelyOccurrence("");
    setModalRiskOwner("");
    setModalMitigation("");
    setModalMitigationTime("");
    setVisible(false);
  };

  const columns = [
    {
      title: "Risk Priority",
      dataIndex: "RiskPriority",
    },
    {
      title: "Risk Type",
      dataIndex: "RiskType",
    },
    {
      title: "Risk Description",
      dataIndex: "RiskDescription",
    },
    {
      title: "Risk Impact",
      dataIndex: "RiskImpact",
    },
    {
      title: "Likelihood of Occurence",
      dataIndex: "LikelyOccurrence",
    },
    {
      title: "Risk Owner",
      dataIndex: "RiskOwner",
    },
    {
      title: "Mitigation",
      dataIndex: "Mitigation",
    },
    {
      title: "Time for Mitigation",
      dataIndex: "MitigationTime",
    },
  ];

  const [Risk, SetRisk] = React.useState<any>([
    {
      RiskPriority: "",
      RiskType: "",
      RiskDescription: "",
      RiskImpact: "",
      LikelyOccurrence: "",
      RiskOwner: "",
      Mitigation: "",
      MitigationTime: "",
    },
  ]);

  useIonViewDidEnter(() => { });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  async function SubmitRisk() {
    message
      .loading("Action in progress..", 2)
      .then(() => message.success("Submission Succeeded", 3));

    console.log(Risk);
    Risk.shift();
    try {
      await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/projects/riskmatrixsubmit/" + CurrentProject.id,
          Risk
        )
        .then((response) => {
          // message.success(response.data);
          console.log(response);
          props.history.push("/RiskAssessment");
        })
    } catch (error: any) {
      console.error(error);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="Risk Matrix" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol>
            <Card>
              <Tooltip placement="top" title={create}>
                <Button
                  type="primary"
                  onClick={showModal}
                  icon={<PlusOutlined />}
                >
                  Add Risk
                </Button>
              </Tooltip>
              <Modal
                width="60vw"
                title="New Project"
                visible={visible}
                onOk={CreateProject}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
              >
                <IonRow>
                  <IonCol>
                    <p>Risk Priority</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalRiskPriority}
                      size="large"
                      type="number"
                      placeholder=""
                      onChange={(e) => {
                        setModalRiskPriority(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>

                    <p>Risk Type</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalRiskType}
                      size="large"
                      onChange={(e) => {
                        setModalRiskType(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>

                    <p>Risk Description</p>
                    <TextArea
                      style={{ padding: "10px" }}
                      value={modalRiskDescription}
                      placeholder=""
                      onChange={(e) => {
                        setModalRiskDescription(e.target.value);
                      }}
                    />
                    <br></br>
                    <p></p>

                    <p>Risk Impact</p>
                    <TextArea
                      style={{ padding: "10px" }}
                      value={modalRiskImpact}
                      placeholder=""
                      onChange={(e) => {
                        setModalRiskImpact(e.target.value);
                      }}
                    />
                    <br></br>
                    <p></p>
                  </IonCol>

                  <IonCol>
                    <p>Likelihood of Occurence</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalLikelyOccurrence}
                      size="large"
                      placeholder=""
                      onChange={(e) => {
                        setModalLikelyOccurrence(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>

                    <p>Risk Owner</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalRiskOwner}
                      size="large"
                      placeholder=""
                      onChange={(e) => {
                        setModalRiskOwner(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>

                    <p>Mitigation</p>
                    <TextArea
                      style={{ padding: "10px" }}
                      value={modalMitigation}
                      placeholder=""
                      onChange={(e) => {
                        setModalMitigation(e.target.value);
                      }}
                    />
                    <br></br>
                    <p></p>

                    <p>Time for Mitigation</p>
                    <TextArea
                      style={{ padding: "10px" }}
                      value={modalMitigationTime}
                      placeholder=""
                      onChange={(e) => {
                        setModalMitigationTime(e.target.value);
                      }}
                    />
                    <br></br>
                    <p></p>
                  </IonCol>
                </IonRow>

                <br></br>
                <br></br>
              </Modal>
              <br></br>
              <br></br>

              <div style={{ paddingLeft: "20px" }}>
                <Table columns={columns} dataSource={Risk} size="middle" />
              </div>

              <IonRow>
                <IonCol class="ion-padding">
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={() => {
                      SubmitRisk();
                    }}
                  >
                    Submit
                  </Button>
                </IonCol>
              </IonRow>
            </Card>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default RiskMatrix;
