import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import { UIStore } from "../store/UIStore";
import { Descriptions, Row, Col, notification } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import moment from "moment";
import { Steps, message } from "antd";
import React, { useState } from "react";
import { PageHeader } from "antd";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
const dateFormat = "YYYY/MM/DD";
const JointVentureAgreement: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }

  async function SubmitJVAgreement() {
    message
      .loading("Action in progress..", 4)
      .then(() => message.success("Contract submitted", 5));

    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdatejv",
          CurrentProject
        )
        .then((response) => {
          console.log(response);
        });
    } catch (error: any) { }
  }

  useIonViewDidEnter(() => { });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  const { Step } = Steps;

  const steps = [
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <h5 className="ion-text-center">
            <strong>
              This Joint Venture Agreement (the “Agreement”) is effective from{" "}
              <DatePicker
                defaultValue={moment("2021/01/01")}
                value={moment(Date.now())}
              />
              <br></br>
              <br></br>
            </strong>
          </h5>{" "}
          <br></br>
          <strong>BETWEEN:</strong>
          <Input
            value={CurrentProject.PartnerName}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.PartnerName = e.target.value;
              });
            }}
            name="PartnerName"
            placeholder="Joint Venture Partner Name"
          />
          <br></br>
          <br></br> a duly incorporated entity under the Company Laws of the
          Republic of South Africa, with registration number{" "}
          <Input
            placeholder="Registration Number"
            value={CurrentProject.RegistrationNumber}
            type="number"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.RegistrationNumber = e.target.value;
              });
            }}
            name="RegistrationNumber"
          />
          <br></br>
          <br></br> with their head office located at:<br></br>
          <br></br>
          <Input
            placeholder="Head Office Location "
            value={CurrentProject.HeadOfficeLocation}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.HeadOfficeLocation = e.target.value;
              });
            }}
            name="HeadOfficeLocation"
          />
          <br></br>
          <br></br>
          <strong>AND:</strong> SP Property Fund (Pty) Ltd , a duly incorporated
          entity under the Company Laws of the Republic of South Africa, with
          registration number 2020/857932/07, with their head office located at:{" "}
          <br></br>
          <br></br>
          <strong>
            11 Monza Close Place, Kyalami Park, Johannesburg, 1684
          </strong>
          <br></br>
          <br></br>
          <strong>AND</strong>
          <br></br>
          <br></br>
          This Agreement is entered into by the 1st Joint Venture Partner and SP
          Property Fund (Pty) Ltd, herein after collectively referred to as
          “Joint Venture Partners/the Parties/the Partners”.<br></br>
          <br></br>
          The Joint Venture Partners have agreed to make contribution to the
          sourcing, design, financing and development of business opportunities
          in the construction and property sector, to be referred to hereon as
          the “Business Interest/Project”. The specific provisions of each
          project in this joint venture shall be specified and signed as an
          annexure to this joint venture agreement.<br></br>
          <br></br>
          The Joint Venture Partners form this Joint Venture Partnership (the
          “Partnership”) to acquire and hold the business interest in common,
          and to provide the finances required to initiate it. To the extent set
          forth in this agreement, each of the Joint Venture Partners shall hold
          an undivided fractional part in the business.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>WITNESSETH:</strong>
          </h5>{" "}
          <br></br>
          WHEREAS the Joint Venture Partners are desirous of forming a Joint
          Venture (the “Venture”) under the Laws of the Republic of South Africa
          by execution of this Agreement for the purposes set forth herein and
          are desirous of fixing and defining, between themselves, their
          respective responsibilities, interests and liabilities in connection
          with, the performance of the beforementioned Project, and NOW,
          THEREFORE, in consideration of the mutual covenants and promises
          herein contained, the Parties herein agree to constitute themselves as
          Joint Venture Partners, for the purposes beforementioned, and
          intending to be legally bound hereby, the parties hereto, after first
          being duly sworn, do covenant, agree and certify as follows:<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>FORMATION, NAME AND PRINCIPAL PLACE OF BUSINESS:</strong>
          </h5>{" "}
          <br></br>
          <strong>Formation</strong>
          <br></br>
          <br></br>
          (a). The Partners do hereby form a Joint Venture pursuant to the Laws
          of the Republic of South Africa in order for the Venture to carry on
          the purposes for which the provision is made herein.<br></br>
          <br></br>
          (b). The Partners shall execute such certificates as may be required
          by the Laws of the Republic of South Africa in order for the Venture
          to operate its business and shall do all other acts and things
          requisite for the continuation of the Venture as a joint venture
          pursuant to applicable law.<br></br>
          <br></br>
          <strong>Name</strong>
          <br></br>
          <br></br>
          The Name under which the Venture will be conducted is{" "}
          <strong>
            <Input
              placeholder="Joint Venture Name "
              value={CurrentProject.JointVentureName}
              type="text"
              onChange={(e: any) => {
                UIStore.update((s) => {
                  s.CurrentProject.JointVentureName = e.target.value;
                });
              }}
              name="JointVentureName"
            />
          </strong>
          <br></br>
          <br></br>
          <strong>Management</strong>
          <br></br>
          <br></br>
          The Venture shall maintain its principal place of Business at{" "}
          <strong>
            11 Monza Close Place, Kyalami Park, Johannesburg, 1684
          </strong>
          <br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>PURPOSE OF THE JOINT VENTURE</strong>
          </h5>{" "}
          <br></br>
          The purpose of the Joint Venture shall be to perform the Business
          Interest referred to above. It has been agreed that each Joint Venture
          Partner will contribute R**m each to the Joint Venture Partnership
          over a period of no more than 12 months from the date of this
          Agreement for the sole purpose of executing the Business Interest. The
          Venture shall operate as a Revolving Fund and the size of each
          partner’s contribution may be increased during or after the initial 12
          month period by mutual agreement of the Partners.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>TERM</strong>
          </h5>{" "}
          <br></br>
          The term of this Venture shall commence as of the date hereof and
          shall be terminated and dissolved upon:<br></br>
          (a). The unanimous agreement of the Partners, or <br></br>
          (b). The order of a court of competent jurisdiction
        </p>
      ),
    },
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <h5 className="ion-text-center">
            <strong>PERCENTAGE OF PARTICIPATION</strong>
          </h5>{" "}
          <br></br>
          The Joint Venture Partners shall, based on the agreed upon equal
          contribution referred to in clause 3 above, participate equally in all
          operational expenditure, profits and losses of the Venture. <br></br>
          <br></br>
          The intention of the Venture is to re-invest all profits back into the
          Venture and only distribute profits per agreement between themselves
          from time to time at formal meetings of the Partnership.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Management</strong>
          </h5>{" "}
          <br></br>
          The management of the Joint Venture shall be conducted pursuant to
          policy established by the Parties acting through a "Policy Committee"
          which is hereby established.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Votes</strong>
          </h5>{" "}
          <br></br>
          Each Party shall have a voice in the Policy Committee equal to its
          Percentage of Participation. For such purpose each Party hereby
          designates the following representatives to exercise such votes:
          <br></br>
          <br></br>
          For{" "}
          <Input
            placeholder="Client Name "
            value={CurrentProject.ClientName}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.ClientName = e.target.value;
              });
            }}
            name="ClientName"
          />{" "}
          <br></br>
          <br></br> (a).{" "}
          <Input
            placeholder="Representative Name"
            value={CurrentProject.RepresentativeNameA}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.RepresentativeNameA = e.target.value;
              });
            }}
            name="RepresentativeNameA"
          />
          <br></br>
          <br></br>
          (b).
          <Input
            placeholder="Representative Name"
            value={CurrentProject.RepresentativeNameB}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.RepresentativeNameB = e.target.value;
              });
            }}
            name="RepresentativeNameB"
          />
          <br></br>
          <br></br>
          For SP Fund: (a). Mrs Nkuli Mkhize, (b). Mr Lucas Tseki<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Substitution</strong>
          </h5>{" "}
          <br></br>
          Each Party may, at any time, substitute an alternative in place of any
          of its above-named representatives by serving written notice to all
          the other Parties. Each Party’s representative or alternative
          representative on the Policy Committee is hereby granted and shall
          hereafter possess authority to act for such Party on all matters of
          interest to it with respect to its participation in the joint venture.
          <br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Majority vote</strong>
          </h5>{" "}
          <br></br>
          The Policy Committee shall determine the policy for the management of
          the joint venture by majority vote and, as used in this Agreement, a
          "majority vote" is defined to be any figure greater than one­ half of
          the authorized votes.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Powers of the Policy Committee</strong>
          </h5>{" "}
          <br></br>
          The Policy Committee shall have the following powers:<br></br>
          <br></br>
          (a). To determine the time and place of holding its meetings and the
          procedures for conducting Committee Affairs.<br></br>
          (b). To determine and act upon the various matters, expressly or
          impliedly contained in other sections of this Agreement, which require
          decision by the Policy Committee.<br></br>
          (c). To determine and act upon any other matters of joint interest to
          or requiring prompt action by the Joint Venture.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Duties</strong>
          </h5>{" "}
          <br></br>
          The Policy Committee shall generally perform its duties at a meeting
          at which all designated representatives of the Parties are present,
          but where circumstances warrant, telephone communication between all
          party representatives or their alternatives is authorized.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Salaries and expenses</strong>
          </h5>{" "}
          <br></br>
          The salaries and expenses of each of the representatives on the
          Committee shall be borne by the Party whom the representative has been
          designated to represent and shall not be an expense to the joint
          venture.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Budget and Flow of Funds </strong>
          </h5>{" "}
          <br></br>
          The Venture, through the Policy Committee, shall be largely guided by
          the attached budget, financial projections and flow of funds document
          signed by both parties hereto referred to as Annexure A.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>Additional Requirements</strong>
          </h5>{" "}
          <br></br>
          Each Partner commits to bring to the attention of the Policy Committee
          any requirements as may be required from time to time by its Funders,
          in order to comply with its funding conditions. The Parties commit to
          endeavor to comply with those requirements in the interest of the
          Venture. The initial requirements are outlined in the attached
          document hereto referred to as Annexure A.<br></br>
          <br></br>
        </p>
      ),
    },
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          {" "}
          <h5 className="ion-text-center">
            <strong>DELEGATION OF AUTHORITY</strong>
          </h5>{" "}
          <br></br>
          <strong>Joint authority</strong> <br></br>
          <br></br>
          The Partners agree to jointly be responsible for the operations of the
          Joint Venture and through the Policy Committee decide from time to
          time who shall be the Administrative Managing Partner responsible for
          all bookkeeping and payroll of the Joint Venture, and who shall be the
          Project Managing Partner in charge of the Project Work; based on the
          principle of assigning each duty to the party best placed to perform
          the duties.<br></br>
          <br></br>
          <strong>Appointment of Joint Venture Staff</strong> <br></br>
          <br></br>
          The Joint Venture is expected to, from time to time, appoint
          non-permanent staff linked to the projects it will be servicing. The
          appointment of this staff will be the responsibility of the party that
          the Joint Venture has determined is best placed to perform this duty
          through the Policy Committee. Where possible, the partners will first
          and foremost second their own staff to the Joint Venture, for the
          purposes of conducting the Joint Venture business. Where existing
          staff lack in either time and/or skill, the Joint Venture will appoint
          the above-mentioned non-permanent staff. The non-permanent staff shall
          be paid by the Joint Venture and the seconded staff shall be paid by
          the Party that has seconded them.<br></br>
          <br></br>
          <strong>Delegation of authority</strong> <br></br>
          <br></br>
          Authority to act for and bind the Partners in connection with any and
          all of the performance of the Project may be delegated in writing by
          unanimous vote of the Partners to any designated individual(s).
          <br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>JOINT VENTURE BANK ACCOUNTS</strong>
          </h5>
          <br></br>
          <strong>Deposits</strong>
          <br></br>
          <br></br>
          All Working Capital or other funds received by the Joint Venture in
          connection with the performance of the project shall be deposited in a
          Checking Account, set up especially for the Joint Venture, and
          requiring the joint signatures of the parties for any withdrawals.
          Said accounts shall be kept separate and apart from any other accounts
          of the Partners.<br></br>
          <br></br>
          <strong>Withdrawals</strong>
          <br></br>
          <br></br>
          Withdrawal of funds from the Joint Venture's Joint Checking Account
          may be made in such amount and by such persons as authorized by the
          Policy Committee.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>ACCOUNTING AND AUDITING</strong>
          </h5>
          <br></br>
          <strong>Books</strong>
          <br></br>
          <br></br>
          Separate books of accounts shall be kept by the Administrative
          Managing Partner appointed by the Policy Committee, of the
          transactions of the Joint Venture. Any Partner may inspect such books
          upon reasonable notice and at any reasonable time and the financials
          of the Joint Venture will be consolidated into each Partner’s
          financial statements in the proportion of each Partner’s participation
          in the Venture.<br></br>
          <br></br>
          <strong>Audits</strong>
          <br></br>
          <br></br>
          Periodic audits may be made upon said books at such time as authorized
          by the Policy Committee by persons designated by the same and copies
          of said audit shall be furnished to all Partners.<br></br>
          <br></br>
          <strong>Compensation</strong>
          <br></br>
          <br></br>
          The Administrative Managing Partner shall receive no additional
          compensation from the Joint Venture, however, work performed by
          independent parties on behalf of the Administrative Managing Partner
          acting on behalf of the Joint Venture, shall be charged separately to
          the Joint Venture's account at a rate agreed upon by the Partners.
          <br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>RESOLUTION OF DISPUTES</strong>
          </h5>
          <br></br>
          All disputes arising out of this Joint Venture Agreement between the
          Partners that is not resolvable by good faith negotiations by the
          same, shall be settled by arbitration under the rules of Arbitration
          Act, No. 42 of 1965 (as amended or replaced from time to time).
          <br></br>
        </p>
      ),
    },
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <h5 className="ion-text-center">
            <strong>DISSOLUTION</strong>
          </h5>{" "}
          <br></br>
          <strong>Events of the Joint Ventures</strong>
          <br></br>
          <br></br>
          The Joint Venture shall be dissolved upon the happening of any of the
          following events:<br></br>
          <br></br>
          (a). The adjudication of bankruptcy, removal or insolvency of either
          of the parties.<br></br>
          (b). The sale or other disposition, not including an exchange of all,
          or substantially all, of the Joint Venture assets.<br></br>
          (c). Mutual agreement of the parties.<br></br>
          <br></br>
          <h5 className="ion-text-center">
            <strong>OTHER PROVISIONS</strong>
          </h5>{" "}
          <br></br>
          <strong>Entire agreement</strong>
          <br></br>
          <br></br>
          This agreement constitutes the entire agreement of the parties and may
          not be altered, unless the same is agreed upon in writing signed and
          acknowledged by the parties.<br></br>
          <br></br>
          This agreement is binding upon the heirs, court appointed
          representatives, assigns, and successors of the parties.<br></br>
          <br></br>
          <strong>Governing Law</strong>
          <br></br>
          <br></br>
          <h4 className="ion-text-center">
            <strong> ADDENDUMS:</strong>
          </h4>
          <br></br>
          <TextArea
            value={CurrentProject.JVAddendum}
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.JVAddendum = e.target.value;
              });
            }}
            name="CreditAddendum"
            disabled
            placeholder="Provide addendum details for the joint venture agreement page"
          />
          <br></br>
          <br></br>
          <br></br>
          This agreement shall be governed and construed by the laws of the
          Republic of South Africa.<br></br>
          <br></br>
          IN WITNESS WHEREOF, each party to this agreement has caused it to be
          executed at{" "}
          <Input
            placeholder="Location of Signing"
            value={CurrentProject.LocationOfSigning}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.LocationOfSigning = e.target.value;
              });
            }}
            name="LocationOfSigning"
          />
          <br></br>
          <br></br>on the{" "}
          <DatePicker
            value={moment(
              CurrentProject.JVDate ? CurrentProject.JVDate : Date.now()
            )}
            onChange={(date, dateString) => {
              UIStore.update((s) => {
                s.CurrentProject.JVDate = dateString;
              });
            }}
          />{" "}
          <br></br>
          <br></br>
          JOINT VENTURE PARTNER
          <Input
            placeholder="Joint Venture Partner Name"
            value={CurrentProject.JointVenturePartnerName}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.JointVenturePartnerName = e.target.value;
              });
            }}
            name="JointVenturePartnerName"
          />
          <br></br>
          <br></br>
          Authorized Signature
          <SignatureCanvas
            ref={(ref) => {
              sigCanvas = ref;
            }}
            backgroundColor="#a9a9a9"
            penColor="black"
            canvasProps={{ width: 1300, height: 200, className: "sigCanvas" }}
            onEnd={() => {
              UIStore.update((s) => {
                s.CurrentProject.SignatureJV = sigCanvas.toDataURL();
              });
            }}
          />
          <Input
            placeholder="Representative Name and Title"
            value={CurrentProject.NameTitle}
            type="text"
            onChange={(e: any) => {
              UIStore.update((s) => {
                s.CurrentProject.NameTitle = e.target.value;
              });
            }}
            name="NameTitle"
          />
          <br></br>
          <br></br>
        </p>
      ),
    },
  ];

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header ion-text-center"
            title="Joint Venture Agreement"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCol class="ion-padding">
          <Space direction="vertical">
            <hr></hr>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && (
                <IonCol class="ion-padding">
                  <Button
                    size={"large"}
                    style={{ margin: "0 8px" }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                </IonCol>
              )}
              {current < steps.length - 1 && (
                <IonCol class="ion-padding">
                  <Button size={"large"} type="primary" onClick={() => next()}>
                    Next
                  </Button>
                </IonCol>
              )}
              {current === steps.length - 1 && (
                <IonCol class="ion-padding">
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={() => {
                      SubmitJVAgreement();
                      props.history.push("/Projects");
                    }}
                  >
                    Submit
                  </Button>
                </IonCol>
              )}

            </div>
          </Space>
        </IonCol>
      </IonContent>
    </IonPage>
  );
};
export default JointVentureAgreement;
