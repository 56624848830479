import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonLabel,
} from "@ionic/react";
import { Button, Card, Collapse, Upload, Input, DatePicker, Steps, Tooltip, Modal } from "antd";
import "./Page2.css";
import { PageHeader, message } from "antd";
import React, { useState } from "react";
import axios from "axios";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import { UIStore } from "../store/UIStore";
import moment from "moment";

const style = { paddingLeft: "130px" };
const label = { paddingLeft: "10px" };
const { Step } = Steps;

const Rulings: React.FC = (props: any) => {
  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const { Panel } = Collapse;
  const { TextArea } = Input;
  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return file.type === "image/jpeg" && file.size / 1024 / 1024 < 2
      ? true
      : Upload.LIST_IGNORE;
  }

  function callback(key: any) {
    console.log(key);
  }
  const genExtra = (item: any) => {
    if (item === true) {
      return (
        <>
          Document Submitted <CheckCircleTwoTone twoToneColor="#52c41a" />
        </>
      );
    } else {
      return (
        <>
          Document Outstanding
          <ExclamationCircleTwoTone twoToneColor="#FF0000" />
        </>
      );
    }
  };

  const FileUploadProps = {
    /*
    //Upload file
    name: "copyuseridentification",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage/main",

    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setState((prevState) => ({
          ...prevState,
          ID: info.file.response.path,
        }));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    */
  };
  async function submitRulings() {
    console.log(CurrentProject);

    // message
    //   .loading('Action in progress..', 5)
    //   .then(() => message.success('Submission Succeeded', 5));
    // try {
    //   const request = await axios.post('https://dev.northroom.co.za/api/spfund/users/ProjectUpdate/Compliance', CurrentProject).then((response) => {

    //     // message.success(response.data);
    //     // console.log(response);
    //     // props.history.push("/ComplianceChecklist");


    //   });

    // } catch (error:any) {
    //   console.error(error);
    // }

  }

  const [isModalHelpVisible, setIsModalHelpVisible] = useState(false);

  const showHelpModal = () => {
    setIsModalHelpVisible(true);
  };

  const handleHelpOk = () => {
    submitRulings();
    setIsModalHelpVisible(false);
  };

  const handleHelpCancel = () => {
    setIsModalHelpVisible(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="Rulings (Site Visits)" />
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCol class="ion-padding" size="11">
          <Steps current={3} status="error">
            <Step
              title="Fund Manager screening"
              description="In progress"
            />
            <Step
              title="Application officer"
              description="Application verification incomplete"
            />
            <Step
              title="Compliance officer"
              description="Compliance verification incomplete"
            />
            <Step
              title="Vetting procedure"
              description="In Progress"
            />
          </Steps>
        </IonCol>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">test</IonTitle>
          </IonToolbar>
        </IonHeader>

        <Card>

          <IonRow>
            <Collapse
              defaultActiveKey={["1"]}
              onChange={callback}
              style={{
                width: "70vw",
                paddingLeft: "2%",
                paddingRight: "2%",
                paddingBottom: "2%",
              }}
            >
              <Panel
                header="Site Visits"
                key="1"
                extra={genExtra(CurrentProject.companyprofile.length > 5)}
              >
                <IonRow className="ion-align-items-center ion-justify-content-center">
                  <IonCol>
                    <IonLabel>
                      <strong></strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ paddingLeft: "7vw" }}>
                      <strong>Upload Documents</strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>
                      <strong></strong>
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol></IonCol>

                  <IonCol>
                    <Upload
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      {...FileUploadProps}
                    >
                      <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload Files
                        </Button>
                      </div>
                    </Upload>
                  </IonCol>

                  <IonCol></IonCol>
                </IonRow>
              </Panel>
              <Panel
                header="Client Vetting"
                key="2"
                extra={genExtra(CurrentProject.companyprofile.length > 5)}
              >
                <IonRow className="ion-align-items-center ion-justify-content-center">
                  <IonCol>
                    <IonLabel>
                      <strong></strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ paddingLeft: "7vw" }}>
                      <strong>Upload Documents</strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>
                      <strong></strong>
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol></IonCol>

                  <IonCol>
                    <Upload
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      {...FileUploadProps}
                    >
                      <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload Files
                        </Button>
                      </div>
                    </Upload>
                  </IonCol>

                  <IonCol></IonCol>
                </IonRow>
              </Panel>
              <Panel
                header="Supplier Vetting"
                key="3"
                extra={genExtra(CurrentProject.companyprofile.length > 5)}
              >
                <IonRow className="ion-align-items-center ion-justify-content-center">
                  <IonCol>
                    <IonLabel>
                      <strong></strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel style={{ paddingLeft: "7vw" }}>
                      <strong>Upload Documents</strong>
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>
                      <strong></strong>
                    </IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol></IonCol>

                  <IonCol>
                    <Upload
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      {...FileUploadProps}
                    >
                      <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload Files
                        </Button>
                      </div>
                    </Upload>
                  </IonCol>

                  <IonCol></IonCol>
                </IonRow>
              </Panel>
            </Collapse>
            <IonRow></IonRow>
            <IonCol>
              <TextArea placeholder="Vetting Procedure Remarks" rows={4} />
            </IonCol>
          </IonRow>
          <IonCol>
            <DatePicker value={moment(Date.now())} />
          </IonCol>
        </Card>
        <IonRow>
          <IonCol class=" ion-text-center ">
            <Tooltip placement="top" title="Submit Documentation ">
              <Button size={"large"} type="primary" onClick={() => { showHelpModal(); }}> Submit</Button>
              <Modal title="" visible={isModalHelpVisible} onOk={handleHelpOk} onCancel={handleHelpCancel}>
                <p><strong>Submitted vetting documentation.</strong></p>
                <p>Risk officer to compile initial risk assessment</p>

              </Modal>
            </Tooltip>

          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Rulings;
