import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Tooltip,
  Upload,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import {
  PlusOutlined,
  ProjectOutlined,
  ReloadOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../pages/images/image6.jpg";
import { Empty } from "antd";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { setcurrentproject } from "../store/actions";
import { UIStore } from "../store/UIStore";

const dateFormat = "YYYY/MM/DD";

const Proposal: React.FC = (props: any) => {
  const { Step } = Steps;
  const [ProjectName, setProjectName] = useState("");
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const [data, setdata] = useState<any[]>([]);
  const [acceptedProposal, setAcceptedProposal] = useState<any[]>([]);
  const [rejectedProposal, setRejectedProposal] = useState<any[]>([]);

  // const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};

  const createText = (
    <span>Create a project, if you do not have one created below.</span>
  );
  const submitText = <span>Create a proposal for the applicant.</span>;
  const submit2Text = <span>View the current proposal</span>;
  const buttonWidth = 70;

  // const [projects, setprojects] = useState<any[]>([]);
  // const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  const InitialProject = {
    id: "",
    ProjectName: "",
    CurrentStatus: "",
    CIDB: false,
    Ownership: false,
    ApplicationParticipation: false,
    MinimumWage: false,
    RingFenced: false,
    DevelopmentPlan: false,
    Training: false,
    Experience: false,
    DevelopmentAgreement: false,
    Assesment: false,
    MaterialsAgreement: false,
    CIDBCommentBox: "",
    OwnershipCommentBox: "",
    ApplicationParticipationBox: "",
    MinimumWageBox: "",
    RingFencedBox: "",
    DevelopmentPlanBox: "",
    TrainingBox: "",
    ExperienceBox: "",
    DevelopmentAgreementBox: "",
    AssesmentBox: "",
    MaterialsAgreementBox: "",
    ProjectNumber: "",
    ApplicationProjectName: "",
    BusinessName: "",
    BusinessOwners: "",
    Date: "",
    VersionNumber: "",
    DocumentName: "",
    Author: "",
    Comments: "",
    BusinessOwnerFirstName: "",
    BusinessOwnerSurname: "",
    BeneficiaryID: "",
    MaleFemale: "",
    Under36: "",
    Race: "",
    CellTell: "",
    PhysicalAddress: "",
    CompanyName: "",
    BbbeeRating: "",
    BlackOwnership: "",
    WomanOwnership: "",
    YouthOwnership: "",
    CompanyContact: "",
    BusinessSector: "",
    BusinessRegistrationDate:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    TradingStartDate:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    Classification: "",
    Services: "",
    GPSCoordinates: "",
    AnnualRevenue: "",
    FundingMethod: "",
    PermanentEmployees: "",
    SeasonalEmployees: "",
    TemporaryJobs: "",
    Date2: "",
    BusinessOwnerName1: "",
    BusinessOwnerName2: "",
    BeneficiaryID2: "",
    FemaleMale: "",
    Under36Years: "",
    Race1: "",
    BeneficiaryContact: "",
    TellCell: "",
    PhysicalAddress1: "",
    CompanyName1: "",
    BBBEERating1: "",
    BlackOwnership1: "",
    WomanOwnership1: "",
    YouthOwnership1: "",
    CompanyContact1: "",
    BusinessSector1: "",
    BusinessSize: "",
    BusinessRegistration: "",
    TradingStartDate1: "",
    Classification1: "",
    ProductCommodity: "",
    GPSCoordinates1: "",
    AnnualRevenue1: "",
    FundingMethod1: "",
    PermanentEmployees1: "",
    SeasonalEmployees1: "",
    TempJobs: "",
    SmmeDate: "",
    LegalEntity: "",
    TaxClearance: "",
    PostalAddress: "",
    CIDBGrade: "",
    Title: "",
    Initials: "",
    IDPassport: "",
    CountryOfIssue: "",
    Designation: "",
    Telephone: "",
    Cellphone: "",
    Email: "",
    Projection: "",
    ValueOfProject: "",
    Client: "",
    ClientContact: "",
    ProjectDuration: "",
    ProjectStart:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    ProjectEnd: "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    Location: "",
    PermanentJobs: "",
    BusinessProjects: "",
    SignatureDeclaration: "",
    DecDate: "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    RepNameTitle: "",
    Consent: false,
    SignatureCredit: "",
    CAClient: "",
    RegistrationNumber: "",
    RepresentativeName: "",
    IdentityNumber: "",
    ClientName: "",
    OfficeBlock: "",
    Address: "",
    City: "",
    CADate: "",
    PostalCode: "",
    SigningLocation: "",
    Witnesses: "",
    Witnesses2: "",
    Witnesses3: "",
    Witnesses4: "",
    SignatureJV: "",
    PartnerName: "",
    JVRegistrationNumber: "",
    HeadOfficeLocation: "",
    JointVentureName: "",
    JVClientName: "",
    RepresentativeNameA: "",
    RepresentativeNameB: "",
    LocationOfSigning: "",
    JVDate: "",
    JointVenturePartnerName: "",
    NameTitle: "",
    Spif: "",
    BOQ: "",
    ImplementationPlan: "",
    Letterofappointment: "",
    BankingStatements: "",
    ManagementAccounts: "",
    Insurance: "",
    CSD: "",
    CIDBDoc: "",
    NHBRC: "",
    TaxClearanceDoc: "",
    Vat: "",
    DepartLabor: "",
    personnel: "",
    AllDirectors: "",
    MartialSTatus: "",
    BBEECert: "",
    Companyregdocuments: "",
    companyprofile: "",
    ProofAddress: "",
    ApplicationForm: "",
    BaselineForm: "",
    CIPCForm: "",
    CreditagreementForm: "",
    DeclarationForm: "",
    JVForm: "",
    EligibilityCriteriaForm: "",
    CompanyDescriptionExtendedServices: "",
    CompanyDescriptionExtendedArea: "",
    BusinessStructureFamilyBusiness: "",
    BusinessStructureOwners: "",
    BusinessStructureRolesResponsibilities: "",
    MarketTarget: "",
    MarketOffTakersCurrent: "",
    MarketOffTakersPipeline: "",
    MarketStrategyCurrent: "",
    GovernanceSystems: "",
    GovernanceFinancialManagement: "",
    GovernanceAccounting: "",
    GovernanceHR: "",
    SkillsGap: "",
    ReasonForIntervention: "",
    BusinessChallenges: "",
    OwnerShortMediumLongGoals: "",
    OwnerGoalsSuccess: "",
    OwnerGoalsSuccessionPlan: "",
    ProjectManagerName: "",
    ProjectDescription: "",
    CAAddendum: "",
    JVAddendum: "",
  };

  const [isDeclineModalVisible, setisDeclineModalVisible] = useState(false);

  const handleDeclineOk = (object: any) => {
    setisDeclineModalVisible(false);
  };

  const handleDeclineCancel = () => {
    setisDeclineModalVisible(false);
  };

  const checkReason = (dataobject: any) => {
    if (user.UserType === "FUNDMANAGER" || dataobject?.ReasonForDecline) {
      <IonCol>
        <Button
          type="primary"
          onClick={() => {
            setisDeclineModalVisible(true);
          }}
        >
          Decline Reason
        </Button>
      </IonCol>;
    }
  };

  async function DownloadPack(object: any) { }

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return file.type === "image/jpeg" && file.size / 1024 / 1024 < 2
      ? true
      : Upload.LIST_IGNORE;
  }

  const FileUploadProps = {
    /*
        //Upload file
        name: "copyuseridentification",
        action: "https://dev.northroom.co.za/api/spfund/uploadimage/main",
    
        onChange(info: any) {
          if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === "done") {
            setState((prevState) => ({
              ...prevState,
              ID: info.file.response.path,
            }));
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        */
  };

  useIonViewDidEnter(() => {
    if (user.UserType === "APPLICANT") {
      axios
        .get("https://dev.northroom.co.za/api/spfund/projects/awaitingproposal")
        .then(function (response) {
          //23 items
          console.log(response.data);

          setdata(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } else {
      axios
        .get(
          "https://dev.northroom.co.za/api/spfund/projects/projectsawaitingproposal"
        )
        .then(function (response) {
          //23 items
          console.log(response.data);

          setdata(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });

      axios
        .get(
          "https://dev.northroom.co.za/api/spfund/projects/projectsrejectedproposal"
        )
        .then(function (response) {
          //23 items
          console.log(response.data);

          setRejectedProposal(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });

      axios
        .get(
          "https://dev.northroom.co.za/api/spfund/projects/projectsacceptedproposal"
        )
        .then(function (response) {
          //23 items
          console.log(response.data);

          setAcceptedProposal(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
      let temp = [...data, ...rejectedProposal];
      setdata([...temp, ...acceptedProposal]);
    }
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Lead Name");
  // const [modalText2, setModalText2] = React.useState('Some other input');
  const [globalobject, setGlobalObject] = useState({})
  const showModal = () => {
    setVisible(true);
  };

  /*
    useEffect(() => {
      console.log(CurrentProject);
    }, [CurrentProject]);
  */

  // function loadProject(projectobject: any) {
  //   UIStore.update((s) => {
  //     s.CurrentProject = projectobject;
  //   });
  //   props.history.push("/EligibilityCriteria");
  // }

  const CreateProject = async () => {
    setModalText("Processing Request");
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));

    try {
      const request = await axios

        .post("https://dev.northroom.co.za/api/spfund/users/createproject", {
          userid: user.id,
          ProjectName: ProjectName,
        })
        .then((response) => {
          message.success(response.data);
          console.log(response);
          // props.history.push("/NewProject");

          // UIStore.update((s) => {
          //   s.CurrentProject = InitialProject;
          // });

          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlist/" +
              user.id
            )
            .then(function (response) {
              //23 items

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
  };
  const { TextArea } = Input;
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  let content = <></>;

  const checkStatus = (data: any) => {
    let status = <></>;

    if (data.ProposalAcceptance === "accepted") {
      status = <Tag color="green">Accepted</Tag>;
    } else if (data.ProposalAcceptance === "rejected") {
      status = <Tag color="red">Rejected</Tag>;
    } else {
      status = <Tag color="yellow">Awaiting Signature</Tag>;
    }
    return status;
  };

  if (user.UserType === "FUNDMANAGER") {
    content = (
      <div>
        <IonRow>
          <IonCol className="ion-text-center">
            <h3>SP Fund Proposals</h3>
          </IonCol>
        </IonRow>

        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[{}]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Lead Name:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                          {checkStatus(dataobject)}
                        </p>
                      }
                    // description="Application in Progress"
                    />
                    {/* <IonCol>
                                        <Tag color="geekblue">
                                            Project Stage: {dataobject.CurrentStatus}
                                        </Tag>
                                    </IonCol> */}
                    <IonRow>
                      <IonCol>
                        <Button
                          type="primary"
                          onClick={() => {
                            setisDeclineModalVisible(true);
                            setGlobalObject(dataobject)
                          }}
                        >
                          Reason for Decline
                        </Button>
                      </IonCol>
                      <IonCol>
                        <div style={{ paddingBottom: "2%" }}>
                          <Tooltip
                            style={{
                              width: buttonWidth,
                              marginLeft: buttonWidth * 4 + 24,
                            }}
                            placement="right"
                            title={submitText}
                          >
                            <Button
                              style={{ width: "10vw" }}
                              onClick={() => {
                                UIStore.update((s) => {
                                  s.CurrentProject = dataobject;
                                });
                                props.history.push("/ProposalForm");
                              }}
                              type="primary"
                            // icon={<ReloadOutlined />}
                            >
                              Create Proposal
                            </Button>
                          </Tooltip>
                        </div>
                      </IonCol>
                    </IonRow>
                  </List.Item>
                </>
              ))
            }
          />
        )}
      </div>
    );
  } else if (user.UserType === "APPLICANT") {
    content = (
      <div className="ion-text-center">
        <h3>Applicant Proposals</h3>
        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[{}]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Lead Name:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                          {checkStatus(dataobject)}
                        </p>
                      }
                    // description="Application in Progress"
                    />
                    {/* <IonCol>
                                        <Tag color="geekblue">
                                            Project Stage: {dataobject.CurrentStatus}
                                        </Tag>
                                    </IonCol> */}

                    <IonCol>
                      <div style={{ paddingBottom: "2%", paddingLeft: "70%" }}>
                        <Tooltip
                          style={{
                            width: buttonWidth,
                            marginLeft: buttonWidth * 4 + 24,
                          }}
                          placement="right"
                          title={submit2Text}
                        >
                          <Button
                            style={{ width: "10vw" }}
                            onClick={() => {
                              UIStore.update((s) => {
                                s.CurrentProject = dataobject;
                              });
                              props.history.push("/ProposalForm");
                            }}
                            type="primary"
                          >
                            View Proposal
                          </Button>
                        </Tooltip>
                      </div>
                    </IonCol>
                  </List.Item>
                </>
              ))
            }
          />
        )}
      </div>
    );
  } else {
    content = (
      <div>
        <div>
          <IonRow>
            <IonCol className="ion-text-center">
              <h3>SP Impact Fund Proposals</h3>
            </IonCol>
          </IonRow>

          {data.length === 0 ? (
            <Empty imageStyle={{ alignSelf: "center" }} />
          ) : (
            <List
              dataSource={[{}]}
              bordered
              renderItem={(item) =>
                data.map((dataobject) => (
                  <>
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={logo} />}
                        title={
                          <p>
                            Lead Name:
                            <Tag color="blue">{dataobject.LeadName}</Tag>
                            {checkStatus(dataobject)}
                          </p>
                        }
                      />

                      <IonCol>
                        <div
                          style={{ paddingBottom: "2%", paddingLeft: "70%" }}
                        >
                          <Tooltip
                            style={{
                              width: buttonWidth,
                              marginLeft: buttonWidth * 4 + 24,
                            }}
                            placement="right"
                            title={submitText}
                          >
                            <Button
                              style={{ width: "10vw" }}
                              onClick={() => {
                                UIStore.update((s) => {
                                  s.CurrentProject = dataobject;
                                });
                                props.history.push("/ProposalForm");
                              }}
                              type="primary"
                            // icon={<ReloadOutlined />}
                            >
                              Create Proposal
                            </Button>
                          </Tooltip>
                        </div>
                      </IonCol>
                    </List.Item>
                  </>
                ))
              }
            />
          )}
        </div>
        <div className="ion-text-center">
          <h3>Applicant Proposals</h3>
          {data.length === 0 ? (
            <Empty imageStyle={{ alignSelf: "center" }} />
          ) : (
            <List
              dataSource={[{}]}
              bordered
              renderItem={(item) =>
                data.map((dataobject) => (
                  <>
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={logo} />}
                        title={
                          <p>
                            Lead Name:
                            <Tag color="blue">{dataobject.ProjectName}</Tag>
                            {checkStatus(dataobject)}
                          </p>
                        }
                      // description="Application in Progress"
                      />
                      {/* <IonCol>
                                      <Tag color="geekblue">
                                          Project Stage: {dataobject.CurrentStatus}
                                      </Tag>
                                  </IonCol> */}
                      <IonCol>
                        <div
                          style={{ paddingBottom: "2%", paddingLeft: "70%" }}
                        >
                          <Tooltip
                            style={{
                              width: buttonWidth,
                              marginLeft: buttonWidth * 4 + 24,
                            }}
                            placement="right"
                            title={submit2Text}
                          >
                            <Button
                              style={{ width: "10vw" }}
                              onClick={() => {
                                UIStore.update((s) => {
                                  s.CurrentProject = dataobject;
                                });
                                props.history.push("/ProposalForm");
                              }}
                              type="primary"
                            // icon={<ReloadOutlined />}
                            >
                              View Proposal
                            </Button>
                          </Tooltip>
                        </div>
                      </IonCol>
                    </List.Item>
                  </>
                ))
              }
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="Proposal" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol class="ion-padding" size="12">
            <Steps current={0} status="error">
              <Step title="Assign Project manager" description="In Progress" />
              <Step
                title="Submission of initial documentation"
                description="No current submissions"
              />
              <Step
                title="Type of application"
                description="No current selection"
              />
              <Step
                title="Submit proposal"
                description="No current submissions"
              />
            </Steps>
          </IonCol>
        </IonRow>
        {content}

        <Modal
          title="Decline Reason"
          visible={isDeclineModalVisible}
          onOk={handleDeclineOk}
          onCancel={handleDeclineCancel}
          style={{ paddingBottom: "0px", height: 1000 }}
          width={900}
        >
          <TextArea
            rows={6}
            //@ts-ignore
            value={globalobject.ReasonForDecline}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default Proposal;
