import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  withIonLifeCycle,
  IonLabel,
  useIonViewDidEnter,
  IonCard,
} from "@ionic/react";
import { useParams } from "react-router";

import { Descriptions, Row, Col, message, Upload, PageHeader } from "antd";
import { Card, Modal } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import {
  UserOutlined,
  LockOutlined,
  UploadOutlined,
  PropertySafetyFilled,
  DatabaseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { menuController } from "@ionic/core";
import {
  DatePicker,
  Space,
  Button,
  Select,
  AutoComplete,
  Cascader,
} from "antd";

import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import logo from "../pages/images/image6.jpg";
import { AntDesignOutlined } from "@ant-design/icons";
import { RegisterAPI, RegisterAPIRoles } from "../store/actions";
import { getcurrentproject } from "../store/actions";
import ImgCrop from "antd-img-crop";

const dateFormat = "YYYY/MM/DD";
const { TextArea } = Input;

const RegistrationPage: React.FC = () => {
  const [fileList, setFileList] = useState([]);

  const { Option } = Select;
  const { name } = useParams<{ name: string }>();
  const style = { paddingLeft: "130px" };
  const style2 = { paddingLeft: "40px" };
  const label = { paddingLeft: "10px" };
  const labelFoot = { paddingLeft: "40px" };
  const label2 = { paddingLeft: "10px" };
  const button = { paddingRight: "50px" };
  const img = { padding: "40px" };

  function onChange(info: any) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      setState((prevState) => ({
        ...prevState,
        ProfilePicture: info.file.response.path,
      }));
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  useIonViewDidEnter(() => {
    // console.log(localStorage.getItem("userobject"));
  });
  const [allowed, setAllowed] = useState(true)
  const [checkOptions, setCheckOptions] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [ApplicationObject, setState] = useState({
    Email: "",
    Password: "",
    Password2: "",
    FullName: "",
    ID: "",
    ProfilePicture: "",
    cellphone: "",
    CompanyName: "",
    FundingRequirement: "",
    PurchaseDocument: "",
    FundingRequirementSummary: "",
  });
  const [Lead, setLead] = useState({
    LeadName: "",
    LeadDescription: "",
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleLeadChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setLead((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSelectChange = (value: any) => {
    setState((prevState) => ({
      ...prevState,
      PurchaseDocument: value.value,
    }));
  };
  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return file.type === "image/jpeg" && file.size / 1024 / 1024 < 2
      ? true
      : Upload.LIST_IGNORE;
  }

  const copyuseridentification = {
    name: "copyuseridentification",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage/main",

    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setState((prevState) => ({
          ...prevState,
          ID: info.file.response.path,
        }));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleOptionClicked = (index: number, e: any) => {
    if (e.value === "Yes") {
      const temp = checkOptions;
      temp[index] = true;
      setCheckOptions(temp);
    } else {
      const temp = checkOptions;
      temp[index] = false;
      setCheckOptions(temp);
    }
    const filtered = checkOptions.filter((option) => option === true);
    if (filtered.length === 12 && checkOptions[1] === false) {
      setAllowed(false)
      console.log("yes")
    } else if (filtered.length === 11 && checkOptions[2] === false && checkOptions[3] === false) {
      setAllowed(false)
      console.log("yes")
    } else {
      setAllowed(true)
      console.log("no")
    }
  };



  const handleRegister = () => {
    const filtered = checkOptions.filter((option) => option === true);
    if (filtered.length === 12 && checkOptions[1] === false) {
      setAllowed(false)
    } else if (filtered.length === 11 && checkOptions[2] === false && checkOptions[3] === false) {
      setAllowed(false)
    } else {
      setAllowed(true)
      console.log("no")
    }


    // if (filtered.length === 13) {
    //   console.log("can register");
    //   RegisterAPIRoles(ApplicationObject, Lead);
    // }
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRow
          style={{
            paddingTop: 5,
            padding: 30,
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <IonCol>
            <Card style={{ width: "100%" }}>
              <IonRow>
                <IonCol>
                  <img src={logo} alt="logo" />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.Email}
                    type="email"
                    width="100px"
                    onChange={handleChange}
                    name="Email"
                    placeholder="Email"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.FullName}
                    type="email"
                    width="100px"
                    onChange={handleChange}
                    name="FullName"
                    placeholder="Full Name"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.cellphone}
                    type="email"
                    width="100px"
                    onChange={handleChange}
                    name="cellphone"
                    placeholder="Cellphone Number"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.Password}
                    type="password"
                    onChange={handleChange}
                    name="Password"
                    placeholder="Password"
                    prefix={<LockOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.Password2}
                    type="password"
                    onChange={handleChange}
                    name="Password2"
                    placeholder="Confirm Password"
                    prefix={<LockOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.CompanyName}
                    type="text"
                    onChange={handleChange}
                    name="CompanyName"
                    placeholder="Company Name"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.FundingRequirement}
                    type="text"
                    onChange={handleChange}
                    name="FundingRequirement"
                    placeholder="Funding Requirement"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Select
                    labelInValue
                    //@ts-ignore
                    defaultValue={{
                      label:
                        "Do you have a Purchase Order/Contract/Invoice?  Y/N",
                    }}
                    style={{ width: "100%" }}
                    onChange={handleSelectChange}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <TextArea
                    value={ApplicationObject.FundingRequirementSummary}
                    onChange={handleChange}
                    name="FundingRequirementSummary"
                    placeholder="Funding  Requirement Summary"
                  />
                </IonCol>
              </IonRow>
            </Card>

            <div>
              <IonCard style={{ margin: "2vw" }}>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>Is your company 51% Black-Owned?</strong>
                    </p>
                  </IonCol>
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(0, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>Are you registered with CIDB?</strong>
                    </p>
                  </IonCol>
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(1, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>
              <IonCard style={{ margin: "2vw" }}>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>If “NO” please confirm: </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Have you ever submitted an application to the CIDB?
                      </strong>
                    </p>
                  </IonCol>
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(2, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you in the process of applying to the CIDB?
                      </strong>
                    </p>
                  </IonCol>
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(3, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>

              <IonCard style={{ margin: "2vw" }}>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>Is your company registered with the CIPC?</strong>
                    </p>
                  </IonCol>
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(4, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you able to submit financial statements for the last
                        year?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(5, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>

              <IonCard style={{ margin: "2vw" }}>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Please confirm whether or not you are willing to comply
                        with the following:
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you willing to submit a formal application?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(5, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you willing to adhere to sp impact fund’s
                        application assessment and due diligence processes?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(6, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you willing to have a training needs analysis
                        conducted?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(7, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        If yes, are you willing to attend training courses based
                        on the results of the needs analysis conducted and a
                        training development plan?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(8, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you willing to employ workers to help complete your
                        project at the minimum industry wage prescribed?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(9, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you willing to enter into a materials supply
                        agreement with sp impact fund?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(10, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you willing to enter into a materials supply
                        agreement with sp impact fund that includes ceding
                        income to the value of any funding approved?{" "}
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(11, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <p>
                      <strong>
                        Are you willing to open a ring-fenced account with sp
                        impact fund being the sole administrator on the account?
                      </strong>
                    </p>
                  </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                  <IonCol class="ion-padding">
                    <div>
                      <Select
                        labelInValue
                        placeholder="Yes/No"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleOptionClicked(12, e);
                        }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow class="ion-align-items-center">
                  <IonCol></IonCol>
                  <IonCol>
                    <Button
                      disabled={allowed}
                      onClick={() => {
                        RegisterAPIRoles(ApplicationObject, Lead)
                      }}
                      block
                      type="primary"
                    >
                      Register
                    </Button>
                  </IonCol>

                  <IonCol></IonCol>
                </IonRow>

                <IonRow class="ion-align-items-center">
                  <IonCol></IonCol>
                  <IonCol>
                    <Button href="/Login" block type="primary">
                      Existing Account
                    </Button>
                  </IonCol>
                  <IonCol></IonCol>
                </IonRow>
              </IonCard>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default RegistrationPage;
