import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Modal,
  Input,
  message,
  Tag,
  Empty,
  Select,
} from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import axios from "axios";

import { DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ProjectStatus, UIStore } from "../store/UIStore";
import { getDocsArray, getDocsUriArray } from "../store/actions";
const dateFormat = "YYYY/MM/DD";

const Screening: React.FC = (props: any) => {
  const [applicationpacks, setapplicationpacks] = useState<any[]>([]);
  const { Step } = Steps;
  const { Option } = Select;
  const [data, setdata] = useState<any[]>([]);

  //moda
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDocModalVisible, setisDocModalVisible] = useState(false);
  const [projectReco, setProjectReco] = useState("");
  const [globalObject, setGlobalObject] = useState({});
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showDocModal = () => {
    setisDocModalVisible(true);
  };

  function loadProject(projectobject: any) {
    UIStore.update((s) => {
      s.CurrentProject = projectobject;
      console.log(CurrentProject);
      props.history.push("/EligibilityCriteria");
    });

    // console.log(projectobject);

    //
  }

  async function changeStatus(object: any) {
    object.CurrentStatus = ProjectStatus.FMRECCOMENDATION;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/projects/fundmanagerdash"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
    console.log(object);
  }

  async function sendProjectReco(object: any) {
    object.CurrentStatus = ProjectStatus.FMRECCOMENDATION;
    object.FundManagerRecommendation = projectReco;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/projects/fundmanagerdash"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
    console.log(object);
  }

  async function DownloadPack(object: any) {
    //turn docs file links into an array

    let linkarr = [];

    linkarr.push(object.Spif);
    linkarr.push(object.BOQ);
    linkarr.push(object.ImplementationPlan);
    linkarr.push(object.Letterofappointment);
    linkarr.push(object.BankingStatements);
    linkarr.push(object.ManagementAccounts);
    linkarr.push(object.Insurance);
    linkarr.push(object.CIDBDoc);
    linkarr.push(object.NHBRC);
    linkarr.push(object.TaxClearanceDoc);
    linkarr.push(object.Vat);
    linkarr.push(object.DepartLabor);
    linkarr.push(object.personnel);
    linkarr.push(object.AllDirectors);
    linkarr.push(object.MartialSTatus);
    linkarr.push(object.BBEECert);
    linkarr.push(object.Companyregdocuments);
    linkarr.push(object.ApplicationForm);
    linkarr.push(object.BaselineForm);
    linkarr.push(object.CIPCForm);
    linkarr.push(object.CreditagreementForm);
    linkarr.push(object.companyprofile);
    linkarr.push(object.ProofAddress);
    linkarr.push(object.DeclarationForm);
    linkarr.push(object.JVForm);
    linkarr.push(object.EligibilityCriteriaForm);
    linkarr.push(object.Resolution);
    linkarr.push(object.ShareholdingConfirmation);
    linkarr.push(object.TaxPin);
    linkarr.push(object.BOQ2);
    linkarr.push(object.CompletionCertificate);
    linkarr.push(object.CPO);
    linkarr.push(object.RFQ);
    linkarr.push(object.SuppliersQ);
    linkarr.push(object.Cinvoice);
    linkarr.push(object.DebtAge);
    linkarr.push(object.EditorsAge);
    linkarr.push(object.PoCl);
    linkarr.push(object.MonthBankState);
    linkarr.push(object.BillofQuanties);
    linkarr.push(object.SLA);
    linkarr.push(object.CletterofAppointment);
    linkarr.push(object.ProjectImplentaionPlan);
    linkarr.push(object.CinvoicePaidbyPayMaster);
    linkarr.push(object.PaymentCert);
    linkarr.push(object.DebtandCred);

    linkarr.map((mainurl) => {
      if (mainurl.length !== 0) {
        axios({
          url: mainurl,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            mainurl.replace(
              " https://dev.northroom.co.za/api/spfund/static/",
              ""
            )
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    });
  }

  function handleOk(object: any) {
    sendProjectReco(object);
    setIsModalVisible(false);
  }
  function handleDocOk(object: any) {
    setisDocModalVisible(false);
  }
  const handleDocCancel = () => {
    setisDocModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleComment = (e: any) => {
    setProjectReco(e.target.value);
  };
  //---end modal

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedscreening/" +
          object.id
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/projects/fundmanagerdash"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error occurred");
    }
  }
  async function ExceptionReport(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as Exception", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedscreeningexception",
          {
            id: object.id,
            ScreeningOfficerExceptioncomment: exception,
          }
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/projects/awaitingproposal"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("ar error occurred");
    }
  }

  // async function ApproveProject(object: any) {
  //   object.CurrentStatus = ProjectStatus.AWAITINGCONTRACT;

  //   message
  //     .loading("Action in progress..", 3)
  //     .then(() => message.success("Marked as verified", 2));
  //   try {
  //     const request = await axios
  //       .post(
  //         "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
  //         object
  //       )
  //       .then((response) => {});
  //   } catch (error:any) {
  //     message.error("An error has occurred");
  //   }
  // }

  useIonViewWillEnter(() => {
    let temp: any[] = [];
    axios
      .get("https://dev.northroom.co.za/api/spfund/projects/fundmanagerdash")
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    // axios
    // .get(
    //   "https://dev.northroom.co.za/api/spfund/projects/fmrecommendationphase"
    // )
    // .then(function (response) {
    //   console.log(response.data);

    //   setdata([...data,...response.data])
    // })
    // .catch(function (error) {
    //   // handle error
    //   console.log(error);
    // })
    // .then(function () {
    //   // always executed
    // });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  //modal
  const [exception, setException] = useState("");
  const [Excvisible, setVisible] = useState(false);
  const showExcModal = () => {
    setVisible(true);
  };
  const handleExcCancel = () => {
    setException("");
    setVisible(false);
  };

  const handleExcComment = (object: any) => {
    console.log(object);
    ExceptionReport(object);
    setVisible(false);
  };

  const checkStatus = (Project: any) => {
    if (Project.CurrentStatus === "VERIFIEDDOCUMENTSFM") {
    } else if (Project.CurrentStatus === "") {
    }
  };
  const onDownloadChange = (val: any) => {
    console.log("Link!");
    window.open(val, "_blank");
  };

  function getSelect(object: any) {
    //@ts-ignore
    console.log(CurrentProject.user)
    let content = (
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select file to download"
        optionFilterProp="children"
        onChange={onDownloadChange}
        filterOption={(input, option) =>
          // console.log(option)
          //@ts-ignore
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {getDocsArray(object).map((item) => {
          return <Option value={item.uri} key={item.name + object.id}>{item.name}</Option>;
        })}
      </Select>
    );

    return content;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Screening Dashboard"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "6%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={0} status="error">
              <Step title="Fund Manager screening" description="In progress" />
              <Step
                title="Application officer"
                description="Application verification incomplete"
              />
              <Step
                title="Compliance officer"
                description="Compliance verification incomplete"
              />
              <Step title="Vetting procedure" description="Incomplete" />
            </Steps>
          </IonCol>
        </IonRow>

        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Project Application ID:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                        </p>
                      }
                      description={
                        "Project Status: " + dataobject.CurrentStatus
                      }
                    />
                  </List.Item>

                  <List.Item>
                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                          disabled={
                            dataobject.CurrentStatus ===
                              ProjectStatus.VERIFIEDDOCUMENTSFM
                              ? false
                              : true
                          }
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            showExcModal();
                          }}
                          type="primary"
                          disabled={
                            dataobject.CurrentStatus ===
                              ProjectStatus.VERIFIEDDOCUMENTSFM
                              ? false
                              : true
                          }
                        >
                          Mark for Exception Report
                        </Button>
                        <Modal
                          title="Exception"
                          visible={Excvisible}
                          onCancel={handleExcCancel}
                          width={500}
                          centered
                          onOk={() => {
                            handleExcComment(dataobject);
                          }}
                        >
                          <IonRow>
                            <IonCol>
                              <TextArea
                                value={exception}
                                rows={4}
                                onChange={(e) => {
                                  setException(e.target.value);
                                }}
                                name="exception"
                                placeholder="exception comment"
                              />
                            </IonCol>
                          </IonRow>
                        </Modal>
                      </IonCol>
                      <IonCol>
                        <Button
                          onClick={() => {
                            UIStore.update((s) => {
                              s.CurrentProject = dataobject;
                              props.history.push("/RFP");
                            });
                          }}
                          type="primary"
                          disabled={
                            dataobject.CurrentStatus ===
                              ProjectStatus.AWAITINGREQUESTFORPAYMENTAPPROVAL
                              ? false
                              : true
                          }
                        >
                          Download RFP
                        </Button>
                      </IonCol>
                      <IonCol>
                        <Button
                          onClick={() => {
                            UIStore.update((s) => {
                              s.CurrentProject = dataobject;
                            });
                            if (dataobject.PaymentInstruction === null) {
                              UIStore.update((s) => {
                                s.CurrentProject.PaymentInstruction = {
                                  Client: "",
                                  ProjectDescription: "",
                                  TotalFundingApproved: "",
                                  DrawdownRequired: "",
                                  SupplierName: "",
                                  AmountPayable: "",
                                  Bank: "",
                                  AccountNumber: "",
                                  PFAName: "",
                                  FMEName: "",
                                  CEName: "",
                                  DocRequestForPayment: "",
                                  DocDrawdown: "",
                                  DocSuppliersQ: "",
                                  DocSupplierConfirmation: "",
                                  PFACapacity: "",
                                  FMESignature: "",
                                  CECapacity: "",
                                  CESignature: "",
                                  FMRSignature: "",
                                  FMRCapacity: "",
                                  PFAsignature: "",
                                };
                              });
                            }
                            props.history.push("/PaymentInstruction");
                          }}
                          type="primary"
                          disabled={
                            dataobject.CurrentStatus ===
                              ProjectStatus.AWAITINGPAYMENTINSTRUCTION
                              ? false
                              : true
                          }
                        >
                          Payment Instruction
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            showModal();
                            setGlobalObject(dataobject);
                          }}
                          disabled={
                            dataobject.CurrentStatus ===
                              ProjectStatus.FMPROJECTVIABILITY
                              ? false
                              : true
                          }
                          type="primary"
                        >
                          Project Recommendation
                        </Button>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        {dataobject.RiskOfficerExceptioncomment ? (
                          <Tag color="orange">
                            Risk officer:
                            {dataobject.RiskOfficerExceptioncomment}
                          </Tag>
                        ) : (
                          ""
                        )}
                      </IonCol>
                      <IonCol>
                        {getSelect(dataobject)}
                        {/* <Select
                          options={options}
                          showSearch
                          style={{ width: 200 }}
                          placeholder="Select file to download"
                          optionFilterProp="children"
                          onChange={onDownloadChange}
                          onClick={() => { setGlobalObject(dataobject) }}
                          filterOption={(input, option) =>
                            //@ts-ignore
                            option.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          }
                        > */}
                        {/* {getDocsArray(dataobject).map((item) => {
                            console.log(item.name);
                            return (
                              <Option value={item.uri}>{item.name}</Option>
                            );
                          })} */}
                        {/* </Select> */}
                      </IonCol>
                      <IonCol>
                        <Button
                          onClick={() => {
                            sendProjectReco(dataobject)
                            setGlobalObject(dataobject);
                            showDocModal();
                          }}
                        >
                          View pack
                        </Button>
                      </IonCol>
                    </IonRow>
                  </List.Item>
                </>
              ))
            }
          />
        )}
        <Modal
          title="Provide Project Recommendation"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button
              onClick={(e) => {
                handleOk(globalObject);
              }}
              type="primary"
            >
              Submit
            </Button>,
          ]}
          style={{ paddingBottom: "0px" }}
        >
          <TextArea
            value={projectReco}
            placeholder="Project Recommendation"
            rows={4}
            onChange={(e) => {
              handleComment(e);
            }}
          />
        </Modal>

        <Modal
          title="Document viewer"
          visible={isDocModalVisible}
          onCancel={handleDocCancel}
          style={{ paddingBottom: "0px", height: 1000 }}
          width={1000}
        >
          <DocViewer
            style={{ width: 850, height: 700 }}
            pluginRenderers={DocViewerRenderers}
            documents={getDocsUriArray(globalObject)}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default Screening;
