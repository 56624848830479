import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Tooltip,
  Empty,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import {
  PlusOutlined,
  ProjectOutlined,
  ReloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { setcurrentproject } from "../store/actions";
import { UIStore } from "../store/UIStore";
import { Link } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";

const dateFormat = "YYYY/MM/DD";

const Addendums: React.FC = (props: any) => {
  const showModalCredit = () => {
    setIsModalCreditVisible(true);
  };

  const showModalJV = () => {
    setIsModalJVVisible(true);
  };
  const [cAAddendum, setCAAddendum] = useState("");
  const [jVAddendum, setJVAddendum] = useState("");
  
  const handleCreditOk = async (object: any) => {
    object.CAAddendum = cAAddendum;
  
    try {
      const request = await axios
        .post("https://dev.northroom.co.za/api/spfund/users/ProjectUpdate/", 
          object
        )
        .then((response) => {
         console.log(response)
        });
    } catch (err) {
      
    }
    setIsModalCreditVisible(false);
  };

  const handleJVOk = async (object: any) => {
    object.JVAddendum = jVAddendum;
    
    try {
      const request = await axios
        .post("https://dev.northroom.co.za/api/spfund/users/ProjectUpdate/", 
          object,
        )
        .then((response) => {
         
        });
    } catch (err) {
      
    }
    setIsModalJVVisible(false);
  };

  const handleCreditCancel = () => {
    setIsModalCreditVisible(false);
  };

  const handleJVCancel = () => {
    setIsModalJVVisible(false);
  };

  const { Step } = Steps;

  const [ProjectName, setProjectName] = useState("");
  const [isModalCreditVisible, setIsModalCreditVisible] = useState(false);
  const [isModalJVVisible, setIsModalJVVisible] = useState(false);
  const [data, setdata] = useState<any[]>([]);

  const createText = (
    <span>Create a project, if you do not have one created below.</span>
  );
  const loadText = <span>Load an existing project.</span>;
  const buttonWidth = 70;

  const [projects, setprojects] = useState<any[]>([]);
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const noProjectstyle = { textalign: "center" };
  
  useIonViewDidEnter(() => {
    axios
    .get(
      "https://dev.northroom.co.za/api/spfund/users/projectmanager/" + user.id
    )
      .then(function (response) {
        const projects = response.data.filter((project: { CurrentStatus: string; }) => project.CurrentStatus === "AWAITINGCONTRACT");
        
      setdata(projects);

      console.log(projects);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  // const CreateProject = async () => {
  //     setModalText('Processing Request');
  //     setConfirmLoading(true);
  //     setTimeout(() => {
  //         setVisible(false);
  //         setConfirmLoading(false);
  //     }, 2000);

  //     message
  //         .loading('Action in progress..', 5)
  //         .then(() => message.success('Submission Succeeded', 5));
  //     try {
  //         const request = await axios.post('https://dev.northroom.co.za/api/spfund/users/createproject', { userid: user.id, ProjectName: ProjectName }).then((response) => {

  //             message.success(response.data);
  //             console.log(response);
  //             // props.history.push("/NewProject");
  //             axios.get('https://dev.northroom.co.za/api/spfund/users/projectlist/' + user.id)
  //                 .then(function (response) {

  //                     //23 items

  //                     setdata(response.data);

  //                 })
  //                 .catch(function (error) {
  //                     // handle error
  //                     console.log(error);
  //                 })
  //                 .then(function () {
  //                     // always executed
  //                 });

  //         });

  //     } catch (error) {
  //         message.error(error);
  //     }
  // };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="Addendum's" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
     
              {data.length === 0 ? 
             
             
              <Empty imageStyle={{alignSelf: "center"}}/>
          
          
          :  <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (           
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>Project Application Name:<Tag color="blue">{dataobject.LeadName}</Tag></p>
                      }
                      description={dataobject.CurrentStatus}
                    />
                    <IonRow>
                      <IonCol>
                        <Button type="primary" onClick={showModalCredit}>
                          Credit Agreement Addendum
                        </Button>
                        <Modal
                          title="Credit Agreement"
                          visible={isModalCreditVisible}
                          onOk={() => { handleCreditOk(dataobject) }}
                          onCancel={handleCreditCancel}
                        >
                          
                          <TextArea
                            value={cAAddendum}
                            onChange={(e: any) => {
                              setCAAddendum(e.target.value);
                            }}
                            name="CreditAddendum"
                            placeholder="Provide addendum details for the credit agreement page"
                          />
                        </Modal>
                      </IonCol>

                      <IonCol>
                        <Button type="primary" onClick={showModalJV}>
                          Joint Venture Agreement Addendum
                        </Button>
                        <Modal
                          title="Joint Venture Agreement"
                          visible={isModalJVVisible}
                          onOk={() => { handleJVOk(dataobject) }}
                          onCancel={handleJVCancel}
                        >
                        
                          <TextArea
                            value={jVAddendum}
                            onChange={(e: any) => {
                              console.log(e.target.value)
                              setJVAddendum(e.target.value);
                            }}
                            name="CreditAddendum"
                            placeholder="Provide addendum details for the joint venture agreement page"
                          />
                        </Modal>
                      </IonCol>
                    </IonRow>
                  </List.Item>
                </>
              ))
            }
              />}
       
         
              
      </IonContent>
    </IonPage>
  );
};

export default Addendums;
