import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonCard,
} from "@ionic/react";

import { useParams } from "react-router";

import { Descriptions, Row, Col } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import moment from "moment";
import { Dropdown, Menu, message, InputNumber, Upload, Radio } from "antd";

import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import React, { useState } from "react";
import { PageHeader } from "antd";
import axios from "axios";
import { notification } from "antd";
import { ProjectStatus, UIStore } from "../store/UIStore";
const dateFormat = "YYYY/MM/DD";
const { TextArea } = Input;

const ProjectBrief: React.FC = (props: any) => {
  const [value, setValue] = React.useState(1);
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const onRadioChange = (e: any) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const { name } = useParams<{ name: string }>();
  const [count, setCount] = useState("");

  function onChange(checked: any) {
    console.log(checked.target.value)
    setState((prevState) => ({
      ...prevState,
      PBFundReq: checked.target.value,
    }));
  }

  function onChangeDatePicker(DateWorkCompleted: any, dateString: any) {
    setState((prevState) => ({
      ...prevState,
      PBLongJobCommencementDate: dateString,
    }));
  }

  async function SubmitProjectBrief() {
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));

    // try {
    //   const request = await axios
    //     .post(
    //       "https://dev.northroom.co.za/api/spfund/users/ProjectUpdatebrief",
    //       CurrentProject
    //     )
    //     .then((response) => {});
    // } catch (error:any) {
    //   message.error("An error has occurred");
    // }

    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/submitprojectbrief/" +
          CurrentProject.id,
          ProjectBrief
        )
        .then((response) => {
          console.log(response);
        });
    } catch (error: any) {
      message.error("Error has ocurred");
    }
    props.history.push("/ProjectManager");
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const [ProjectBrief, setState] = useState({
    PBEntity: "",
    PBProject: "",
    PBResponsiblePM: "",
    PBDate: "",
    PBProjectDesc: "",
    PBStakeholdersNorg: "",
    PBImmedProjectGoal: "",
    PBStatus: "",
    PBEstProjectDuration: "",
    PBReminderProjectDuration: "",
    PBStartDate: "",
    PBEstCompDate: "",
    PBShortNumOfJobs: "",
    PBShortJobDuration: "",
    PBShortJobCommencementDate: "",
    PBLongNumOfJobs: "",
    PBLongJobDuration: "",
    PBLongJobCommencementDate: "",
    PBFundReq: "",
    PBValueAppointmentLetter: "",
    PBFundReq2: "",
    PBClientSuppName: "",
    PBContactDetails: "",
    PBFormOfTraining: "",
    PBSuggestTraining: "",
    PBEstimatedCostTraining: "",
    PBNumOfPplTrain: "",
  });
  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGlobalChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    UIStore.update((s) => {
      s.CurrentProject.LeadDescription = e.target.value;
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader className="site-page-header" title="Project Brief" />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonCard style={{ margin: "2vw" }}>
            <Descriptions
              className="ion-text-center"
              title="Project Summary Brief"
            ></Descriptions>

            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong>Entity</strong>
                </p>
                <Input
                  value={CurrentProject.BusinessName}
                  type="text"
                  onChange={handleChange}
                  name="BusinessName"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Project</strong>
                </p>
                <Input
                  value={CurrentProject.LeadName}
                  type="text"
                  onChange={handleChange}
                  name="TradingName"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Responsible Project Manager</strong>
                </p>
                <Input
                  value={CurrentProject.ProjectManagerName}
                  type="text"
                  onChange={handleChange}
                  name="Entity"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Date</strong>
                </p>
                <DatePicker
                  defaultValue={moment("2021/01/01", dateFormat)}
                  format={dateFormat}
                  value={CurrentProject.ProjectStart ? moment(Date.now()) : moment(CurrentProject.ProjectStart)}
                />
              </IonCol>
            </Row>
            <Row></Row>
            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>PROJECT OVERVIEW</strong>
                </h6>
              </IonCol>
            </IonRow>
            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong>Project Description</strong>
                </p>
                <TextArea
                  value={CurrentProject.LeadDescription}
                  placeholder=""
                  rows={4}
                  onChange={handleGlobalChange}
                  name="LeadDescription"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Project Stakeholders and Organization</strong>
                </p>
                <TextArea
                  value={ProjectBrief.PBStakeholdersNorg}
                  placeholder=""
                  rows={4}
                  onChange={handleChange}
                  name="PBStakeholdersNorg"
                />
              </IonCol>
            </Row>
            <Row>
              <IonCol className="ion-text-center">
                <p>
                  <strong>
                    Immediate Project Goal – Where Funds Will Be Dedicated To
                  </strong>
                </p>
                <TextArea
                  value={ProjectBrief.PBImmedProjectGoal}
                  placeholder=""
                  rows={4}
                  name="PBImmedProjectGoal"
                  onChange={handleChange}
                />
              </IonCol>
            </Row>

            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong>Current Status of Project</strong>
                </p>
                <Input
                  value={ProjectBrief.PBStatus}
                  type="text"
                  onChange={handleChange}
                  name="PBStatus"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Estimated project duration</strong>
                </p>
                <Input
                  value={ProjectBrief.PBEstProjectDuration}
                  type="text"
                  onChange={handleChange}
                  name="PBEstProjectDuration"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Remainder project duration (to date)</strong>
                </p>
                <Input
                  value={ProjectBrief.PBReminderProjectDuration}
                  type="text"
                  onChange={handleChange}
                  name="PBReminderProjectDuration"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Start Date</strong>
                </p>
                <DatePicker
                  defaultValue={moment("2021/01/01", dateFormat)}
                  format={dateFormat}
                  value={CurrentProject.ProjectStart.length === 0 ? moment(Date.now()) : moment(CurrentProject.ProjectStart)}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Estimated completion date</strong>
                </p>
                <DatePicker
                  defaultValue={moment("2021/01/01", dateFormat)}
                  format={dateFormat}
                  value={CurrentProject.ProjectEnd.length === 0 ? moment(Date.now()) : moment(CurrentProject.ProjectEnd)}
                />
              </IonCol>
            </Row>
            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>JOB CREATION SCHEDULE</strong>
                </h6>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <p>
                  <strong>Short-term Jobs</strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>Number of Jobs</p>
                <Input
                  value={ProjectBrief.PBShortNumOfJobs}
                  type="number"
                  onChange={handleChange}
                  name="PBShortNumOfJobs"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>Duration </p>
                <Input
                  value={ProjectBrief.PBShortJobDuration}
                  type="text"
                  onChange={handleChange}
                  name="PBShortJobDuration"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>Commencement Date</p>
                <DatePicker
                  defaultValue={moment("2021/01/01", dateFormat)}
                  format={dateFormat}
                  value={CurrentProject.ProjectStart.length === 0 ? moment(Date.now()) : moment(CurrentProject.ProjectStart)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <p>
                  <strong>Long-term Jobs</strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>Number of Jobs</p>
                <Input
                  value={ProjectBrief.PBLongNumOfJobs}
                  type="number"
                  onChange={handleChange}
                  name="PBLongNumOfJobs"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>Duration </p>
                <Input
                  value={ProjectBrief.PBLongJobDuration}
                  type="text"
                  onChange={handleChange}
                  name="PBLongJobDuration"
                />
              </IonCol>
              <IonCol className="ion-text-center">
                <p>Commencement Date</p>
                <DatePicker
                  defaultValue={moment("2021/01/01", dateFormat)}
                  format={dateFormat}
                  value={ProjectBrief.PBLongJobCommencementDate.length === 0 ? moment(Date.now()) : moment(ProjectBrief.PBLongJobCommencementDate)}
                  name="PBLongJobCommencementDate"
                  onChange={onChangeDatePicker}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <h6>
                  <strong>FUNDING REQUIREMENT</strong>
                </h6>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <Radio.Group
                  onChange={onChange}
                  value={ProjectBrief.PBFundReq}
                  style={{ paddingLeft: "21vw" }}
                >
                  <Radio value={"Invoice Discounting"}>
                    <strong>Invoice Discounting</strong>
                  </Radio>
                  <Radio value={"Purchase Order Financing"}>
                    <strong>Purchase Order Financing</strong>
                  </Radio>
                  <Radio value={"Construction Financing"}>
                    <strong>Construction Financing</strong>
                  </Radio>
                </Radio.Group>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Value of project as per appointment letter</strong>
                </p>
                <Input
                  prefix="R"
                  value={ProjectBrief.PBValueAppointmentLetter}
                  type="text"
                  onChange={handleChange}
                  name="PBValueAppointmentLetter"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Funding requirement</strong>
                </p>
                <Input
                  value={ProjectBrief.PBFundReq2}
                  type="text"
                  onChange={handleChange}
                  name="PBFundReq2"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Client/Supplier Name</strong>
                </p>
                <Input
                  value={ProjectBrief.PBClientSuppName}
                  type="text"
                  onChange={handleChange}
                  name="PBClientSuppName"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Contact Details of Client/Supplier</strong>
                </p>
                <Input
                  value={ProjectBrief.PBContactDetails}
                  type="number"
                  onChange={handleChange}
                  name="PBContactDetails"
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>PROJECT TRAINING NEEDS</strong>
                </h6>
              </IonCol>
            </IonRow>
            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong>Form of training that may be required </strong>
                </p>
                <TextArea
                  value={ProjectBrief.PBFormOfTraining}
                  placeholder=""
                  rows={4}
                  onChange={handleChange}
                  name="PBFormOfTraining"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Suggested training courses</strong>
                </p>
                <TextArea
                  value={ProjectBrief.PBSuggestTraining}
                  placeholder=""
                  rows={4}
                  onChange={handleChange}
                  name="PBSuggestTraining"
                />
              </IonCol>
            </Row>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Estimated cost of training programs</strong>
                </p>
                <Input
                  prefix="R"
                  value={ProjectBrief.PBEstimatedCostTraining}
                  type="number"
                  onChange={handleChange}
                  name="PBEstimatedCostTraining"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Number of people to be trained</strong>
                </p>
                <Input
                  value={ProjectBrief.PBNumOfPplTrain}
                  type="number"
                  onChange={handleChange}
                  name="PBNumOfPplTrain"
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol class="ion-padding">
                <Button
                  size={"large"}
                  onClick={() => {
                    SubmitProjectBrief();
                  }}
                  type="primary"
                >
                  Submit
                </Button>
              </IonCol>
            </IonRow>
          </IonCard>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default ProjectBrief;
