import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonGrid,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  message,
  Typography,
  Table,
  Tag,
  Radio,
} from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import moment from "moment";
import { PageHeader } from "antd";
import axios from "axios";
import { UIStore } from "../store/UIStore";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import React, { useState } from "react";

const dateFormat = "YYYY/MM/DD";
const { TextArea } = Input;

const FinalRiskAssesment: React.FC = (props: any) => {
  const { name } = useParams<{ name: string }>();

  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }

  async function SubmitBaseline() {
    console.log(CurrentProject);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdateBaseline",
          CurrentProject
        )
        .then((response) => {
          // message.success(response.data);
          console.log(response);
          props.history.push("/ProjectManager");
        });
    } catch (error: any) {
      console.error(error);
    }
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const { TextArea } = Input;
  const style = { name: { paddingLeft: "2vw", paddingRight: "2vw" } };
  const { Title } = Typography;

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  useIonViewDidEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/users/compliance")
      .then(function (response) {
        //23 items
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  const options = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
    { label: "NA", value: "NA" },
  ];
  const optionsVerifeid = [
    { label: "Verified", value: "Verified" },
    { label: "Not Verified", value: "Not Verified" },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            onBack={() => props.history.goBack()}
            title="Final Risk Assessment"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ padding: "2vw" }}>
          <IonRow>
            <IonCol>
              <div>
                <h6>
                  <strong>ITEM</strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>YES/NO/NA</strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>MEANS OF VERIFICATION</strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>VERIFICATION STATUS </strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>COMMENTS</strong>
                </h6>
              </div>
            </IonCol>
          </IonRow>
          <br></br>
          <IonRow>
            <IonCol>
              <div>
                <p>Company Profile</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.CompanyProfileExistance = e.target.value;
                    });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Company Profile</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.CompanyProfileVerification = e.target.value;
                    });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CompanyProfileComment = e.target.value;
                  });
                }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Company registration documents</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.CompanyRegistrationExistance = e.target.value;
                    });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Independent verification with CIPC office</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.CompanyRegistrationVerification = e.target.value;
                    });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CompanyRegistrationComment = e.target.value;
                  });
                }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>B-BBEE Certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.BbbeeCertificateExistance = e.target.value;
                    });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Affidavit/Certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Marital status and type of marital contract</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Signed declaration and certified marital certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Identity Documents of all owners or directors at group
                  level(within the last three months)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Clear certified copies</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Proof of Address(all directors,company)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Certified proof of address</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Authorized Personal</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Director or Company Resolution giving the responsible person
                  authorization
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Department of Labour - Letter of Good Standing</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Confirmation with the Department of Labour</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Tax Clearance Certificate and tax pin</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>SARS Certificate - Expiry data checks</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>NHBRC certificate (or relevant certification)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>NHBRC certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>CIDB Certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Confirmation with CIDB</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  CSD registration report (generated within the last three
                  months)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>CSD registration report</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Proof of insurance including liability cover(where applicable)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Proof of cover and policy schedule</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Financials/Management accounts (three-year historic financials
                  for a funding requirement of more than R15 million)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Attached and Confirmed with Auditor or Accountant/A letter of
                  good standing from a bank without accountant/auditor services.
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Bank confirmation letter</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Attached Bank Confirmation Letter</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Banking statements (not older than 6 months)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Banking Statements need to be attached</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Debtors Age Analysis</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Debtors Age Analysis</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Letter of appointment</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Attached and verified with the relevant project owner in
                  writing
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Signed Contract with Client</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Attached and verified with the relevant project owner in
                  writing
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Implementation Plan</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Implementation Plan</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Priced Bills of Quantities (or related purchase order)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Confirmation of Validity Received from the project Operation
                  Teams
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Project Completion Certificate of Similar Project (where
                  applicable)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Project Completion Certificate of Similar</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  onChange={() => {
                    UIStore.update((s) => { });
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea placeholder="" rows={2} onChange={(e) => { }} />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonRow>
          <IonCol class="ion-padding">
            <Button
              size={"large"}
              type="primary"
              onClick={() => {
                SubmitBaseline();
              }}
            >
              Submit
            </Button>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default FinalRiskAssesment;
