import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import { Select, Typography } from "antd";
import { Row, TreeSelect, Table, InputNumber } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import { DatePicker, Space, Button, Tooltip } from "antd";
import "./Page2.css";
import moment from "moment";
import { Steps, message } from "antd";
import React, { useState } from "react";
import { PageHeader } from "antd";
import SignatureCanvas from "react-signature-canvas";
import ReactDOM from "react-dom";
import axios from "axios";
import { setConstantValue } from "typescript";
import { TreeNode } from "antd/lib/tree-select";
import Modal from "antd/lib/modal/Modal";
import { PlusOutlined, ProjectOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { UIStore } from "../store/UIStore";
import { checkProjectEmpty } from "../store/actions";

const dateFormat = "YYYY/MM/DD";
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

const columns = [
  {
    title: "Project Start Date",
    dataIndex: "ProjectStartDate",
  },
  {
    title: "Description of project",
    dataIndex: "Description",
  },
  {
    title: "Value of project",
    className: "column-money",
    dataIndex: "Value",
  },
  {
    title: "Role of  SMME in Project",
    dataIndex: "SMMERole",
  },
  {
    title: "Client",
    dataIndex: "Client",
  },
  {
    title: "Contact Details of Client",
    dataIndex: "ClientContact",
  },
];

const columns2 = [
  {
    title: "Name",
    dataIndex: "DirectorKeyName",
  },
  {
    title: "Surname",
    dataIndex: "DirectorKeySurname",
  },
  {
    title: "ID Number",
    dataIndex: "DirectorKeyIDNumber",
  },
  {
    title: "Designation",
    dataIndex: "DirectorKeyDesignation",
  },
];

const ApplicationForm: React.FC = (props: any) => {
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};

  // function onChange(checked: any) {
  //   console.log(`switch to ${checked}`);
  // }

  const options: any = [];

  countryList.forEach((country) => {
    options.push({
      value: country,
    });
  });

  const [isModalHelpVisible, setIsModalHelpVisible] = useState(false);

  const showHelpModal = () => {
    setIsModalHelpVisible(true);
  };

  const handleHelpOk = () => {
    SubmitApplication();
    props.history.push("/ComplianceChecklist");
    setIsModalHelpVisible(false);
  };

  const handleHelpCancel = () => {
    setIsModalHelpVisible(false);
  };

  const [signatureResult, setSignatureResult] = useState("");
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const [overdraft, setOverdraft] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [visibleD, setVisibleD] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalTextProjectName, setModalTextProjectName] = React.useState("");
  const [modalTextYear, setModalTextYear] = React.useState("");
  const [modalTextDescription, setModalTextDescription] = React.useState("");
  const [modalTextValue, setModalTextValue] = React.useState(0);
  const [modalTextContactClient, setModalTextContactClient] =
    React.useState("");
  const [SMMERole, setSMMERole] = React.useState("");
  const [modalTextClient, setModalTextClient] = React.useState("");
  const [value, setValue] = useState(undefined);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const [DirectorKeyName, setDirectorKeyName] = React.useState("");
  const [DirectorKeySurname, setDirectorKeySurname] = React.useState("");
  const [DirectorKeyIDNumber, setDirectorKeyIDNumber] = React.useState("");
  const [DirectorKeyDesignation, setDirectorKeyDesignation] =
    React.useState("");

  const [Projects, SetProjects] = React.useState<any>([
    {
      ProjectStartDate: "",
      Year: "",
      Description: "",
      Value: 0,
      Client: "",
      SMMERole: "",
      ClientContact: "",
    },
  ]);
  const [Directors, SetDirectors] = React.useState<any>([{}]);
  const section = <span>Next section</span>;
  const submit = <span>Submit and proceed to compliance checklist.</span>;
  const back = <span>Previous section</span>;
  const create = <span>Fill in project history </span>;
  const buttonWidth = 70;

  useIonViewDidEnter(() => {
    console.log(user);
    if (user.UserType === "SUPERADMIN") {
    } else if (checkProjectEmpty(CurrentProject)) {
      message
        .loading("Error", 1)
        .then(() => message.error("You have not loaded a project", 5));
      props.history.push("/projects");
    }
    UIStore.update((s) => {
      s.CurrentProject.BusinessName = user.CompanyName;
    });

    axios
      .get("https://dev.northroom.co.za/api/spfund/projects/fundmanagerdash")
      .then(function (response) {
        console.log(response.data);
        const projectfromDB = response.data.filter(
          (object: { id: string }) => object.id === CurrentProject.id
        );
        UIStore.update((s) => {
          s.CurrentProject.FundingRequirement =
            projectfromDB.user.FundingRequirement;
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  });

  async function SubmitApplication() {
    if (CurrentProject.CountryOfIssue.length! > 3) {
      UIStore.update((s) => {
        s.CurrentProject.CountryOfIssue = "South Africa";
      });
    }
    console.log(CurrentProject);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate/Application",
          CurrentProject
        )
        .then((response) => {
          // message.success(response.data);
          console.log(response);
          props.history.push("/ComplienceChecklist");
        });
    } catch (error: any) {
      console.error(error);
    }
  }

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  function handleEnityChange(value: any) {
    //console.log(value)
    UIStore.update((s) => {
      s.CurrentProject.LegalEntity = value.toString();
    });
  }

  // const [modalText2, setModalText2] = React.useState('Some other input');

  const showModal = () => {
    setVisible(true);
  };

  const showModalD = () => {
    setVisibleD(true);
  };

  const CreateProject = async () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    if (modalTextYear.length === 0) {
      setModalTextYear(moment(Date.now()).toString());
    }

    console.log(SMMERole);
    SetProjects((oldArray: any) => [
      ...oldArray,
      {
        ProjectName: modalTextProjectName,
        ProjectStartDate: modalTextYear,
        Description: modalTextDescription,
        Value: modalTextValue,
        Client: modalTextClient,
        ClientContact: modalTextContactClient,
        SMMERole: SMMERole,
      },
    ]);
    setModalTextContactClient("");
    setSMMERole("");
    setModalTextDescription("");
    setModalTextValue(0);
    setModalTextYear("");
    setModalTextClient("");
    setVisible(false);

    UIStore.update((s) => {
      s.CurrentProject.BusinessProjects = JSON.stringify(Projects);
    });
  };

  const CreateDirector = async () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    if (modalTextYear.length === 0) {
      setModalTextYear(moment(Date.now()).toString());
    }

    console.log(SMMERole);
    SetDirectors((oldArray: any) => [
      ...oldArray,
      {
        DirectorKeyName: DirectorKeyName,
        DirectorKeySurname: DirectorKeySurname,
        DirectorKeyIDNumber: DirectorKeyIDNumber,
        DirectorKeyDesignation: DirectorKeyDesignation,
      },
    ]);
    setDirectorKeyName("");
    setDirectorKeySurname("");
    setDirectorKeyIDNumber("");
    setDirectorKeyDesignation("");
    setVisibleD(false);

    UIStore.update((s) => {
      s.CurrentProject.Directors = JSON.stringify(Directors);
    });
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const handleCancelD = () => {
    console.log("Clicked cancel button");
    setVisibleD(false);
  };

  function changeDate(date: any, dateString: any) {
    setModalTextYear(dateString);
  }

  const { Step } = Steps;

  const steps = [
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Business Name</strong>
              </p>
              <Input
                value={CurrentProject.BusinessName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessName = e.target.value;
                  });
                }}
                name="BusinessName"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Client Designation</strong>
              </p>
              <Select
                id="Client"
                placeholder="Please Select Client Designation"
                style={{ width: "100%" }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessOwners = e.toString();
                  });
                }}
              >
                <Option value="BusinessOwners">Main Contractor</Option>
                <Option value="BusinessOwners">Subcontractor</Option>
                <Option value="BusinessOwners">Project Owner</Option>
              </Select>
            </IonCol>

            <IonCol class="ion-padding">
              <p>
                <strong>CIPC Registration number</strong>
              </p>
              <Input
                value={CurrentProject.BusinessRegistration}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessRegistration = e.target.value;
                  });
                }}
                name="BusinessRegistration"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Business Registration Date</strong>
              </p>

              <DatePicker
                value={
                  CurrentProject.BusinessRegistrationDate.length === 0
                    ? moment(Date.now())
                    : moment(CurrentProject.BusinessRegistrationDate)
                }
                defaultValue={moment("2015/01/01", dateFormat)}
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessRegistrationDate = dateString;
                  });
                }}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Trading Start Date</strong>
              </p>

              <DatePicker
                value={
                  CurrentProject.TradingStartDate.length === 0
                    ? moment(Date.now())
                    : moment(CurrentProject.TradingStartDate)
                }
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.TradingStartDate = dateString;
                  });
                }}
              />
            </IonCol>
          </Row>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Legal Entity</strong>
              </p>
              <Select
                id="Legal"
                placeholder="Please Select Legal Entity "
                style={{ width: "100%" }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.LegalEntity = e.toString();
                  });
                }}
              >
                <Option value="Pty/Ltd">Private Company (Pty limited)</Option>
                <Option value="CC">Close corporation</Option>
                <Option value="Partnership">Partnership</Option>
                <Option value="Trust">Trust</Option>
                <Option value="Fund">Fund</Option>
                <Option value="Corporation">Corporation</Option>
              </Select>

              {/* <Input
              value={CurrentProject.LegalEntity}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.LegalEntity = e.target.value }) }}
              name="LegalEntity"
              placeholder=""
            /> */}
            </IonCol>
          </Row>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Tax Pin</strong>
              </p>
              <Input
                value={CurrentProject.TaxClearance}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.TaxClearance = e.target.value;
                  });
                }}
                name="TaxClearance"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Physical Address</strong>
              </p>
              <Input
                value={CurrentProject.PhysicalAddress}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.PhysicalAddress = e.target.value;
                  });
                }}
                name="PhysicalAddress"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Postal Address</strong>
              </p>
              <Input
                value={CurrentProject.PostalAddress}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.PostalAddress = e.target.value;
                  });
                }}
                name="PostalAddress"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>GPS Coordinates (where available)</strong>
              </p>
              <Input
                value={CurrentProject.GPSCoordinates}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.GPSCoordinates = e.target.value;
                  });
                }}
                name="GPSCoordinates"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row style={{ paddingLeft: "10px" }}>
            <label>
              <strong>Business Size</strong>
            </label>
            <br></br>
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              value={CurrentProject.BusinessSize}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Business Size"
              allowClear
              treeDefaultExpandAll
              onChange={(e: any) => {
                UIStore.update((s) => {
                  s.CurrentProject.BusinessSize = e;
                });
              }}
            >
              <TreeNode value="Micro" title="Micro">
                <TreeNode value="0-10 Employees" title="0-10 Employees" />
                <TreeNode value="parent 1-0" title="Small">
                  <TreeNode value="11-50 Employees" title="11-50 Employees" />
                </TreeNode>
                <TreeNode value="parent 1-1" title="Medium">
                  <TreeNode
                    value="51-250 Employees"
                    title={<b style={{ color: "#08c" }}>51-250 Employees</b>}
                  />
                </TreeNode>
              </TreeNode>
            </TreeSelect>
          </Row>
          <br></br>
          <IonCol class="ion-padding"></IonCol>
          <Row style={{ paddingLeft: "10px" }}>
            <IonCol class="ion-padding">
              <p>
                <strong>Number of permanent employees </strong>
              </p>
              <Input
                value={CurrentProject.CurrentPermtEmpl}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CurrentPermtEmpl = e.target.value;
                  });
                }}
                name="CurrentPermtEmpl"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Number of permanent temporary</strong>
              </p>
              <Input
                value={CurrentProject.CurrentTempEmpl}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CurrentTempEmpl = e.target.value;
                  });
                }}
                name="CurrentTempEmpl"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Black Ownership</strong>
              </p>

              <Input
                value={CurrentProject.BlackOwnership}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BlackOwnership = e.target.value;
                  });
                }}
                name="BlackOwnership"
                suffix="%"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong> Woman Ownership</strong>
              </p>
              <Input
                value={CurrentProject.WomanOwnership}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.WomanOwnership = e.target.value;
                  });
                }}
                name="WomanOwnership"
                suffix="%"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong> Youth Ownership</strong>
              </p>
              <Input
                value={CurrentProject.YouthOwnership}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.YouthOwnership = e.target.value;
                  });
                }}
                name="YouthOwnership"
                suffix="%"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Sector in which Entity Operates</strong>
              </p>
              <Input
                value={CurrentProject.BusinessSector}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessSector = e.target.value;
                  });
                }}
                name="BusinessSector"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Services</strong>
              </p>
              <Input
                value={CurrentProject.Services}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Services = e.target.value;
                  });
                }}
                name="Services"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>CIDB Grade</strong>
              </p>
              <Input
                value={CurrentProject.CIDBGrade}
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CIDBGrade = e.target.value;
                  });
                }}
                name="CIDBGrade"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Annual Revenue for the Most Recent Financial period
                </strong>
              </p>
              <Input
                value={CurrentProject.AnnualRevenue}
                prefix="R"
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.AnnualRevenue = e.target.value;
                  });
                }}
                name="AnnualRevenue"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Method of Funding the Business</strong>
              </p>
              {/* <Input
              value={CurrentProject.FundingMethod1}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.FundingMethod1 = e.target.value }) }}
              name="FundingMethod1"
              placeholder=""
            /> */}
              <Select
                id="Funding"
                placeholder="Please select Method of Funding "
                style={{ width: "100%" }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.FundingMethod1 = e.toString();
                  });
                }}
              >
                <Option value="Personal Savings">Personal Savings</Option>
                <Option value="Friends">Friends</Option>
                <Option value="Family">Family</Option>
                <Option value="Commercial">Commercial</Option>
              </Select>
            </IonCol>
          </Row>
        </p>
      ),
    },
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <h4>SECTION 2 Entity Representative </h4>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Title</strong>
              </p>
              {/* <Input
              value={CurrentProject.Title}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Title = e.target.value }) }}
              name="Title"
              placeholder=""
            /> */}
              <Select
                id="Title"
                placeholder="Please Select Representative Title"
                style={{ width: "100%" }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Title = e.toString();
                  });
                }}
              >
                <Option value="Title">Mr</Option>
                <Option value="Title">Mrs</Option>
                <Option value="Title">Ms</Option>
              </Select>
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Initials</strong>
              </p>
              <Input
                value={CurrentProject.Initials}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Initials = e.target.value;
                  });
                }}
                name="Initials"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Surname</strong>
              </p>
              <Input
                value={CurrentProject.BusinessOwnerSurname}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessOwnerSurname = e.target.value;
                  });
                }}
                name="BusinessOwnerSurname"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>ID or Passport number</strong>
              </p>
              <Input
                value={CurrentProject.IDPassport}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.IDPassport = e.target.value;
                  });
                }}
                name="IDPassport"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Country of issue</strong>
              </p>
              <Select
                id="Country"
                defaultValue={["South Africa"]}
                style={{ width: "100%" }}
                placeholder="South Africa"
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CountryOfIssue = e.toString();
                  });
                }}
                options={options}
              />
              {/* <Input
              value={CurrentProject.CountryOfIssue}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.CountryOfIssue = e.target.value }) }}
              name="CountryOfIssue"
              placeholder=""
            /> */}
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Race</strong>
              </p>
              <Select
                id="Race"
                defaultValue={"Please select your race"}
                placeholder="Please select your race"
                style={{ width: "100%" }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Race = e.toString();
                  });
                }}
              >
                <Option value="Caucasian">Caucasian</Option>
                <Option value="African">African</Option>
                <Option value="Asian">Asian</Option>
                <Option value="Indian">Indian</Option>
                <Option value="Hispanic">Hispanic</Option>
                <Option value="Latino">Latino</Option>
                <Option value="Other">Other</Option>
              </Select>

              {/* <Input
              value={CurrentProject.Race}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Race = e.target.value }) }}
              name="Race"
              placeholder=""
            /> */}
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Designation</strong>
              </p>
              <Input
                value={CurrentProject.Designation}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Designation = e.target.value;
                  });
                }}
                name="Designation"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Telephone</strong>
              </p>
              <Input
                value={CurrentProject.Telephone}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Telephone = e.target.value;
                  });
                }}
                name="Telephone"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Cellphone</strong>
              </p>
              <Input
                value={CurrentProject.Cellphone}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Cellphone = e.target.value;
                  });
                }}
                name="Cellphone"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Email address</strong>
              </p>
              <Input
                value={CurrentProject.Email}
                type="email"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Email = e.target.value;
                  });
                }}
                name="Email"
                placeholder=""
              />
            </IonCol>
          </Row>

          <IonCol>
            <h6>
              <strong>Directors and other Key Management Personnel</strong>
            </h6>
          </IonCol>

          <IonRow>
            <IonCol>
              <Card>
                <Tooltip placement="top" title={create}>
                  <Button
                    type="primary"
                    onClick={showModalD}
                    icon={<PlusOutlined />}
                  >
                    Add Director
                  </Button>
                </Tooltip>
                <Modal
                  title="New Director"
                  visible={visibleD}
                  onOk={CreateDirector}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancelD}
                >
                  <p></p>
                  <p>Name</p>
                  <Input
                    style={{ padding: "10px" }}
                    value={DirectorKeyName}
                    size="large"
                    placeholder=""
                    onChange={(e) => {
                      setDirectorKeyName(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                  <br></br>
                  <p>Surname</p>
                  <Input
                    style={{ padding: "10px" }}
                    value={DirectorKeySurname}
                    size="large"
                    placeholder=""
                    onChange={(e) => {
                      setDirectorKeySurname(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                  <br></br>
                  <p>ID Number</p>
                  <Input
                    style={{ padding: "10px" }}
                    value={DirectorKeyIDNumber}
                    size="large"
                    placeholder=""
                    onChange={(e) => {
                      setDirectorKeyIDNumber(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                  <br></br>
                  <p>Designation</p>
                  <Input
                    style={{ padding: "10px" }}
                    value={DirectorKeyDesignation}
                    size="large"
                    placeholder=""
                    onChange={(e) => {
                      setDirectorKeyDesignation(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                  <br></br>

                  <br></br>
                  <br></br>
                  <br></br>
                  {/* <p>{modalText2}</p>
                <Input 
                size="large" 
                placeholder="" 
                prefix={<UserOutlined />} /> */}
                </Modal>
              </Card>
            </IonCol>
          </IonRow>

          <div style={{ paddingLeft: "20px" }}>
            <Table columns={columns2} dataSource={Directors} size="middle" />
          </div>
          {/* <IonRow>
            <IonCol class="ion-padding">
            <p><strong>Name</strong></p>
            <Input
              value={CurrentProject.DirectorKeyName}
              type="email"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.DirectorKeyName = e.target.value }) }}
              name="Email"
              placeholder=""
            />
            </IonCol>

            <IonCol class="ion-padding">
            <p><strong>Surname</strong></p>
            <Input
              value={CurrentProject.DirectorKeySurname}
              type="email"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.DirectorKeySurname = e.target.value }) }}
              name="Email"
              placeholder=""
            />
            </IonCol>
          </IonRow> */}

          {/* <IonRow>
            <IonCol class="ion-padding">
            <p><strong>ID Number</strong></p>
            <Input
              value={CurrentProject.DirectorKeyIDNumber}
              type="email"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.DirectorKeyIDNumber = e.target.value }) }}
              name="Email"
              placeholder=""
            />
            </IonCol>

            <IonCol class="ion-padding">
            <p><strong>Designation</strong></p>
            <Input
              value={CurrentProject.DirectorKeyDesignation}
              type="email"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.DirectorKeyDesignation = e.target.value }) }}
              name="Email"
              placeholder=""
            />
            </IonCol>
          </IonRow> */}

          <IonCol>
            <h6>
              <strong>Previous Funding Information</strong>
            </h6>
          </IonCol>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Have you been previously funded?</strong>
              </p>
              <Select
                defaultValue={"previously funded"}
                placeholder="Have you been previously funded?"
                style={{ width: 120 }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.PreviousFunding = e.toString();
                  });
                }}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
              {/* <Input
              value={CurrentProject.PreviousFunding}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.PreviousFunding = e.target.value }) }}
              name="previouslyFunded"
              placeholder=""
            /> */}
            </IonCol>

            <IonCol class="ion-padding">
              <p>
                <strong>If Yes, what is the funder’s name </strong>
              </p>
              <Input
                value={CurrentProject.FunderName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.FunderName = e.target.value;
                  });
                }}
                name="FunderName"
                placeholder=""
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Contact details of the funder</strong>
              </p>
              <Input
                value={CurrentProject.FunderContact}
                type="email"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.FunderContact = e.target.value;
                  });
                }}
                name="Email"
                placeholder=""
              />
            </IonCol>

            <IonCol class="ion-padding">
              <p>
                <strong>Type of Funding</strong>
              </p>
              <Input
                value={CurrentProject.FundingTypePrevious}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.FundingTypePrevious = e.target.value;
                  });
                }}
                name="FundingTypePrevious"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Was it fully repaid? </strong>
              </p>
              <Select
                labelInValue
                placeholder="Was it fully repaid?"
                style={{ width: 120 }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Repaid = e.toString();
                  });
                }}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
              {/* <Input
              value={CurrentProject.Repaid}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Repaid = e.target.value }) }}
              name="Repaid"
              placeholder=""
            /> */}
            </IonCol>

            <IonCol class="ion-padding">
              <p>
                <strong>
                  Do you have a bank overdraft facility/ Business Loan?
                </strong>
              </p>
              <Select
                labelInValue
                placeholder="Do you have a bank overdraft facility/ Business Loan "
                style={{ width: 120 }}
                onChange={(e) => {
                  setOverdraft(true);
                  UIStore.update((s) => {
                    s.CurrentProject.OverdraftFacility = e.toString();
                  });
                }}
              >
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
              {/* <Input
              value={CurrentProject.OverdraftFacility}
              type="text"
              onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.OverdraftFacility = e.target.value }) }}
              name="OverdraftFacility"
              placeholder=""
            /> */}
            </IonCol>
          </IonRow>

          {overdraft ? <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Amount of overdraft/Business Loan</strong>
              </p>
              <Input
                value={CurrentProject.OverdraftAmount}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.OverdraftAmount = e.target.value;
                  });
                }}
                name="OverdraftAmount"
                placeholder=""
                prefix="R"
                style={{ width: "31.5vw" }}
              />
            </IonCol>
          </IonRow> : ""}

          <div style={{ paddingLeft: "20px" }}></div>
        </p>
      ),
    },
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <h4 style={{ paddingLeft: "20px" }}>
            SECTION 3 Previous Projects Completed
          </h4>

          <IonRow>
            <IonCol>
              <Card>
                <Tooltip placement="top" title={create}>
                  <Button
                    type="primary"
                    onClick={showModal}
                    icon={<PlusOutlined />}
                  >
                    Add project
                  </Button>
                </Tooltip>
                <Modal
                  title="New Project"
                  visible={visible}
                  onOk={CreateProject}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancel}
                >
                  <p>Project start Date</p>
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={changeDate}
                    defaultValue={moment("2015/01/01", dateFormat)}
                    size="large"
                    value={
                      modalTextYear.length === 0
                        ? moment(Date.now())
                        : moment(modalTextYear)
                    }
                  />
                  {/* <Input
                  style={{ padding: "10px" }}
                  value={modalTextYear}
                  size="large"
                  placeholder=""
                  onChange={(e) => { setModalTextYear(e.target.value) }}
                  prefix={<ProjectOutlined />} /> */}
                  <p>Project Description</p>
                  <Input
                    style={{ padding: "10px" }}
                    value={modalTextDescription}
                    size="large"
                    onChange={(e) => {
                      setModalTextDescription(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                  <br></br>
                  <p></p>
                  <p>Project Value</p>
                  <InputNumber
                    //defaultValue={1000}
                    formatter={(value) =>
                      `R ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    size="large"
                    style={{ width: "100%" }}
                    value={modalTextValue}
                    //@ts-ignore
                    parser={(value) => value.replace(/\R\s?|(,*)/g, "")}
                    onChange={(e) => {
                      setModalTextValue(e);
                    }}
                  />
                  {/* <Input
                  style={{ padding: "10px" }}
                  value={modalTextValue}
                  size="large"
                  placeholder=""
                  onChange={(e) => { setModalTextValue(e.target.value) }}
                  prefix={<ProjectOutlined />} /> */}
                  <br></br>
                  <p></p>
                  <p>Role of SMME in project </p>
                  <Select
                    labelInValue
                    value={{ value: SMMERole, label: SMMERole }}
                    placeholder="Role of SMME in project"
                    style={{ width: "100%" }}
                    //@ts-ignore
                    onChange={(e) => {
                      setSMMERole(e.label);
                    }}
                  >
                    <Option value="MainContractor">Main Contractor</Option>
                    <Option value="SubContractor">Sub Contractor</Option>
                    <Option value="ProjectOwner">Project Owner</Option>
                  </Select>
                  {/* <Input
                  style={{ padding: "10px" }}
                  value={modalTextYear}
                  size="large"
                  placeholder=""
                  onChange={(e) => { setModalTextYear(e.target.value) }}
                  prefix={<ProjectOutlined />} />
                <br></br> */}

                  <p></p>
                  <p>Client</p>
                  <Input
                    style={{ padding: "10px" }}
                    value={modalTextClient}
                    size="large"
                    placeholder=""
                    onChange={(e) => {
                      setModalTextClient(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                  <br></br>
                  <p></p>

                  <p>Contact Details of Client</p>
                  <Input
                    style={{ padding: "10px" }}
                    value={modalTextContactClient}
                    size="large"
                    placeholder=""
                    onChange={(e) => {
                      setModalTextContactClient(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* <p>{modalText2}</p>
                <Input 
                size="large" 
                placeholder="" 
                prefix={<UserOutlined />} /> */}
                </Modal>
              </Card>
            </IonCol>
          </IonRow>

          <div style={{ paddingLeft: "20px" }}>
            <Table columns={columns} dataSource={Projects} size="middle" />
          </div>

          <h4 style={{ paddingLeft: "20px" }}>SECTION 4 PROJECT INFORMATION</h4>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Description as per Appointment Letter</strong>
              </p>
              <Input
                value={CurrentProject.Projection}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Projection = e.target.value;
                  });
                }}
                name="Projection"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Value of Project as per Appointment Letter</strong>
              </p>
              <Input
                value={CurrentProject.ValueOfProject}
                type="number"
                prefix="R"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ValueOfProject = e.target.value;
                  });
                }}
                name="ValueOfProject"
                placeholder=""
              />
            </IonCol>
          </Row>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Funding requirement (amount of funding the entity is applying
                  for)
                </strong>
              </p>
              <Input
                value={CurrentProject.FundingRequirement}
                type="number"
                prefix="R"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.FundingRequirement = e.target.value;
                  });
                }}
                name="ValueOfProject"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Client</strong>
              </p>
              <Input
                value={CurrentProject.Client}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Client = e.target.value;
                  });
                }}
                name="Client"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Clients Contact Number</strong>
              </p>
              <Input
                value={CurrentProject.ClientContact}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ClientContact = e.target.value;
                  });
                }}
                name="ClientContact"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Duration of Project</strong>
              </p>
              <Input
                value={CurrentProject.ProjectDuration}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectDuration = e.target.value;
                  });
                }}
                name="ProjectDuration"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Start Date of the Project</strong>
              </p>

              <DatePicker
                value={
                  CurrentProject.ProjectStart.length === 0
                    ? moment(Date.now())
                    : moment(CurrentProject.ProjectStart)
                }
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectStart = dateString;
                  });
                }}
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Completion Date</strong>
              </p>

              <DatePicker
                value={
                  CurrentProject.ProjectEnd.length === 0
                    ? moment(Date.now())
                    : moment(CurrentProject.ProjectEnd)
                }
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectEnd = dateString;
                  });
                }}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Location of Project</strong>
              </p>
              <Select
                id="Funding"
                placeholder="Please select the location of the project"
                style={{ width: "100%" }}
                onChange={(e) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Location = e.toString();
                  });
                }}
              >
                <Option value="Eastern Cape">Eastern Cape</Option>
                <Option value="Free State">Free State</Option>
                <Option value="Gauteng">Gauteng</Option>
                <Option value="KwaZulu-Natal">KwaZulu-Natal</Option>
                <Option value="Limpopo">Limpopo</Option>
                <Option value="Mpumalanga">Mpumalanga</Option>
                <Option value="Northern Cape">Northern Cape</Option>
                <Option value="North West">North West</Option>
                <Option value="Western Cape">Western Cape</Option>
              </Select>
            </IonCol>
          </Row>

          <Title
            style={{ marginTop: "50px" }}
            className="ion-text-center"
            level={5}
          >
            Number of jobs to be created from project
          </Title>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Permanent</strong>
              </p>
              <Input
                value={CurrentProject.PermanentJobs}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.PermanentJobs = e.target.value;
                  });
                }}
                name="PermanentJobs"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Temporary</strong>
              </p>
              <Input
                value={CurrentProject.TemporaryJobs}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.TemporaryJobs = e.target.value;
                  });
                }}
                name="TemporaryJobs"
                placeholder=""
              />
            </IonCol>
          </Row>
        </p>
      ),
    },
  ];

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="Application Form"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCol class="ion-padding">
          <hr></hr>
          <IonRow>
            <IonCol class="ion-padding">
              <Steps style={{ padding: "20px" }} current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </IonCol>
          </IonRow>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current > 0 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={back}>
                  <Button
                    size={"large"}
                    style={{ margin: "0 8px" }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                </Tooltip>
              </IonCol>
            )}
            {current < steps.length - 1 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={section}>
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={(CheckObject) => next()}
                  >
                    Next
                  </Button>
                </Tooltip>
              </IonCol>
            )}
            {current === steps.length - 1 && (
              <IonCol class="ion-padding">
                {/* <Link to="/ComplienceChecklist"> */}
                <Tooltip placement="top" title={submit}>
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={() => {
                      showHelpModal();
                    }}
                  >
                    Submit Form
                  </Button>
                  <Modal
                    title=""
                    visible={isModalHelpVisible}
                    onOk={handleHelpOk}
                    onCancel={handleHelpCancel}
                  >
                    <p>
                      <strong>Proceeding to service level agreement</strong>
                    </p>
                    <p>
                      <strong>
                        You will be required to upload the following documents:
                      </strong>
                    </p>
                    <p>Company profile</p>
                    <p>Company registration documents</p>
                    <p>B-BBEE certificate</p>
                    <p>Marital status and contract</p>
                    <p>ID of all owners or directors at group level</p>
                    <p>Proof of address</p>
                    <p>Company and project responsible personnel</p>
                    <p>Department of labour</p>
                    <p>VAT certificate</p>
                    <p>NHBRC certificate</p>
                    <p>CSD registration</p>
                    <p>Proof of insurance including liability cover</p>
                    <p>Financials/Management accounts</p>
                    <p>Banking statements (not older than 6 months)</p>
                    <p>Letter of appointment</p>
                    <p>Implementation plan</p>
                    <p>Priced bills of quantities</p>
                  </Modal>
                </Tooltip>
                {/* </Link> */}
              </IonCol>
            )}
          </div>
        </IonCol>
      </IonContent>
    </IonPage>
  );
};

export default ApplicationForm;
