import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Modal,
  Empty,
  Select,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { getDocsArray } from "../store/actions";
const dateFormat = "YYYY/MM/DD";

const ComplianceView: React.FC = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comment, setComment] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (object: any) => {
    object.CompOfficerExceptioncomment = comment;
    ExceptionReport(object);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { Step } = Steps;

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedcompofficer",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/appofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }

  }
  async function ExceptionReport(object: any) {
    console.log(object)
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedcompofficerexception",
          object
        )
        .then((response) => {
          console.log(response.data)
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/appofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
      console.log(JSON.stringify(error))
    }
  }
  /*
  axios.get('https://dev.northroom.co.za/api/spfund/users/projectlistall/appofficer')
  .then(function (response) {
    console.log(response.data);
    setdata(response.data);

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });
  */
  useIonViewWillEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/users/projectlistall/compofficer"
      )
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });
  const handleComment = (e: any) => {
    setComment(e.target.value);
  };

  async function DownloadPack(object: any) {
    //turn docs file links into an array

    let linkarr = [];

    linkarr.push(object.Spif);
    linkarr.push(object.BOQ);
    linkarr.push(object.ImplementationPlan);
    linkarr.push(object.Letterofappointment);
    linkarr.push(object.BankingStatements);
    linkarr.push(object.ManagementAccounts);
    linkarr.push(object.Insurance);
    linkarr.push(object.CIDBDoc);
    linkarr.push(object.NHBRC);
    linkarr.push(object.TaxClearanceDoc);
    linkarr.push(object.Vat);
    linkarr.push(object.DepartLabor);
    linkarr.push(object.personnel);
    linkarr.push(object.AllDirectors);
    linkarr.push(object.MartialSTatus);
    linkarr.push(object.BBEECert);
    linkarr.push(object.Companyregdocuments);
    linkarr.push(object.ApplicationForm);
    linkarr.push(object.BaselineForm);
    linkarr.push(object.CIPCForm);
    linkarr.push(object.CreditagreementForm);
    linkarr.push(object.companyprofile);
    linkarr.push(object.ProofAddress);
    linkarr.push(object.DeclarationForm);
    linkarr.push(object.JVForm);
    linkarr.push(object.EligibilityCriteriaForm);
    linkarr.push(object.Resolution);
    linkarr.push(object.ShareholdingConfirmation);
    linkarr.push(object.TaxPin);
    linkarr.push(object.BOQ2);
    linkarr.push(object.CompletionCertificate);
    linkarr.push(object.CPO);
    linkarr.push(object.RFQ);
    linkarr.push(object.SuppliersQ);
    linkarr.push(object.Cinvoice);
    linkarr.push(object.DebtAge);
    linkarr.push(object.EditorsAge);
    linkarr.push(object.PoCl);
    linkarr.push(object.MonthBankState);
    linkarr.push(object.BillofQuanties);
    linkarr.push(object.SLA);
    linkarr.push(object.CletterofAppointment);
    linkarr.push(object.ProjectImplentaionPlan);
    linkarr.push(object.CinvoicePaidbyPayMaster);
    linkarr.push(object.PaymentCert);
    linkarr.push(object.DebtandCred);

    linkarr.map((mainurl) => {
      if (mainurl.length !== 0) {
        axios({
          url: mainurl,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            mainurl.replace(
              " https://dev.northroom.co.za/api/spfund/static/",
              ""
            )
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    });
  }

  //select
  const { Option } = Select;

  const onDownloadChange = (val: any) => {
    console.log("Link!")
    window.open(val, "_blank");
  };

  function getSelect(object: any) {

    let content =
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select file to download"
        optionFilterProp="children"
        onChange={onDownloadChange}
        filterOption={(input, option) =>
          //@ts-ignore
          option.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {getDocsArray(object).map((item) => {

          return (
            <Option value={item.uri} key={item.name + object.id}>{item.name}</Option>
          );
        })}
      </Select>

    return content
  }
  //end select

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Compliance Verification"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "7%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={1} status="error">
              <Step
                title="Application Pack Verification"
                description="Complete"
              />
              <Step
                title="Application Pack Verification"
                description="In Progress"
              />
              <Step
                title="Vetting"
                description="Awaiting client/site/supplier vetting"
              />
              <Step
                title="Verified Pack Submission"
                description="Verified Documents submitted to risk officer"
              />
            </Steps>
          </IonCol>
        </IonRow>

        {data.length === 0 ?
          <Empty imageStyle={{ alignSelf: "center" }} />
          : <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={<p>Project Application ID:<Tag color="blue">{dataobject.LeadName}</Tag></p>}
                      description={"Project Status: " + dataobject.CurrentStatus}
                    />
                  </List.Item>

                  <List.Item>
                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        {/* <Button
                          onClick={() => { ExceptionReport(dataobject); }}
                          type="primary">
                          Create Exception Report
                       </Button> */}

                        <Button type="primary" onClick={() => { showModal(); }}>
                          Create Exception Report
                        </Button>
                        <Modal
                          title="Exception Report Details"
                          visible={isModalVisible}
                          onOk={() => { handleOk(dataobject) }}
                          onCancel={handleCancel}
                        >
                          <TextArea
                            value={comment}
                            placeholder=""
                            rows={4}
                            onChange={(e) => {
                              handleComment(e);
                            }}
                          />
                        </Modal>
                      </IonCol>

                      <IonCol></IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol>
                        {getSelect(dataobject)}
                      </IonCol>
                    </IonRow>
                  </List.Item>
                </>
              ))
            }
          />
        }
      </IonContent>
    </IonPage>
  );
};

export default ComplianceView;
