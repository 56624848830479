import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';

import { Descriptions, Row, Col } from 'antd';
import { Card } from 'antd';
import { Switch } from 'antd';

import { DatePicker, Space ,Button} from 'antd';
import './Page2.css';

const BeneficiaryContact: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Application Forms</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
        {/* <Card title="Application Form" extra={<a href="#">More</a>} > */}
     <Row>
        <Card style={{ width: 300 }}>
    <p>Registration with CIBD</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
  
  <Card style={{ width: 300 }}>
    <p>Duly incorporated company with 51% black ownership</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
  </Row>
  <Row>
  <p>Potential to grow scale of work in terms of:</p>
  </Row>
  <Row>
        <Card style={{ width: 300 }}>
    <p>Willingness  to submit an application to particiapte in the program</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
        <Card style={{ width: 300 }}>
    <p>Willingness to employ people and pay the minimun industry wage</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
        <Card style={{ width: 300 }}>
    <p>Willingness to open a ring-fenced-account</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
  </Row>
       <Row>
        <Card style={{ width: 300 }}>
    <p>Willingness to participate in preparing of a development plan for the business</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
        <Card style={{ width: 300 }}>
    <p>Willingness to attend training when necessary</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
        <Card style={{ width: 300 }}>
    <p>Atleast 1 year experience as a business or individual</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
  </Row>
  <Row>
        <Card style={{ width: 300 }}>
    <p>Willingness to sign a development agreement for thier business</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
        <Card style={{ width: 300 }}>
    <p>Willingness to enter into materials supply aggrement where necessary including ceding income to the value of the funding</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
        <Card style={{ width: 300 }}>
    <p>Willingness to go through assesment in order to particiapte in the program</p>
    <Switch defaultChecked onChange={onChange} />
  </Card>
  </Row>
  
  
 
      
    
   
    {/* </Card> */}


    <Descriptions title="User Info"></Descriptions>
  </Space>
      </IonContent>
    </IonPage>

    
  );
};

export default BeneficiaryContact;