import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  useIonViewDidEnter,
} from "@ionic/react";

import { message, Modal, Typography } from "antd";
import { Row } from "antd";
import { Input } from "antd";
import { DatePicker, Button, Tooltip } from "antd";
import "./Page2.css";
import moment from "moment";
import { Steps } from "antd";
import React, { useState } from "react";
import { PageHeader } from "antd";
import SignatureCanvas from "react-signature-canvas";
import { UIStore } from "../store/UIStore";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { ProjectStatus } from "../store/UIStore";
import { clone } from "@babel/types";
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const ProposalForm: React.FC = (props: any) => {
  const { Title } = Typography;
  const [signatureResult, setSignatureResult] = useState("");
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [userDB, setUserDB] = useState({});
  const { TextArea } = Input;
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const [Projects, SetProjects] = useState<any>([
    { ProjectName: "", Year: "", Description: "", Value: "", Client: "" },
  ]);
  const section = <span>Next section</span>;
  const submit = <span>Submit and save the proposal.</span>;
  const decline = (
    <span>Decline the proposal and await response from SP Impact Fund .</span>
  );
  const back = <span>Previous section</span>;
  const create = <span>Fill in project history </span>;

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleDocCancel = () => {
    setisDocModalVisible(false);
  };

  const showDeclineModal = () => {
    setisDeclineModalVisible(true);
  };

  const handleDeclineOk = (object: any) => {
    DeclineProposal(object);
    setisDeclineModalVisible(false);
  };

  const handleDeclineCancel = () => {
    setisDeclineModalVisible(false);
  };

  // View Purchase Order
  const [isDocModalVisible, setisDocModalVisible] = useState(false);
  const [isDeclineModalVisible, setisDeclineModalVisible] = useState(false);
  const docs: any = [{ uri: CurrentProject.CPO }];

  var sigCanvas: any = {};

  useIonViewDidEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/projects/fundmanagerdash")
      .then(function (response) {
        const projectfromDB = response.data.filter(
          (object: { id: string }) => object.id === CurrentProject.id
        );
        setUserDB(projectfromDB.user);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    console.log();
    UIStore.update((s) => {
      //@ts-ignore
      s.CurrentProject.Client = userDB.CompanyName;
    });

    SetProjects(CurrentProject);
    if (CurrentProject.Proposal != null) {
      setPropposalFomData(CurrentProject.Proposal);
    }
    const { TextArea } = Input;

    if (user.UserType === "APPLICANT") {
      setDisabled(true);
    }
  });
  const [PropposalFomData, setPropposalFomData] = useState({
    ProposalDate: "",
    ProposalCreatedBy: "",
    OrderValueIncl: "",
    PrimeInterestRate: "",
    AdministrationFeeIncl: "",
    LegalFeeIncl: "",
    InitiationFeeIncl: "",
    GrossProfit: "",
    FundingCost: "",
    FundingRepayment: "",
    ProposalSignedLocation: "",
    ProposalSignatureDeclaration: "",
  });

  const todayDate = new Date();
  const month = todayDate.toLocaleString("default", { month: "long" });
  const [ReasonForDecline, setReasonForDecline] = useState("");
  const { Step } = Steps;

  async function SubmitProposal() {
    console.log(PropposalFomData);

    // let temp = {
    //   Client: CurrentProject.Client,
    //   ProjectDescription:CurrentProject.ProjectDescription
    // };
    if (user.UserType === "APPLICANT") {
      console.log("Applicant !");
      message
        .loading("Action in progress..", 5)
        .then(() =>
          message.success(
            "SP Fund has received your proposal and will be in touch shortly.",
            5
          )
        );
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/project/updateproposal/" +
            CurrentProject.id,
            {
              ProposalBody: PropposalFomData,
              ProjectBody: CurrentProject,
            }
          )
          .then((response) => {
            UIStore.update((s) => {
              s.CurrentProject = response.data;
            });
            console.log(response.data);
          })
          .finally(() => {
            props.history.push("/BankingDocs");
          });
      } catch (error: any) {
        console.error(error);
      }
    } else {
      message
        .loading("Action in progress..", 5)
        .then(() => message.success("Applicant has received the proposal.", 5));
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/project/createproposal/" +
            CurrentProject.id,
            {
              ProposalBody: PropposalFomData,
              ProjectBody: CurrentProject,
            }
          )
          .then((response) => {
            UIStore.update((s) => {
              s.CurrentProject = response.data;
            });
            console.log(response);
            props.history.push("/Proposal");
          });
      } catch (error: any) {
        console.error(error);
      }
    }
  }

  async function DeclineProposal(object: any) {
    let temp = { ...object };
    temp.CurrentStatus = ProjectStatus.AWAITINGPROPOSAL;
    temp.ReasonForDecline = ReasonForDecline;

    console.log(temp)
    message
      .loading("Action in progress..", 5)
      .then(() =>
        message.success(
          "SP Fund has received your response and will be in touch shortly.",
          5
        )
      );
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
          temp
        )
        .then((response) => {
          props.history.push("/Projects");
        });
    } catch (error: any) {
      console.log(error);
      message.error("An error has occurred");
    }
  }

  const handlePropposalFomDataChange = (e: {
    target: { name: any; value: any };
  }) => {
    const { name, value } = e.target;
    setPropposalFomData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSigntureChagne = (sig: any) => {
    setPropposalFomData((prevState) => ({
      ...prevState,
      ProposalSignatureDeclaration: sig,
      id: CurrentProject.id,
    }));
  };

  let content = <></>;

  if (user.UserType === "APPLICANT") {
    content = (
      <SignatureCanvas
        ref={(ref) => {
          sigCanvas = ref;
        }}
        backgroundColor="#a9a9a9"
        penColor="black"
        canvasProps={{
          width: 1300,
          height: 200,
          className: "sigCanvas",
        }}
        onEnd={() => {
          let signature = sigCanvas.toDataURL();
          handleSigntureChagne(signature);
        }}
      />
    );
  }
  let contentB = <></>;

  if (user.UserType === "APPLICANT") {
    contentB = (
      <Button
        size={"large"}
        style={{ textAlign: "center" }}
        type="primary"
        onClick={showDeclineModal}
      >
        Decline Proposal
      </Button>
    );
  }

  let contentF = <></>;

  if (user.UserType === "FUNDMANAGER") {
    contentF = (
      <IonCol class="ion-padding">
        <Button
          size={"large"}
          style={{ textAlign: "center" }}
          type="primary"
          disabled
          onClick={showDeclineModal}
        >
          Decline Proposal
        </Button>
      </IonCol>
    );
  }

  const steps = [
    {
      title: "Funding Proposal",
      content: (
        <p style={{ padding: "20px" }}>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Client:</strong>
              </p>
              <Input
                value={CurrentProject.Client}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Client = e.target.value;
                  });
                }}
                name="Client"
                placeholder=""
                disabled={disabled}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Date:</strong>
              </p>

              <DatePicker
                value={moment(
                  PropposalFomData.ProposalDate
                    ? PropposalFomData.ProposalDate
                    : Date.now()
                )}
                // onChange={(e: any) => {
                //   handlePropposalFomDataChange(e);
                // }}
                disabled={disabled}
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Description:</strong>
              </p>
              <Input
                value={CurrentProject.LeadDescription}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.LeadDescription = e.target.value;
                  });
                }}
                name="ProjectDescription"
                placeholder=""
                disabled={disabled}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Created by:</strong>
              </p>
              <Input
                value={user.FullName}
                type="text"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="ProposalCreatedBy"
                placeholder=""
                disabled={disabled}
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Financial Requirements</strong>
              </p>
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Contract/Invoice/Purchase Order Value (Incl. Vat)
                </strong>
              </p>
              <Input
                value={PropposalFomData.OrderValueIncl}
                type="text"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="OrderValueIncl"
                placeholder=""
                prefix="R"
                disabled={disabled}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Prime Interest rate</strong>
              </p>
              <Input
                value={PropposalFomData.PrimeInterestRate}
                type="number"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="PrimeInterestRate"
                placeholder=""
                disabled={disabled}
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Administration Fee (Incl. Vat)</strong>
              </p>

              <Input
                value={PropposalFomData.AdministrationFeeIncl}
                type="number"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="AdministrationFeeIncl"
                prefix="R"
                disabled={disabled}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong> Legal Fee (Incl. Vat)</strong>
              </p>
              <Input
                value={PropposalFomData.LegalFeeIncl}
                type="number"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="LegalFeeIncl"
                prefix="R"
                disabled={disabled}
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong> Initiation Fee (Incl. Vat)</strong>
              </p>
              <Input
                value={PropposalFomData.InitiationFeeIncl}
                type="number"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="InitiationFeeIncl"
                prefix="R"
                disabled={disabled}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Gross Profit</strong>
              </p>
              <Input
                value={PropposalFomData.GrossProfit}
                type="text"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="GrossProfit"
                prefix="R"
                placeholder=""
                disabled={disabled}
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Funding Cost </strong>
              </p>
              <Input
                value={PropposalFomData.FundingCost}
                type="text"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="FundingCost"
                placeholder=""
                prefix="R"
                disabled={disabled}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Funding Repayment (30, 60, 90 days)</strong>
              </p>
              <Input
                value={PropposalFomData.FundingRepayment}
                type="number"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="FundingRepayment"
                placeholder=""
                disabled={disabled}
              />
            </IonCol>
          </Row>
          <h4>This Funding Proposal is subject to the following conditions:</h4>
          <Row>
            <p>
              -SP Impact Fund is to conduct Contract/Invoice/Purchase Order
              vetting with the Paymaster to validate the authenticity of the
              Contract/Invoice/Purchase Order as well as confirm the payment
              terms.
            </p>
          </Row>
          <Row>
            <p>
              -(Client’s entity name) agrees and authorizes SP Impact Fund to
              open an FNB regulated account in its name as provided in the
              Company and Intellectual Property Commission (CIPC) registration
              certificate.
            </p>
          </Row>
          <Row>
            <p>
              -The regulated bank account will solely be administered by SP
              Impact Fund.
            </p>
          </Row>
          <Row>
            <p>
              -This regulated bank account will be changed on the Paymaster’s
              vendor portal and CSD for the receipt of funds related to this
              proposal.
            </p>
          </Row>
          <Row>
            <p>
              -Upon signing of this proposal, the signatory authorizes SP Impact
              Fund to conduct credit authentications which may be required to
              ascertain the credit worthiness of the said client and/or
              directors.
            </p>
          </Row>
          <Row>
            <p>
              -Funding cost to be calculated on the daily basis on the overdue
              outstanding amount after the repayment term is reached, calculated
              at 2% per month (pm).
            </p>
          </Row>
          <Row>
            <p>-All amounts exclude VAT unless otherwise stated.</p>
          </Row>
        </p>
      ),
    },
    {
      title: "DEVELOPMENT REQUIREMENTS",
      content: (
        <p style={{ padding: "20px" }}>
          <h4>
            Further due diligence will be conducted to ascertain
            contract/invoice/purchase order viability. The outcome of this
            process will guide SP Impact Fund’s official decision, once
            approved, the following conditions will apply:
          </h4>
          <Row>
            <p>
              -Client will sign a Funding and Onboarding Pack – which will
              detail the development aspect and expected deliverables of the
              agreement.
            </p>
          </Row>
          <Row>
            <p>
              -Client will sign a Credit Agreement – which will detail the
              disbursement, repayment of capital and interest, and distribution
              of profits between SP Impact Fund and the Client.
            </p>
          </Row>

          <div style={{ paddingLeft: "20px" }}></div>
        </p>
      ),
    },
    {
      title: "POPIA CONSENT",
      content: (
        <p style={{ padding: "20px" }}>
          <h4>
            We respect your privacy and acknowledge that this form contains
            personal details, which may belong to you, others and / or to your
            company.
          </h4>
          <Row>
            <p>
              By populating this form, you expressly give us consent to process
              and further process the Personal Information contained herein
              which processing will be done in accordance with POPIA.
            </p>
          </Row>
          <Row>
            <p>
              On signing and accepting this proposal you agree to SP Impact
              Fund’s financial requirements.
            </p>
          </Row>
          <Row>
            <p>
              Please sign and return the Funding Proposal if you are satisfied
              with the proposed terms and conditions.
            </p>
          </Row>
          <Row style={{ padding: 10 }}>
            <Checkbox onChange={onChange}>I hereby accept the above POPIA terms.</Checkbox>
          </Row>
          <Row>
            <p className="ion-text-center">
              Signed at{" "}
              <Input
                style={{ width: "20vw" }}
                value={PropposalFomData.ProposalSignedLocation}
                type="text"
                onChange={(e: any) => {
                  handlePropposalFomDataChange(e);
                }}
                name="ProposalSignedLocation"
                placeholder=""
                disabled={user.UserType !== "APPLICANT" ? true : false}
              />
              on day {todayDate.getDate()} of {" " + month}{" "}
              {" " + todayDate.getFullYear()}.
            </p>
          </Row>
          <Row>{content}</Row>

        </p>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            onBack={() => props.history.goBack()}
            title="Proposal Form"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">test</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCol class="ion-padding">
          <hr></hr>
          <IonRow>
            <IonCol class="ion-padding">
              <Button onClick={() => setisDocModalVisible(true)}>
                View Purchase Order
              </Button>
              <Steps style={{ padding: "20px" }} current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </IonCol>
          </IonRow>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action" style={{ textAlign: "center" }}>
            {current > 0 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={back}>
                  <Button
                    size={"large"}
                    style={{ margin: "0 8px" }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                </Tooltip>
              </IonCol>
            )}
            {current < steps.length - 1 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={section}>
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={(CheckObject) => next()}
                  >
                    Next
                  </Button>
                </Tooltip>
              </IonCol>
            )}
            {current === steps.length - 1 && (
              <IonRow>
                <IonCol class="ion-padding">
                  {/* <Link to="/ComplienceChecklist"> */}
                  <Tooltip placement="top" title={submit}>
                    <Button
                      size={"large"}
                      style={{ textAlign: "center" }}
                      type="primary"
                      onClick={() => {
                        SubmitProposal();
                      }}
                    >
                      Submit Proposal
                    </Button>
                  </Tooltip>
                  {/* </Link> */}
                </IonCol>

                <IonCol class="ion-padding">
                  {/* <Link to="/ComplienceChecklist"> */}

                  {contentF}

                  <Tooltip placement="top" title={decline}>
                    {contentB}
                  </Tooltip>
                  {/* </Link> */}
                </IonCol>
              </IonRow>
            )}
          </div>
        </IonCol>
        <Modal
          title="Document viewer"
          visible={isDocModalVisible}
          onCancel={handleDocCancel}
          style={{ paddingBottom: "0px", height: 1000 }}
          width={900}
        >
          <DocViewer
            style={{ width: 850, height: 700 }}
            pluginRenderers={DocViewerRenderers}
            documents={docs}
          />
        </Modal>
        <Modal
          title="Please provide a reason for declining."
          visible={isDeclineModalVisible}
          onOk={() => handleDeclineOk(CurrentProject)}
          onCancel={handleDeclineCancel}
          style={{ paddingBottom: "0px", height: 1000 }}
          width={900}
        >
          <TextArea
            rows={6}
            //@ts-ignore
            value={ReasonForDecline}
            onChange={(e) => {
              setReasonForDecline(e.target.value);
            }}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default ProposalForm;
