import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  useIonViewDidEnter,
  IonCard,
} from "@ionic/react";

import { useParams } from "react-router";

import { Descriptions, Row, Col, Table } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import moment from "moment";
import { Dropdown, Menu, message, InputNumber, Upload } from "antd";

import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import React, { useState } from "react";
import { PageHeader } from "antd";
import axios from "axios";
import { notification } from "antd";
import { UIStore } from "../store/UIStore";
const dateFormat = "YYYY/MM/DD";
const { TextArea } = Input;

const ClientBrief: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const { name } = useParams<{ name: string }>();

  async function SubmitClientBrief() {
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
          CurrentProject
        )
        .then((response) => {
          //props.history.push("/");
          console.log(response)
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
    props.history.push("/ProjectManager");
  }

  const [Projects, SetProjects] = React.useState<any>([
    { ProjectStartDate: "", Year: "", Description: "", Value: 0, Client: "", SMMERole: "", ClientContact: "" },
  ]);

  const columns = [

    {
      title: 'Project Start Date',
      dataIndex: 'ProjectStartDate',
    },
    {
      title: 'Description of project',
      dataIndex: 'Description',
    },
    {
      title: 'Value of project',
      className: 'column-money',
      dataIndex: 'Value',
    },
    {
      title: 'Role of  SMME in Project',
      dataIndex: 'SMMERole',
    },
    {
      title: 'Client',
      dataIndex: 'Client',
    },
    {
      title: 'Contact Details of Client',
      dataIndex: 'ClientContact',
    },
  ];

  const [data, setdata] = useState<any[]>([]);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");



  useIonViewDidEnter(() => {
    let temp = JSON.parse(CurrentProject.BusinessProjects);
    temp.shift();
    SetProjects(temp);
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/users/projectlist/" + user.id
      )
      .then(function (response) {
        //23 items
        console.log(response.data);

        setdata(response.data);

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader className="site-page-header" title="Client Brief" />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonCard style={{ margin: "2vw" }}>
            <Descriptions
              className="ion-text-center"
              title="Client  Brief"
            ></Descriptions>

            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong>Entity</strong>
                </p>
                <Input
                  value={CurrentProject.BusinessName}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BusinessName = e.target.value }) }}
                  name="BusinessName"
                  placeholder="Business Name"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Project</strong>
                </p>
                <Input
                  value={CurrentProject.LeadName}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.LeadName = e.target.value }) }}
                  name="ProjectName"
                  placeholder="Project"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Date</strong>
                </p>
                <DatePicker
                  value={CurrentProject.TradingStartDate.length === 0 ? moment(Date.now()) : moment(CurrentProject.TradingStartDate)}
                  onChange={(date, datestring) => { UIStore.update(s => { s.CurrentProject.TradingStartDate = datestring }) }}
                  name="Date"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Responsible Project Manager</strong>
                </p>
                <Input
                  value={CurrentProject.ProjectManagerName}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.ProjectManagerName = e.target.value }) }}
                  name="ProjectManagerName"

                />
              </IonCol>
            </Row>
            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>COMPANY INFORMATION</strong>
                </h6>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <p>BUSINESS INFORMATION OVERVIEW</p>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <p>
                  <strong>SMME Owner Details</strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Business Owner Surname</strong>
                </p>
                <Input
                  value={CurrentProject.BusinessOwnerSurname}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BusinessOwnerSurname = e.target.value }) }}
                  name="BusinessOwnerSurname"
                  placeholder="Business Name"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Business Owner First Name</strong>
                </p>
                <Input
                  value={CurrentProject.BusinessOwnerFirstName}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BusinessOwnerFirstName = e.target.value }) }}
                  name="BusinessOwnerFirstName"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Beneficiary ID. No</strong>
                </p>
                <Input
                  value={CurrentProject.BeneficiaryID}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BeneficiaryID = e.target.value }) }}
                  name="BeneficiaryID"

                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Male/ Female</strong>
                </p>
                <Input
                  value={CurrentProject.MaleFemale}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.MaleFemale = e.target.value }) }}
                  name="MaleFemale"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Under 36 years old (Yes, No)</strong>
                </p>
                <Input
                  value={CurrentProject.Under36}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.Under36 = e.target.value }) }}
                  name="Under36"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Race</strong>
                </p>
                <Input
                  value={CurrentProject.Race}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.Race = e.target.value }) }}
                  name="Race"

                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <p>
                  <strong>SMME Owner Details</strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Cell / Tel Number</strong>
                </p>
                <Input
                  value={CurrentProject.Telephone}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.Telephone = e.target.value }) }}
                  name="CellTell"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Physical Address</strong>
                </p>
                <Input
                  value={CurrentProject.PhysicalAddress}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.PhysicalAddress = e.target.value }) }}
                  name="PhysicalAddress"

                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <p>
                  <strong>Beneficiary Company Information</strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Company Name</strong>
                </p>
                <Input
                  value={CurrentProject.BusinessName}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BusinessName = e.target.value }) }}
                  name="CompanyName"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>BBBEE Rating</strong>
                </p>
                <Input
                  value={CurrentProject.BbbeeRating}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BbbeeRating = e.target.value }) }}
                  name="BBBEERating1"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>% Black Ownership</strong>
                </p>
                <Input
                  value={CurrentProject.BlackOwnership}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BlackOwnership = e.target.value }) }}
                  name="BlackOwnership"

                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>% Women Ownership</strong>
                </p>
                <Input
                  value={CurrentProject.WomanOwnership}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.WomanOwnership = e.target.value }) }}
                  name="WomanOwnership"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>% Youth Ownership</strong>
                </p>
                <Input
                  value={CurrentProject.YouthOwnership}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.YouthOwnership = e.target.value }) }}
                  name="YouthOwnership"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Company Contact Details (Tel / E-mail)</strong>
                </p>
                <Input
                  value={CurrentProject.CompanyContact}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.CompanyContact = e.target.value }) }}
                  name="CompanyContact"

                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Sector in which business operates</strong>
                </p>
                <Input
                  value={CurrentProject.BusinessSector}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BusinessSector = e.target.value }) }}
                  name="BusinessSector"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Business Registration Date</strong>
                </p>
                <Input
                  value={CurrentProject.BusinessRegistrationDate}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.BusinessRegistrationDate = e.target.value }) }}
                  name="BusinessRegistration"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Trading Start Date</strong>
                </p>
                <Input
                  value={CurrentProject.TradingStartDate}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.TradingStartDate = e.target.value }) }}
                  name="TradingStartDate"

                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>Classification (Micro, small, Medium)</strong>
                </p>
                <Input
                  value={CurrentProject.Classification}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.Classification = e.target.value }) }}
                  name="Classification"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>CIDB Grade</strong>
                </p>
                <Input
                  value={CurrentProject.CIDBGrade}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.CIDBGrade = e.target.value }) }}
                  name="CIDBGrade"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>GPS coordinates where available</strong>
                </p>
                <Input
                  value={CurrentProject.GPSCoordinates}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.GPSCoordinates = e.target.value }) }}
                  name="GPSCoordinates"

                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <p>
                  <strong>Prior to joining the programme</strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Annual revenue for the most recent financial period (R)
                  </strong>
                </p>
                <Input
                  value={CurrentProject.AnnualRevenue}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.AnnualRevenue = e.target.value }) }}
                  name="AnnualRevenue"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Method of Funding </strong>
                </p>
                <Input
                  value={CurrentProject.FundingMethod1}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.FundingMethod1 = e.target.value }) }}
                  name="FundingMethod"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Number of permanent employees in the business currently
                  </strong>
                </p>
                <Input
                  value={CurrentProject.PermanentEmployees}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.PermanentEmployees = e.target.value }) }}
                  name="PermanentEmployees"

                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Number of seasonal employees in the business in the past year
                  </strong>
                </p>
                <Input
                  value={CurrentProject.SeasonalEmployees}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.SeasonalEmployees = e.target.value }) }}
                  name="SeasonalEmployees"

                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Number of short term/ temporary jobs (Past year)
                  </strong>
                </p>
                <Input
                  value={CurrentProject.TempJobs}
                  type="text"
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.TempJobs = e.target.value }) }}
                  name="TempJobs"

                />
              </IonCol>

              <IonCol class="ion-padding">
                <p>
                  <strong>Date the SMME joined the intervention</strong>
                </p>
                <DatePicker
                  value={CurrentProject.SmmeDate.length === 0 ? moment(Date.now()) : moment(CurrentProject.SmmeDate)}
                  onChange={(date, dateString) => {
                    UIStore.update((s) => {
                      s.CurrentProject.SmmeDate = dateString;
                    });
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class="ion-padding">
                <p>
                  <strong>COMPANY BACKGROUND</strong>
                </p>
                <TextArea value={CurrentProject.CompanyBackGround}
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.CompanyBackGround = e.target.value }) }} placeholder="" rows={4} />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>PRIMARY BUSINESS</strong>
                </p>
                <TextArea value={CurrentProject.PrimaryBusiness}
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.PrimaryBusiness = e.target.value }) }} placeholder="" rows={4} />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>EMPLOYMENT CREATION AND RETENTION</strong>
                </p>
                <TextArea value={CurrentProject.EmploymentCreation} placeholder=""
                  onChange={(e) => { UIStore.update(s => { s.CurrentProject.EmploymentCreation = e.target.value }) }} rows={4} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>PREVIOUS WORK COMPLETED</strong>
                </h6>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <div style={{ paddingLeft: "20px" }}>
                  <Table columns={columns} dataSource={Projects} size="middle" />
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol class="ion-padding">
                <Button
                  size={"large"}
                  onClick={() => {
                    SubmitClientBrief();
                  }}
                  type="primary"
                >
                  Submit
                </Button>
              </IonCol>
            </IonRow>
          </IonCard>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default ClientBrief;
