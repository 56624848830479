import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Modal,
  Empty,
  Select,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { getDocsArray } from "../store/actions";
const dateFormat = "YYYY/MM/DD";

const TDRiskOfficer: React.FC = (props: any) => {
  const [applicationpacks, setapplicationpacks] = useState<any[]>([]);
  const { Step } = Steps;

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedERC",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/ERC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
  }

  async function ExceptionReport(object: any) {
    object.RiskOfficerExceptioncomment = exception;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedERCexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/ERC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
  }
  async function DownloadPack(object: any) { }

  useIonViewWillEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/users/projectlistall/ERC")
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  //modal
  const [exception, setException] = useState("")
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setException("");
    setVisible(false);
  };

  const handleComment = (object: any) => {
    console.log(object);
    ExceptionReport(object);
    setVisible(false);
  };

  //select
  const { Option } = Select;

  const onDownloadChange = (val: any) => {
    console.log("Link!")
    window.open(val, "_blank");
  };

  function getSelect(object: any) {

    let content =
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select file to download"
        optionFilterProp="children"
        onChange={onDownloadChange}
        filterOption={(input, option) =>
          //@ts-ignore
          option.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {getDocsArray(object).map((item) => {

          return (
            <Option value={item.uri} key={item.name + object.id}>{item.name}</Option>
          );
        })}
      </Select>

    return content
  }
  //end select

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Technical Director/Risk Officer"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "6%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={0} status="error">
              <Step
                title="Compiling initial risk assessment"
                description="In progress"
              />
              <Step
                title="TEC preliminary approval"
                description="Awaiting TEC review"
              />
              <Step title="Fund manager review" description="Awaiting review" />
              <Step
                title="Project manager present to TEC"
                description="Incomplete"
              />
            </Steps>
          </IonCol>
        </IonRow>

        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={<p>Application ID:{dataobject.id}</p>}
                      description="Pending ERC Outcome"
                    />


                  </List.Item>

                  <List.Item>

                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            ExceptionReport(dataobject);
                          }}
                          type="primary"
                        >
                          Create Exception Report
                        </Button>
                      </IonCol>

                      <IonCol>
                        {getSelect(dataobject)}

                      </IonCol>
                    </IonRow>
                  </List.Item>


                  <Modal
                    title="Exception"
                    visible={visible}
                    onCancel={handleCancel}
                    width={500}
                    centered
                    onOk={() => {
                      handleComment(dataobject);
                    }}
                  >
                    <IonRow>
                      <IonCol>
                        <TextArea
                          value={exception}
                          rows={4}
                          onChange={(e) => { setException(e.target.value) }}
                          name="exception"
                          placeholder="exception comment"
                        />
                      </IonCol>
                    </IonRow>
                  </Modal>
                </>
              ))
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TDRiskOfficer;
