import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonCard,
} from "@ionic/react";
import { useParams } from "react-router";
import { getcurrentproject, setcurrentproject } from "../store/actions";
import { store } from "../store/store";

import {
  Descriptions,
  Row,
  Col,
  notification,
  Upload,
  Tooltip,
  Modal,
  Radio,
} from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import { Steps } from "antd";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";

import { message } from "antd";
import { DatePicker, Space, Button, Select } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { checkProjectEmpty } from '../store/actions';
import { UIStore } from "../store/UIStore";

const dateFormat = "YYYY/MM/DD";
const { Step } = Steps;
const { Option } = Select;

const EligibilityCriteria: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const [ApplicationObject, setState] = useState<any>({
    CIDB: false,
    Ownership: false,
    ApplicationParticipation: false,
    MinimumWage: false,
    RingFenced: false,
    DevelopmentPlan: false,
    Training: false,
    Experience: false,
    DevelopmentAgreement: false,
    Assesment: false,
    MaterialsAgreement: false,
    CIDBCommentBox: "",
    CIDBDoc: "",
    OwnershipCommentBox: "",
    ApplicationParticipationBox: "",
    MinimumWageBox: "",
    RingFencedBox: "",
    DevelopmentPlanBox: "",
    TrainingBox: "",
    ExperienceBox: "",
    DevelopmentAgreementBox: "",
    AssesmentBox: "",
    MaterialsAgreementBox: "",
  });
  const [commentbox1, setShowcommentbox1] = useState(true);
  const [commentbox2, setShowcommentbox2] = useState(true);
  const [commentbox3, setShowcommentbox3] = useState(true);
  const [commentbox4, setShowcommentbox4] = useState(true);
  const [commentbox5, setShowcommentbox5] = useState(true);
  const [commentbox6, setShowcommentbox6] = useState(true);
  const [commentbox7, setShowcommentbox7] = useState(true);
  const [commentbox8, setShowcommentbox8] = useState(true);
  const [commentbox9, setShowcommentbox9] = useState(true);
  const [commentbox10, setShowcommentbox10] = useState(true);
  const [commentbox11, setShowcommentbox11] = useState(true);

  function beforeUpload(file: any) {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload Pdf files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Document must smaller than 5MB!");
    }

    return file.type === "application/pdf" && file.size / 1024 / 1024 < 5
      ? true
      : Upload.LIST_IGNORE;
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    SubmitEligibility();
    props.history.push("/ApplicationForm");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const options = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
  ];

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const propsCIDB = {
    name: "propsCIDB",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setState((prevState: any) => ({
          ...prevState,
          CIDBDoc: info.file.response.path,
        }));
        UIStore.update((s) => {
          s.CurrentProject.CIDBDoc = info.file.response.path;
        });
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const text = <span>Submit and proceed to the application form</span>;

  // async function EligibilityCriteria() {

  //   message
  //     .loading('Action in progress..', 5)
  //     .then(() => message.success('Submission Succeeded', 5));
  //   try {
  //     const request = await axios.post('https://dev.northroom.co.za/api/spfund/users/ECSUBMIT', ApplicationObject).then((response) => {

  //       message.success(response.data);
  //       // console.log(response);
  //       props.history.push("/BaselineReport");

  //     });

  //   } catch (error:any) {
  //     message.error(error);
  //   }

  // }

  useIonViewDidEnter(async () => {
    if (user.UserType === "SUPERADMIN") {

    } else if (checkProjectEmpty(CurrentProject)) {
      message
        .loading('Error', 1)
        .then(() => message.error('You have not loaded a project', 5));
      props.history.push("/projects");
    }



    //#region
    setState((prevState: any) => ({
      ...prevState,
      CIDB: CurrentProject.CIDB,
    }));

    setShowcommentbox1(!CurrentProject.CIDB);

    setState((prevState: any) => ({
      ...prevState,
      CIDBCommentBox: CurrentProject.CIDBCommentBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      Ownership: CurrentProject.Ownership,
    }));

    setShowcommentbox2(!CurrentProject.Ownership);

    setState((prevState: any) => ({
      ...prevState,
      OwnershipCommentBox: CurrentProject.OwnershipCommentBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      ApplicationParticipation: CurrentProject.ApplicationParticipation,
    }));
    setShowcommentbox3(!CurrentProject.ApplicationParticipation);

    setState((prevState: any) => ({
      ...prevState,
      ApplicationParticipationBox: CurrentProject.ApplicationParticipationBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      MinimumWage: CurrentProject.MinimumWage,
    }));

    setShowcommentbox4(!CurrentProject.MinimumWage);

    setState((prevState: any) => ({
      ...prevState,
      MinimumWageBox: CurrentProject.MinimumWageBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      RingFenced: CurrentProject.RingFenced,
    }));

    setShowcommentbox5(!CurrentProject.RingFenced);

    setState((prevState: any) => ({
      ...prevState,
      RingFencedBox: CurrentProject.RingFencedBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentPlan: CurrentProject.DevelopmentPlan,
    }));

    setShowcommentbox6(!CurrentProject.DevelopmentPlan);

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentPlanBox: CurrentProject.DevelopmentPlanBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      Training: CurrentProject.Training,
    }));

    setShowcommentbox7(!CurrentProject.Training);

    setState((prevState: any) => ({
      ...prevState,
      TrainingBox: CurrentProject.TrainingBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      Experience: CurrentProject.Experience,
    }));

    setShowcommentbox8(!CurrentProject.Experience);

    setState((prevState: any) => ({
      ...prevState,
      ExperienceBox: CurrentProject.ExperienceBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentAgreement: CurrentProject.DevelopmentAgreement,
    }));

    setShowcommentbox9(!CurrentProject.DevelopmentAgreement);

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentAgreementBox: CurrentProject.DevelopmentAgreementBox,
    }));

    setState((prevState: any) => ({
      ...prevState,
      Assesment: CurrentProject.Assesment,
    }));

    setShowcommentbox10(!CurrentProject.Assesment);

    setState((prevState: any) => ({
      ...prevState,
      AssesmentBox: CurrentProject.Assesment,
    }));

    setState((prevState: any) => ({
      ...prevState,
      MaterialsAgreement: CurrentProject.MaterialsAgreement,
    }));

    setShowcommentbox11(!CurrentProject.MaterialsAgreement);

    setState((prevState: any) => ({
      ...prevState,
      MaterialsAgreementBox: CurrentProject.MaterialsAgreementBox,
    }));

    //#endregion
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  async function SubmitEligibility() {
    console.log(CurrentProject);
    console.log(ApplicationObject);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate/Eligibility",
          CurrentProject
        )
        .then((response) => {
          message.success(response.data);
          console.log(response);
          //props.history.push("/ApplicationForm");
        });
    } catch (error: any) {
      message.error("An error has ocurred");
    }
  }

  const handleChangecheckbox = (e: any, name: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [name]: e,
    }));

    if (name === "CIDB") {
      UIStore.update((s) => {
        s.CurrentProject.CIDB = e;
      });
      setShowcommentbox1(!commentbox1);
    }

    if (name === "Ownership") {
      UIStore.update((s) => {
        s.CurrentProject.Ownership = e;
      });
      setShowcommentbox2(!commentbox2);
    }

    if (name === "ApplicationParticipation") {
      UIStore.update((s) => {
        s.CurrentProject.ApplicationParticipation = e;
      });
      setShowcommentbox3(!commentbox3);
    }

    if (name === "MinimumWage") {
      UIStore.update((s) => {
        s.CurrentProject.MinimumWage = e;
      });
      setShowcommentbox4(!commentbox4);
    }

    if (name === "RingFenced") {
      UIStore.update((s) => {
        s.CurrentProject.RingFenced = e;
      });
      setShowcommentbox5(!commentbox5);
    }

    if (name === "DevelopmentPlan") {
      UIStore.update((s) => {
        s.CurrentProject.DevelopmentPlan = e;
      });
      setShowcommentbox6(!commentbox6);
    }

    if (name === "Training") {
      UIStore.update((s) => {
        s.CurrentProject.Training = e;
      });
      setShowcommentbox7(!commentbox7);
    }

    if (name === "Experience") {
      UIStore.update((s) => {
        s.CurrentProject.Experience = e;
      });
      setShowcommentbox8(!commentbox8);
    }

    if (name === "DevelopmentAgreement") {
      UIStore.update((s) => {
        s.CurrentProject.DevelopmentAgreement = e;
      });
      setShowcommentbox9(!commentbox9);
    }

    if (name === "Assesment") {
      UIStore.update((s) => {
        s.CurrentProject.Assesment = e;
      });
      setShowcommentbox10(!commentbox10);
    }

    if (name === "MaterialsAgreement") {
      UIStore.update((s) => {
        s.CurrentProject.MaterialsAgreement = e;
      });
      setShowcommentbox11(!commentbox11);
    }
  };



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              // onBack={() => null}
              title="Eligibility Criteria"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-text-center" fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonRow style={{ paddingLeft: "9%" }}>
            <IonCol class="ion-padding">
              <Steps current={0} status="error">
                <Step
                  title="Eligibility Criteria"
                  description="Complete Eligibility Criteria"
                />
                <Step title="Application Form" description="Incomplete" />
                <Step title="Compliance Checklist" description="Incomplete" />
                <Step title="Declaration form" description="Incomplete" />
              </Steps>
            </IonCol>
          </IonRow>
          <hr></hr>

          <IonRow>
            <IonCol className="ion-text-center">
              <h1>Eligibility</h1>
            </IonCol>
          </IonRow>
          <IonCard style={{ margin: "2vw" }}>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>Is your company 51% Black-Owned?</strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.Ownership = true;
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.Ownership = false;
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>

                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.Ownership}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.Ownership = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>Are you registered with CIDB?</strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.CIDB = true;
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.CIDB = false;
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.SupplierCert}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.SupplierCert = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    If “YES” please confirm your latest CIDB Grading{" "}
                  </strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Input
                    style={{ width: "10vw" }}
                    value={CurrentProject.CIDBGrade}
                    type="text"
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.CIDBGrade = e.target.value;
                      });
                    }}
                    name="CIDBGrade"
                    placeholder=""
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonCard>
          <IonCard style={{ margin: "2vw" }}>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>If “NO” please confirm: </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Have you ever submitted an application to the CIDB?
                  </strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.PreviousSubmissionCIDB = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.PreviousSubmissionCIDB = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.PreviousSubmissionCIDB}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.PreviousSubmissionCIDB =
                          e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you in the process of applying to the CIDB?
                  </strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.CIDBApplying = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.CIDBApplying = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.CIDBApplying}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.CIDBApplying = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
          </IonCard>
          <IonCard style={{ margin: "2vw" }}>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    IS THERE ANY ASSISTANCE THAT SP IMPACT FUND MAY PROVIDE
                    REGARDING YOUR STATUS WITH THE CIDB? IF SO, PLEASE SPECIFY
                    IN THE SPACE PROVIDED BELOW:{" "}
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <TextArea
                  value={CurrentProject.SPAssistance}
                  placeholder=""
                  rows={4}
                  onChange={(e: any) => {
                    UIStore.update((s) => {
                      s.CurrentProject.SPAssistance = e.target.value;
                    });
                  }}
                />
              </IonCol>
            </IonRow>
          </IonCard>
          <IonCard style={{ margin: "2vw" }}>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>Is your company registered with the CIPC?</strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.CIPCRegistered = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.CIPCRegistered = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.CIPCRegistered}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.CIPCRegistered = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    If “YES” please confirm your Company Registration No.{" "}
                  </strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Input
                    value={CurrentProject.BusinessRegistration}
                    type="text"
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.BusinessRegistration = e.target.value;
                      });
                    }}
                    name="BusinessRegistration"
                    placeholder=""
                  />
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you able to submit financial statements for the last
                    year?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.FinancialStatementsSubmission = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.FinancialStatementsSubmission = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.FinancialStatementsSubmission}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.FinancialStatementsSubmission =
                          e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    If “NO” please confirm – who is responsible for your
                    Financial records.{" "}
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <TextArea
                  value={CurrentProject.ResponsibleFinancialsRecord}
                  placeholder=""
                  rows={4}
                  onChange={(e: any) => {
                    UIStore.update((s) => {
                      s.CurrentProject.ResponsibleFinancialsRecord =
                        e.target.value;
                    });
                  }}
                />
              </IonCol>
            </IonRow>
          </IonCard>

          <IonCard style={{ margin: "2vw" }}>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Please confirm whether or not you are willing to comply with
                    the following:
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you willing to submit a formal application?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.FormalApplication = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.FormalApplication = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.FormalApplication}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.FormalApplication = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you willing to adhere to sp impact fund’s application
                    assessment and due diligence processes?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.SPfundApplicationAssessmentDiligence = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.SPfundApplicationAssessmentDiligence = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.SPfundApplicationAssessmentDiligence}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.SPfundApplicationAssessmentDiligence =
                          e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you willing to have a training needs analysis conducted?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingTraining = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingTraining = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.WillingTraining}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.WillingTraining = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    If yes, are you willing to attend training courses based on
                    the results of the needs analysis conducted and a training
                    development plan?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingtoAttend = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingtoAttend = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.WillingtoAttend}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.WillingtoAttend = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you willing to employ workers to help complete your
                    project at the minimum industry wage prescribed?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingMinWage = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingMinWage = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>

                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.WillingMinWage}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.WillingMinWage = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you willing to enter into a materials supply agreement
                    with sp impact fund?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingMatsSpfund = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingMatsSpfund = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.WillingMatsSpfund}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.WillingMatsSpfund = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you willing to enter into a materials supply agreement
                    with sp impact fund that includes ceding income to the value
                    of any funding approved?{" "}
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingMatsSpfundCeding = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingMatsSpfundCeding = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.WillingMatsSpfundCeding}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.WillingMatsSpfundCeding =
                          e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>
                    Are you willing to open a ring-fenced account with sp impact
                    fund being the sole administrator on the account?
                  </strong>
                </p>
              </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <div>
                  <Select
                    labelInValue
                    placeholder="Yes/No"
                    style={{ width: 120 }}
                    onChange={(e) => {
                      if (e.toString() === "Yes") {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingRingFenced = "Yes";
                        });
                      } else {
                        UIStore.update((s) => {
                          s.CurrentProject.WillingRingFenced = "No";
                        });
                      }
                    }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                  {/* <Radio.Group
                    options={options}
                    value={CurrentProject.WillingRingFenced}
                    onChange={(e: any) => {
                      UIStore.update((s) => {
                        s.CurrentProject.WillingRingFenced = e.target.value;
                      });
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  /> */}
                </div>
              </IonCol>
            </IonRow>
            <IonRow
              className="ion-text-center"
              style={{ paddingBottom: "2vw" }}
            >
              <IonCol>
                <Button
                  size={"large"}
                  type="primary"
                  onClick={() => {
                    showModal();
                  }}
                >
                  Submit
                </Button>
              </IonCol>
            </IonRow>
          </IonCard>
          <Modal
            title=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Proceeding to the Application form. </p>
            <p>
              You will be required to fill out business and shareholder details
              required by SP Impact Fund to proceed.
            </p>
          </Modal>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default EligibilityCriteria;
