import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  withIonLifeCycle,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";

import { Descriptions, Row, Col } from "antd";
import { Card, Input } from "antd";
import { Switch } from "antd";
import { Collapse } from "antd";
import { DatePicker, Space, Button, Steps, Tooltip } from "antd";
import { getuserObject } from "../store/actions";
import axios from "axios";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./Page2.css";
import { PageHeader } from "antd";
import { Link } from "react-router-dom";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { useState } from "react";
import { UIStore } from "../store/UIStore";
import Modal from "antd/lib/modal/Modal";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

const BankingDocs: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function SubmitCompliance() {
    console.log(CurrentProject);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/projects/bankingdocs/",
          CurrentProject
        )
        .then((response) => {
          props.history.push("/EligibilityCriteria");
        });
    } catch (error: any) {
      console.error(error);
    }
  }

  const { Step } = Steps;

  const { name } = useParams<{ name: string }>();

  // function onChange(checked: any) {
  //   console.log(`switch to ${checked}`);
  // }

  // function handleChange(value: any) {
  //   console.log(`selected ${value}`);
  // }

  const { Panel } = Collapse;

  function callback(key: any) {
    console.log(key);
  }

  const [isModalHelpVisible, setIsModalHelpVisible] = useState(false);

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const showHelpModal = () => {
    setIsModalHelpVisible(true);
  };

  const handleHelpOk = () => {
    SubmitCompliance();
    setIsModalHelpVisible(false);
  };

  const handleHelpCancel = () => {
    setIsModalHelpVisible(false);
  };

  const genExtra = (item: any) => {
    if (item === true) {
      return (
        <>
          {" "}
          Document Submitted <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
        </>
      );
    } else {
      return (
        <>
          {" "}
          Document Outstanding{" "}
          <ExclamationCircleTwoTone twoToneColor="#FF0000" />{" "}
        </>
      );
    }
  };

  const text = <span>Submit and proceed to Eligibility Criteria form</span>;

  useIonViewDidEnter(() => {
    console.log(CurrentProject);
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  // async function SubmitApplicationForm() {

  //   message
  //     .loading('Action in progress..', 5)
  //     .then(() => message.success('Submission Succeeded', 5));

  // }

  function beforeUpload(file: any) {
    const isPdf =
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    console.log(file.type);
    if (!isPdf) {
      message.error("You can only upload Pdf or Word files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Document must smaller than 5MB!");
    }

    return file.type === "application/pdf" ||
      file.type === "application/msword" ||
      (file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        file.size / 1024 / 1024 < 5)
      ? true
      : Upload.LIST_IGNORE;
  }

  const propsResolution = {
    name: "propsResolution",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.Resolution = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        console.log(info);
      }
    },
  };
  const propsShareholdingConfirmation = {
    name: "propsShareholdingConfirmation",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.ShareholdingConfirmation = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          message.error(
            `${info.file.name} file upload failed. Error: ${error}`
          );
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsTaxPin = {
    name: "propsTaxPin",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.TaxPin = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsBOQ2 = {
    name: "propsBOQ2",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.BOQ2 = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        console.log();
      }
    },
  };
  const propsCompletionCertificate = {
    name: "propsCompletionCertificate",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage",
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update((s) => {
          s.CurrentProject.CompletionCertificate = info.file.response.path;
        });
        try {
          const request = await axios
            .post(
              "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
              CurrentProject
            )
            .then((response) => {
              // message.success(response.data);
              console.log(response);
            });
        } catch (error: any) {
          console.error(error);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            title="Banking Documents (FICA)"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonRow style={{ paddingLeft: "2%", width: "100vw" }}>
            <IonCol class="ion-padding" size="8">
              <Steps current={1} status="error">
                <Step
                  title="Formal Acceptance of proposal"
                  description="Completed"
                />
                <Step
                  title="Required FICA documentation"
                  description="In progress"
                />
                <Step
                  title="Capture banking details(Ring fenced)"
                  description="Incomplete"
                />
                <Step title="Application pack" description="Incomplete" />
              </Steps>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <Collapse
                defaultActiveKey={["1"]}
                onChange={callback}
                style={{
                  width: "70vw",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  paddingBottom: "2%",
                }}
              >
                {/* change the below line when DB is updated to account for correct document */}
                <Panel
                  header="Resolution specifying who is authorized to act on behalf of the company"
                  key="15"
                  extra={genExtra(CurrentProject.Resolution.length > 5)}
                >
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}>
                        <strong>Upload Documents</strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol></IonCol>

                    <IonCol>
                      <Upload
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        {...propsResolution}
                      >
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload Files
                          </Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol></IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Checkbox onChange={onChange}>Not Applicable</Checkbox>
                    </IonCol>
                  </IonRow>
                </Panel>
                {/* change the below line when DB is updated to account for correct document  */}
                <Panel
                  header="Confirmation of shareholding"
                  key="16"
                  extra={genExtra(
                    CurrentProject.ShareholdingConfirmation.length > 5
                  )}
                >
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}>
                        <strong>Upload Documents</strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol></IonCol>

                    <IonCol>
                      <Upload
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        {...propsShareholdingConfirmation}
                      >
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload Files
                          </Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol></IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Checkbox onChange={onChange}>Not Applicable</Checkbox>
                    </IonCol>
                  </IonRow>
                </Panel>
                {/* change the below line when DB is updated to account for correct document  */}
                <Panel
                  header="Tax Pin"
                  key="17"
                  extra={genExtra(CurrentProject.TaxPin.length > 5)}
                >
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}>
                        <strong>Upload Documents</strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol></IonCol>

                    <IonCol>
                      <Upload
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        {...propsTaxPin}
                      >
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload Files
                          </Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol></IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Checkbox onChange={onChange}>Not Applicable</Checkbox>
                    </IonCol>
                  </IonRow>
                </Panel>
                {/* change the below line when DB is updated to account for correct document  */}
                {/* <Panel
                  header="Priced Bills of Quantities (Only for Construction Funding)"
                  key="18"
                  extra={genExtra(CurrentProject.BOQ2.length > 5)}
                >
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}>
                        <strong>Upload Documents</strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol></IonCol>

                    <IonCol>
                      <Upload
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        {...propsBOQ2}
                      >
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload Files
                          </Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol></IonCol>
                  </IonRow>
                </Panel> */}
                {/* change the below line when DB is updated to account for correct document  */}
                <Panel
                  header="Completion Certificate(s) of a similar project undertaken in the past (Only for Construction Funding)"
                  key="19"
                  extra={genExtra(
                    CurrentProject.CompletionCertificate.length > 5
                  )}
                >
                  <IonRow className="ion-align-items-center ion-justify-content-center">
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}>
                        <strong>Upload Documents</strong>
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>
                        <strong></strong>
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol></IonCol>

                    <IonCol>
                      <Upload
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        {...propsCompletionCertificate}
                      >
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload Files
                          </Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol></IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Checkbox onChange={onChange}>Not Applicable</Checkbox>
                    </IonCol>
                  </IonRow>
                </Panel>
              </Collapse>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class=" ion-text-center ">
              <Tooltip placement="top" title={text}>
                <Button
                  style={{ marginRight: "30vw" }}
                  size={"large"}
                  type="primary"
                  onClick={() => {
                    showHelpModal();
                  }}
                >
                  {" "}
                  Submit
                </Button>
                <Modal
                  title=""
                  visible={isModalHelpVisible}
                  onOk={handleHelpOk}
                  onCancel={handleHelpCancel}
                >
                  <p>
                    <strong>Proceeding to the Application pack</strong>
                  </p>
                  <p>
                    You will be required to provide a signature in order to
                    complete your application
                  </p>
                </Modal>
              </Tooltip>
            </IonCol>
          </IonRow>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default BankingDocs;
