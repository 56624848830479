import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonItemDivider,
    IonLabel,
    useIonViewDidEnter,
    useIonViewDidLeave,
    IonItem,
  } from "@ionic/react";
  import { useParams } from "react-router";
  
  import {
    Descriptions,
    Row,
    Col,
    notification,
    List,
    Avatar,
    Button,
    Steps,
    Tooltip,
    Empty,
  } from "antd";
  import { Card } from "antd";
  import { Switch, Radio } from "antd";
  import { Input } from "antd";
  import {
    PlusOutlined,
    ProjectOutlined,
    ReloadOutlined,
    UserOutlined,
  } from "@ant-design/icons";
  import moment from "moment";
  import { message, Tag } from "antd";
  import { DatePicker, Space } from "antd";
  import "./Page2.css";
  import { PageHeader } from "antd";
  import React, { useEffect, useState } from "react";
  import logo from "../pages/images/image6.jpg";
  
  import axios from "axios";
  import SignatureCanvas from "react-signature-canvas";
  import { DownloadOutlined } from "@ant-design/icons";
  import Modal from "antd/lib/modal/Modal";
  import { setcurrentproject } from "../store/actions";
  import { UIStore } from "../store/UIStore";
  
  const dateFormat = "YYYY/MM/DD";
  
  const FinanceDash: React.FC = (props: any) => {
    const { Step } = Steps;
  
    const [ProjectName, setProjectName] = useState("");
  
    const [data, setdata] = useState<any[]>([]);
  
    const { TextArea } = Input;
    const { name } = useParams<{ name: string }>();
    var sigCanvas: any = {};
  
    const createText = (
      <span>Create a project, if you do not have one created below.</span>
    );
    const loadText = <span>Load an existing project.</span>;
    const buttonWidth = 70;
  
    const [projects, setprojects] = useState<any[]>([]);
    const CurrentProject = UIStore.useState((s) => s.CurrentProject);
    const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  
  
    useIonViewDidEnter(() => {
      axios
        .get(
          "https://dev.northroom.co.za/api/spfund/projects/projectsfm"
        )
        .then(function (response) {
          //23 items
          console.log(response.data);
  
          setdata(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    });
  
    useIonViewDidLeave(() => {
      console.log("ionViewDidLeave event fired");
    });
  
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [modalText, setModalText] = React.useState("Project Name");
    // const [modalText2, setModalText2] = React.useState('Some other input');
  
    const showModal = () => {
      setVisible(true);
    };
  
    /*
    useEffect(() => {
      console.log(CurrentProject);
    }, [CurrentProject]);
  */
  
    function loadProject(projectobject: any) {
      UIStore.update((s) => {
        s.CurrentProject = projectobject;
      });
  
      console.log(CurrentProject.CurrentStatus);
  
      switch (projectobject.CurrentStatus) {
        case "AWAITINGPROPOSALACCEPTANCE":
          props.history.push("/Proposal");
          break;
          case "ELIGIBILITY":
            props.history.push("/EligibilityCriteria");
            break;
      
        default:
          props.history.push("/Typeofapplication");
          break;
      }
  
      
    }
  
    
  
    const handleCancel = () => {
      console.log("Clicked cancel button");
      setVisible(false);
    };
  
    const checkForException = (object: any) => {
      switch (object.CurrentStatus) {
        case "APPOFFICEREXCEPTION":
          return object.AppOfficerExceptioncomment;
  
        case "ERCOFFICEREXCEPTION ":
          return object.RiskOfficerExceptioncomment;
  
        case "COMPOFFICEREXCEPTION ":
          return object.CompOfficerExceptioncomment;
  
        case "TECEXCEPTION":
          return object.Tecexceptioncoment;
          //ScreeningOfficerExceptioncomment
        default:
          break;
      }
    };
    
  
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonRow>
              <PageHeader className="site-page-header" title="Finance Admin Dashboard" />
            </IonRow>
          </IonToolbar>
        </IonHeader>
  
        <IonContent fullscreen>
          <IonRow>
            <IonCol>
              {/* <Card>
                <Tooltip
                  style={{ width: buttonWidth, marginLeft: buttonWidth * 4 + 24 }}
                  placement="right"
                  title={createText}
                >
                  <Button
                    type="primary"
                    onClick={showModal}
                    icon={<PlusOutlined />}
                  >
                    Create New Project
                  </Button>
                </Tooltip>
                <Modal
                  title="New Project"
                  visible={visible}
                  onOk={CreateProject}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancel}
                >
                  <p>{modalText}</p>
                  <Input
                    value={ProjectName}
                    size="large"
                    placeholder=""
                    name={"ProjectName"}
                    onChange={(e) => {
                      setProjectName(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
  
                  <br></br>
                  <br></br>
              
                </Modal>
              </Card> */}
            </IonCol>
          </IonRow>
          {data.length === 0 ? (
             <Empty imageStyle={{ alignSelf: "center" }} />
          ) : (
            <List
              dataSource={[{}]}
              bordered
              renderItem={(item) =>
                data.map((dataobject) => (
                  <>
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={logo} />}
                        title={
                          <p>
                            Project Application Name:
                            <Tag color="blue">{dataobject.LeadName}</Tag>
                          </p>
                        }
                        
                      />
                      <IonCol>
                        <Tag color="geekblue">
                          Project Stage: {dataobject.CurrentStatus}
                        </Tag>
                      </IonCol>
  
                      <IonCol>
                        <Tooltip
                          style={{
                            width: buttonWidth,
                            marginLeft: buttonWidth * 4 + 24,
                          }}
                          placement="right"
                          title={"View payment instruction"}
                        >
                          <Button
                            onClick={() => {
                              UIStore.update((s) => {
                                s.CurrentProject = dataobject;
                              });
                              if (dataobject.PaymentInstruction === null) {
                                UIStore.update((s) => {
                                  s.CurrentProject.PaymentInstruction =   {
                                    Client: "",
                                    ProjectDescription: "",
                                    TotalFundingApproved: "",
                                    DrawdownRequired: "",
                                    SupplierName: "",
                                    AmountPayable: "",
                                    Bank: "",
                                    AccountNumber: "",
                                    PFAName: "",
                                    FMEName: "",
                                    CEName: "",
                                    DocRequestForPayment: "",
                                    DocDrawdown: "",
                                    DocSuppliersQ: "",
                                    DocSupplierConfirmation: "",
                                    PFACapacity: "",
                                    FMESignature: "",
                                    CECapacity: "",
                                   CESignature: "",
                                   FMRSignature: "",
                                   FMRCapacity: "",
                                   PFAsignature:"",
                                 };
                                });
                              }
                              props.history.push("/PaymentInstruction");
                            }}
                            type="primary"
                            
                          >
                            Payment Instruction
                          </Button>
                        </Tooltip>
                      </IonCol>
                      {/* <IonCol>
                        <Tag color="red">{checkForException(dataobject)}</Tag>
                      </IonCol> */}
  
                      
                    </List.Item>
  
                    <List.Item>
                      <IonRow></IonRow>
                      <IonRow></IonRow>
                    </List.Item>
                  </>
                ))
              }
            />
          )}
        </IonContent>
      </IonPage>
    );
  };
  
  export default FinanceDash;
  