import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Empty,
  Modal,
  Select,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { ReloadOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ProjectStatus, UIStore } from "../store/UIStore";
import { getDocsArray } from "../store/actions";
const dateFormat = "YYYY/MM/DD";

const ProjectManager: React.FC = (props: any) => {

  const { Step } = Steps;
  const [IsRODVisible, setIsRODVisible] = useState(false);
  const [IsCICVisible, setIsCICVisible] = useState(false);
  const [globalObject, setGlobalObject] = useState({});

  const [data, setdata] = useState<any[]>([]);
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();


  function loadProject(link: any) {


    props.history.push(link);
    console.log(CurrentProject);


  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");


  useIonViewDidEnter(() => {

    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/users/projectmanager/" + user.id
      )
      .then(function (response) {
        setdata(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });


  async function DownloadPack(object: any) {
    //turn docs file links into an array

    let linkarr = [];

    linkarr.push(object.Spif);
    linkarr.push(object.BOQ);
    linkarr.push(object.ImplementationPlan);
    linkarr.push(object.Letterofappointment);
    linkarr.push(object.BankingStatements);
    linkarr.push(object.ManagementAccounts);
    linkarr.push(object.Insurance);
    linkarr.push(object.CIDBDoc);
    linkarr.push(object.NHBRC);
    linkarr.push(object.TaxClearanceDoc);
    linkarr.push(object.Vat);
    linkarr.push(object.DepartLabor);
    linkarr.push(object.personnel);
    linkarr.push(object.AllDirectors);
    linkarr.push(object.MartialSTatus);
    linkarr.push(object.BBEECert);
    linkarr.push(object.Companyregdocuments);
    linkarr.push(object.ApplicationForm);
    linkarr.push(object.BaselineForm);
    linkarr.push(object.CIPCForm);
    linkarr.push(object.CreditagreementForm);
    linkarr.push(object.companyprofile);
    linkarr.push(object.ProofAddress);
    linkarr.push(object.DeclarationForm);
    linkarr.push(object.JVForm);
    linkarr.push(object.EligibilityCriteriaForm);
    linkarr.push(object.Resolution);
    linkarr.push(object.ShareholdingConfirmation);
    linkarr.push(object.TaxPin);
    linkarr.push(object.BOQ2);
    linkarr.push(object.CompletionCertificate);
    linkarr.push(object.CPO);
    linkarr.push(object.RFQ);
    linkarr.push(object.SuppliersQ);
    linkarr.push(object.Cinvoice);
    linkarr.push(object.DebtAge);
    linkarr.push(object.EditorsAge);
    linkarr.push(object.PoCl);
    linkarr.push(object.MonthBankState);
    linkarr.push(object.BillofQuanties);
    linkarr.push(object.SLA);
    linkarr.push(object.CletterofAppointment);
    linkarr.push(object.ProjectImplentaionPlan);
    linkarr.push(object.CinvoicePaidbyPayMaster);
    linkarr.push(object.PaymentCert);
    linkarr.push(object.DebtandCred);

    linkarr.map((mainurl) => {
      if (mainurl.length !== 0) {
        axios({
          url: mainurl,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            mainurl.replace(
              " https://dev.northroom.co.za/api/spfund/static/",
              ""
            )
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    });
  }

  async function pushToTec(object: any) {
    object.CurrentStatus = ProjectStatus.AWAITINGTEC;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectmanager/" +
              user.id
            )
            .then(function (response) {
              setdata(response.data);
              console.log(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
  }

  const showROD = () => {
    setIsRODVisible(true);
  };
  const handleCancel = () => {
    setIsRODVisible(false);
  };
  const showCIC = () => {
    setIsCICVisible(true);
  };
  const handleCICCancel = () => {
    setIsCICVisible(false);
  };

  const { Option } = Select;
  const onDownloadChange = (val: any) => {
    console.log("Link!")
    window.open(val, "_blank");
  };

  let content = <></>;

  function getSelect(object: any) {

    content =
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select file to download"
        optionFilterProp="children"
        onChange={onDownloadChange}
        filterOption={(input, option) =>
          //@ts-ignore
          option.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {getDocsArray(object).map((item) => {
          //console.log(item.name);
          return (
            <Option value={item.uri} key={item.name + object.id}>{item.name}</Option>
          );
        })}
      </Select>

    return content
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="Project Manager" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "5%" }}>
          <IonCol class="ion-padding" size="11">

          </IonCol>
        </IonRow>

        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Project Application ID:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                        </p>
                      }
                      description={
                        "Project Status: " + dataobject.CurrentStatus
                      }
                    />
                    <List.Item.Meta
                      title={<p>Client Details:{dataobject.Client}</p>}
                    />
                    <List.Item.Meta
                      title={<p>Client Contact:{dataobject.ClientContact}</p>}
                    />
                  </List.Item>

                  <List.Item>
                    <IonRow>
                      <IonCol>

                        <Button
                          onClick={() => {
                            UIStore.update((s) => {
                              s.CurrentProject = dataobject;
                            });
                            props.history.push("/BaselineReport");

                          }}
                          type="primary"
                          disabled={dataobject.CurrentStatus === ProjectStatus.AWAITINGBASELINE ? false : true}
                        >
                          Conduct Baseline

                        </Button>

                      </IonCol>
                      <IonCol>
                        <Link to="/Vetting">
                          <Button
                            onClick={() => {
                              UIStore.update((s) => {
                                s.CurrentProject = dataobject;
                              });
                              props.history.push("/Vetting");
                            }}
                            type="primary"
                            disabled={dataobject.CurrentStatus === ProjectStatus.AWAITINGVETTING ? false : true}
                          >
                            Conduct Vetting Assessment
                          </Button>
                        </Link>
                      </IonCol>
                      <IonCol>

                        <Button
                          onClick={() => {
                            UIStore.update((s) => {
                              s.CurrentProject = dataobject;
                            });
                            console.log(CurrentProject);
                            loadProject("/ClientBrief")
                          }}
                          type="primary"
                          disabled={dataobject.CurrentStatus === ProjectStatus.AWAITINGBRIEF ? false : true}
                        >
                          Client Brief
                        </Button>

                      </IonCol>
                      <IonCol>

                        <Button
                          onClick={() => {
                            UIStore.update((s) => {
                              s.CurrentProject = dataobject;
                            });
                            props.history.push("/ProjectBrief");

                          }}
                          type="primary"
                          disabled={dataobject.CurrentStatus === ProjectStatus.AWAITINGBRIEF ? false : true}
                        >
                          Project Brief
                        </Button>

                      </IonCol>

                      <IonCol>
                        {/* <Button type="primary" icon={<DownloadOutlined />}
                          onClick={() => { DownloadPack(dataobject) }}
                        disabled={dataobject.CurrentStatus!==ProjectStatus.VERIFIEDDOCUMENTSFM?false:true}>
                          Download
                        </Button> */}
                        {getSelect(dataobject)}
                      </IonCol>

                      <IonCol>
                        <Button
                          type="primary"
                          onClick={() => {
                            pushToTec(dataobject);
                          }}
                          disabled={dataobject.CurrentStatus === ProjectStatus.FMRECCOMENDATION ? false : true}
                        >
                          Ready for TEC
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          type="primary"
                          onClick={() => {
                            showROD();
                            setGlobalObject(dataobject);
                          }}
                          disabled={dataobject.CurrentStatus === ProjectStatus.AWAITINGBRIEF ? false : true}
                        >
                          ROD
                        </Button>
                      </IonCol>
                      <IonCol>
                        <Button
                          type="primary"
                          onClick={() => {
                            showCIC();
                            setGlobalObject(dataobject);
                          }}

                        >
                          CIC
                        </Button>
                      </IonCol>
                    </IonRow>
                    <IonCol>
                      <p style={{ display: "inline" }}>
                        Project Recommendation:{" "}
                      </p>
                      <Tag color="blue">
                        {dataobject.FundManagerRecommendation}
                      </Tag>
                    </IonCol>
                  </List.Item>
                </>
              ))
            }
          />
        )}
        <Modal
          title="Record of Decision"
          visible={IsRODVisible}
          onOk={() => { handleCancel() }}
          onCancel={handleCancel}
        >
          <TextArea
            //@ts-ignore
            value={globalObject.RECORDOFDECISION}
            placeholder=""
            rows={4}
          />
        </Modal>
        <Modal
          title="CIC Approval Status"
          visible={IsCICVisible}
          onOk={() => { handleCICCancel() }}
          onCancel={handleCICCancel}
        >
          <TextArea
            //@ts-ignore
            value={globalObject.VotingComment}
            placeholder=""
            rows={4}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default ProjectManager;
