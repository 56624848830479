import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Modal,
  Select,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";

import { message, Tag } from "antd";

import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";

import { DownloadOutlined } from "@ant-design/icons";
import { ProjectStatus } from "../store/UIStore";
import { getDocsArray } from "../store/actions";
const dateFormat = "YYYY/MM/DD";

const ApplicationsOfficer: React.FC = (props: any) => {
  const [applicationpacks, setapplicationpacks] = useState<any[]>([]);
  const { Step } = Steps;

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  // async function SubmitApplicationPack() {

  //   message
  //     .loading('Action in progress..', 5)
  //     .then(() => message.success('Submission Succeeded', 5));
  //   try {
  //     const request = await axios.post('https://dev.northroom.co.za/api/spfund/users/submitapplicationScreen', ApplicationObject).then((response) => {

  //       // message.success(response.data);
  //       // console.log(response);
  //       props.history.push("/CreditAgreement");

  //     });

  //   } catch (error:any) {
  //     message.error(error);
  //   }

  // }

  //select
  const { Option } = Select;

  const onDownloadChange = (val: any) => {
    console.log("Link!")
    window.open(val, "_blank");
  };

  function getSelect(object: any) {

    let content =
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select file to download"
        optionFilterProp="children"
        onChange={onDownloadChange}
        filterOption={(input, option) =>
          //@ts-ignore
          option.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {getDocsArray(object).map((item) => {

          return (
            <Option value={item.uri} key={item.name + object.id}>{item.name}</Option>
          );
        })}
      </Select>

    return content
  }
  //end select
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedappofficer",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/appofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error(error);
    }
    try {
      const request = await axios
        .post("https://dev.northroom.co.za/api/spfund/users/messageuser/", {
          userid: 43,
          message:
            "Application Officer has submitted an application pack for you to review.",
        })
        .then((response2) => { });
    } catch (error: any) {
      console.error(error);
    }
  }
  async function ExceptionReport(object: any) {
    object.AppOfficerExceptioncomment = exception;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedappofficerexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/appofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error(error.data);
    }
  }

  useIonViewWillEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/users/projectlistall/appofficer"
      )
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  async function DownloadPack(object: any) {
    //turn docs file links into an array

    let linkarr = [];

    linkarr.push(object.Spif);
    linkarr.push(object.BOQ);
    linkarr.push(object.ImplementationPlan);
    linkarr.push(object.Letterofappointment);
    linkarr.push(object.BankingStatements);
    linkarr.push(object.ManagementAccounts);
    linkarr.push(object.Insurance);
    linkarr.push(object.CIDBDoc);
    linkarr.push(object.NHBRC);
    linkarr.push(object.TaxClearanceDoc);
    linkarr.push(object.Vat);
    linkarr.push(object.DepartLabor);
    linkarr.push(object.personnel);
    linkarr.push(object.AllDirectors);
    linkarr.push(object.MartialSTatus);
    linkarr.push(object.BBEECert);
    linkarr.push(object.Companyregdocuments);
    linkarr.push(object.ApplicationForm);
    linkarr.push(object.BaselineForm);
    linkarr.push(object.CIPCForm);
    linkarr.push(object.CreditagreementForm);
    linkarr.push(object.companyprofile);
    linkarr.push(object.ProofAddress);
    linkarr.push(object.DeclarationForm);
    linkarr.push(object.JVForm);
    linkarr.push(object.EligibilityCriteriaForm);
    linkarr.push(object.Resolution);
    linkarr.push(object.ShareholdingConfirmation);
    linkarr.push(object.TaxPin);
    linkarr.push(object.BOQ2);
    linkarr.push(object.CompletionCertificate);
    linkarr.push(object.CPO);
    linkarr.push(object.RFQ);
    linkarr.push(object.SuppliersQ);
    linkarr.push(object.Cinvoice);
    linkarr.push(object.DebtAge);
    linkarr.push(object.EditorsAge);
    linkarr.push(object.PoCl);
    linkarr.push(object.MonthBankState);
    linkarr.push(object.BillofQuanties);
    linkarr.push(object.SLA);
    linkarr.push(object.CletterofAppointment);
    linkarr.push(object.ProjectImplentaionPlan);
    linkarr.push(object.CinvoicePaidbyPayMaster);
    linkarr.push(object.PaymentCert);
    linkarr.push(object.DebtandCred);

    linkarr.map((mainurl) => {
      if (mainurl.length !== 0) {
        axios({
          url: mainurl,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            mainurl.replace(
              " https://dev.northroom.co.za/api/spfund/static/",
              ""
            )
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    });
  }


  //modal
  const [exception, setException] = useState("");
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setException("");
    setVisible(false);
  };

  const handleComment = (object: any) => {
    console.log(object);
    ExceptionReport(object);
    setVisible(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Applications Verification"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "6%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={0} status="error">
              <Step
                title="Application Pack Verification"
                description="In Progress"
              />
              <Step
                title="Application Pack Verification"
                description="Compliance verification incomplete"
              />
              <Step
                title="Vetting"
                description="Awaiting client/site/supplier vetting"
              />
              <Step
                title="Verified Pack Submission"
                description="Verified Documents submitted to risk officer"
              />
            </Steps>
          </IonCol>
        </IonRow>
        {data.length === 0 ? (
          <IonItem lines="none" padding-top="20px" class="ion-text-center">
            <IonLabel>No projects found</IonLabel>
          </IonItem>
        ) : (
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Project Application ID:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                        </p>
                      }
                      description={"Project Status: " + dataobject.CurrentStatus}
                    />
                  </List.Item>

                  <List.Item>
                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            showModal();
                          }}
                          type="primary"
                        >
                          Mark for Exception Report
                        </Button>
                      </IonCol>

                      <IonCol></IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        {getSelect(dataobject)}
                      </IonCol>
                    </IonRow>
                    <Modal
                      title="Exception"
                      visible={visible}
                      onCancel={handleCancel}
                      width={500}
                      centered
                      onOk={() => {
                        handleComment(dataobject);
                      }}
                    >
                      <IonRow>
                        <IonCol>
                          <TextArea
                            value={exception}
                            rows={4}
                            onChange={(e) => {
                              setException(e.target.value);
                            }}
                            name="exception"
                            placeholder="exception comment"
                          />
                        </IonCol>
                      </IonRow>
                    </Modal>
                  </List.Item>
                </>
              ))
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ApplicationsOfficer;
