import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Upload,
  Tooltip,
  Empty,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import {
  DownloadOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message, Tag, Steps } from "antd";
import { DatePicker, Space, Button, Modal } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { ProjectStatus, UIStore } from "../store/UIStore";
const dateFormat = "YYYY/MM/DD";

const RiskAssessment: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState(s => s.CurrentProject);
  const [vote, setVote] = useState("Unvoted");
  const [data, setdata] = useState<any[]>([]);
  const { Step } = Steps;
  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const [comment, setComment] = useState("");

  const [recordOfDecision, setRecordOfDecision] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isModal2Visible, setIsModal2Visible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal2 = (myVote: any) => {
    setIsModal2Visible(true);
    setVote(myVote);
  };

  const handleOk2 = () => {
    setIsModal2Visible(false);
  };

  const handleCancel2 = () => {
    setIsModal2Visible(false);
  };

  const handleComment = (e: any) => {
    setComment(e.target.value);
  };

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const [globalObject, setGlobalObject] = useState({});

  async function ApproveProject(object: any) {


    if (object.CurrentStatus === ProjectStatus.AWAITINGFINALRISK) {
      setRecordOfDecision(vote + "-" + comment);
      object.RiskOfficerExceptioncomment = vote + "-" + comment;
      object.CurrentStatus = ProjectStatus.FMPROJECTVIABILITY;

      message
        .loading("Action in progress..", 3)
        .then(() => message.success("Marked as verified", 2));
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
            object
          )
          .then((response) => {
            axios
              .get(
                "https://dev.northroom.co.za/api/spfund/users/projectlistall/ERC"
              )
              .then(function (response) {
                console.log(response.data);

                setdata(response.data);
                // setRecordOfDecision(response.data.RECORDOFDECISION);
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              })
              .then(function () {
                // always executed
              });
          });
      } catch (error: any) {
        message.error("An error has occurred");
      }
    } else {
      setRecordOfDecision(vote + "-" + comment);
      object.RiskOfficerExceptioncomment = vote + "-" + comment;
      object.CurrentStatus = ProjectStatus.FMPROJECTVIABILITY;

      message
        .loading("Action in progress..", 3)
        .then(() => message.success("Marked as verified", 2));
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
            object
          )
          .then((response) => {
            axios
              .get(
                "https://dev.northroom.co.za/api/spfund/users/projectlistall/ERC"
              )
              .then(function (response) {
                console.log(response.data);

                setdata(response.data);
                // setRecordOfDecision(response.data.RECORDOFDECISION);
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              })
              .then(function () {
                // always executed
              });
          });
      } catch (error: any) {
        message.error("An error has occurred");
      }
    }

    console.log(object);
    handleOk2();
  }
  async function ExceptionReport(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/markverifiedTECrexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlistall/TEC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
              setRecordOfDecision(response.data.RECORDOFDECISION);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error has occurred");
    }
  }
  async function DownloadPack(object: any) { }

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return file.type === "image/jpeg" && file.size / 1024 / 1024 < 2
      ? true
      : Upload.LIST_IGNORE;
  }
  const FileUploadProps = {
    /*
    //Upload file
    name: "copyuseridentification",
    action: "https://dev.northroom.co.za/api/spfund/uploadimage/main",

    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setState((prevState) => ({
          ...prevState,
          ID: info.file.response.path,
        }));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    */
  };

  useIonViewWillEnter(() => {
    //https://dev.northroom.co.za/api/spfund/users/projectlistall/TEC
    axios
      .get("https://dev.northroom.co.za/api/spfund/users/projectlistall/ERC")
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
        setRecordOfDecision(response.data.RECORDOFDECISION);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  const handleSizeChange = (e: any) => {
    setVote(e.target.value);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="Risk Assessment" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol class="ion-padding" size="12">
            <Steps current={2} status="error">
              <Step title="Application Pack Submission" description="Complete" />
              <Step title="Vetting" description="Complete" />
              <Step
                title="Awaiting Preliminary Approval"
                description=""
              />
              <Step title="Vote on Peliminary Approval " description="Incomplete" />
            </Steps>
          </IonCol>
        </IonRow>
        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Project Application ID:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                        </p>
                      }
                      description={dataobject.CurrentStatus}
                    />

                    {/* <p>Registration with CIBD</p>
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} onChange={e => { handleChangecheckbox(e, "CIBD") }} />
                  <br></br>
                  <br></br>
                  {commentbox1 ? <TextArea placeholder="Provide a reason/Ask for help?" rows={4} /> : null} */}
                    {
                      //AWAITINGINITIALRISK
                    }
                    <Row style={{ paddingLeft: "1px" }}>
                      <div style={{ paddingLeft: "1vw" }}>
                        <Button
                          icon={<UploadOutlined />}
                          disabled={
                            dataobject.CurrentStatus === "INITIALRISK" ? false : true}
                          onClick={() => {
                            UIStore.update((s) => {
                              s.CurrentProject = dataobject;
                            });

                            props.history.push("/InitalRisk")
                          }}
                        >
                          Risk Assessment
                        </Button>
                      </div>
                    </Row>

                    <Row style={{ paddingLeft: "50px" }}>
                      <Col style={{ paddingRight: "50px", paddingTop: "1%" }}>
                        <div style={{ paddingBottom: "2%" }}>
                          <Tooltip
                            style={{
                              width: 70,
                              marginLeft: 70 * 4 + 24,
                            }}
                            placement="right"
                            title="Cast your vote before providing a recommendation."
                          >
                            <Button
                              type="primary"
                              onClick={showModal2}
                              style={{ width: "10vw" }}
                              disabled
                            >
                              Voting Comment
                            </Button>
                          </Tooltip>

                        </div>


                        <div>
                          <Tooltip
                            style={{
                              width: 70,
                              marginLeft: 70 * 4 + 24,
                            }}
                            placement="right"
                            title="Approval status of the project"
                          >
                            <Button
                              type="primary"
                              onClick={showModal}
                              style={{ width: "10vw" }}
                            >
                              Approval status
                            </Button>
                          </Tooltip>
                          <Modal
                            title="Approval status"
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                          >
                            <TextArea
                              value={recordOfDecision}
                              placeholder=""
                              rows={4}
                            />
                          </Modal>
                        </div>
                      </Col>
                      <Col style={{ paddingRight: "50px", paddingTop: "10px" }}>
                        <p>Vote on Document Agreement</p>
                        <Tooltip
                          style={{
                            width: 70,
                            marginLeft: 70 * 4 + 24,
                          }}
                          placement="right"
                          title="Voting Decision"
                        >
                          <Radio.Group value={vote} onChange={handleSizeChange}>
                            <Radio.Button value="Unvoted">Unvoted</Radio.Button>
                            <Radio.Button
                              onClick={() => {
                                showModal2("Approved");
                                setGlobalObject(dataobject)
                              }}
                              value="Approved"
                            >
                              Approve
                            </Radio.Button>
                            <Radio.Button
                              onClick={() => {
                                showModal2("Declined");
                                setGlobalObject(dataobject)
                              }}
                              value="Declined"
                            >
                              Decline
                            </Radio.Button>
                          </Radio.Group>
                        </Tooltip>
                      </Col>
                    </Row>
                  </List.Item>
                </>
              ))
            }
          />
        )}
        <Modal
          title="Provide Voting Comment"
          visible={isModal2Visible}
          onOk={() => {
            ApproveProject(globalObject);
          }}
          onCancel={handleCancel2}
        >
          <TextArea
            value={comment}
            placeholder=""
            rows={4}
            onChange={(e) => {
              handleComment(e);
            }}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default RiskAssessment;
