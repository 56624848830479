import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonCard,
  useIonViewDidEnter,
} from "@ionic/react";

import { useParams } from "react-router";
import { Descriptions, Row, Col, Select } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import moment from "moment";
import { Dropdown, Menu, message, InputNumber, Upload, Radio } from "antd";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import React, { useState } from "react";
import { PageHeader } from "antd";
import axios from "axios";
import { notification } from "antd";
import SignatureCanvas from "react-signature-canvas";
import { UIStore } from "../store/UIStore";
import { Content } from "antd/lib/layout/layout";
import { getDocsArray } from "../store/actions";
const dateFormat = "YYYY/MM/DD";
const { TextArea } = Input;

const PaymentInstruction: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const [value, setValue] = React.useState(1);

  const { name } = useParams<{ name: string }>();

  const [PaymentInstruction, setPaymentInstruction] = useState({
    Client: "",
    ProjectDescription: "",
    TotalFundingApproved: "",
    DrawdownRequired: "",
    SupplierName: "",
    AmountPayable: "",
    Bank: "",
    AccountNumber: "",
    PFAName: "",
    FMEName: "",
    CEName: "",
    DocRequestForPayment: "",
    DocDrawdown: "",
    DocSuppliersQ: "",
    DocSupplierConfirmation: "",
    PFACapacity: "",
    FMESignature: "",
    CECapacity: "",
    CESignature: "",
  });

  var sigCanvas: any = {};
  var sigCanvas1: any = {};
  var sigCanvas2: any = {};
  const options = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
  ];

  async function SubmitPaymentInst() {
    //setPaymentInstruction(CurrentProject.PaymentInstruction);
    console.log(CurrentProject.PaymentInstruction);

    if (user.UserType === "APPLICANT") {
      message
        .loading("Action in progress..", 5)
        .then(() => message.success("Submission Succeeded", 5));
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/projects/paymentinstructionsubmit/" +
            CurrentProject.id,
            CurrentProject.PaymentInstruction
          )
          .then((response) => {
            console.log(response)
            props.history.push("/Screening");
          });
      } catch (error: any) {
        message.error("An error occurred");
      }
    } else {

      message
        .loading("Action in progress..", 5)
        .then(() => message.success("Submission Succeeded", 5));
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/projects/paymentinstructionsubmitfinal/" +
            CurrentProject.id,
            CurrentProject.PaymentInstruction
          )
          .then((response) => {
            console.log(response)
            props.history.push("/Screening");
          });
      } catch (error: any) {
        message.error("An error occurred");
      }
    }
  }

  useIonViewDidEnter(() => {
    console.log(CurrentProject.PaymentInstruction)
  });
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  const handlePayChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setPaymentInstruction((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleReqChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setPaymentInstruction((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkFile = (file: any) => {
    if (file.length < 4) {
      return "Yes";
    } else {
      return "No";
    }
  };
  let allowed = false;
  let content = <></>;

  if (user.UserType === "FINANCEADMINSTRATOR") {
    allowed = true;
    content = <></>
  } else {
    content = <Button
      size={"large"}
      onClick={() => {
        SubmitPaymentInst();
      }}
      type="primary"
    >
      Submit
    </Button>
  }

  //select
  const { Option } = Select;

  const onDownloadChange = (val: any) => {
    console.log("Link!")
    window.open(val, "_blank");
  };

  function getSelect(object: any) {

    let content =
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select file to download"
        optionFilterProp="children"
        onChange={onDownloadChange}
        filterOption={(input, option) =>
          //@ts-ignore
          option.children.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {getDocsArray(object).map((item) => {

          return (
            <Option value={item.uri} key={item.name + object.id}>{item.name}</Option>
          );
        })}
      </Select>

    return content
  }
  //end select

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <PageHeader
            className="site-page-header"
            title="Payment Instruction"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonCard style={{ margin: "2vw" }}>
            <Descriptions
              className="ion-text-center"
              title="Payment Instruction Form"
            ></Descriptions>

            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong>Client</strong>
                </p>
                <Input
                  value={CurrentProject.Client}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.Client = e.target.value;
                    });
                  }}
                  name="Client"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Project Description</strong>
                </p>
                <Input
                  value={CurrentProject.LeadDescription}
                  type="text"
                  //   onChange={(e) => {
                  //       UIStore.update((s) => {
                  //           s.CurrentProject.Client = e.target.value;
                  //       });
                  //   }}
                  disabled={allowed}
                  name="ProjectDescription"
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Total Funding Approved</strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.TotalFundingApproved}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.TotalFundingApproved = e.target.value;
                    });
                  }}
                  name="TotalFundingApproved"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Drawdown Required</strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.DrawdownRequired}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.DrawdownRequired = e.target.value;
                    });
                  }}
                  name="DrawdownRequired"
                  disabled={allowed}
                />
              </IonCol>
            </Row>
            <Row></Row>
            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>Supplier Details</strong>
                </h6>
              </IonCol>
            </IonRow>
            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong>Supplier name </strong>
                </p>
                <Input
                  value={CurrentProject.SupplierName}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.SupplierName = e.target.value;
                    });
                  }}
                  name="SupplierName"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Amount Payable</strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.AmountPayable}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.AmountPayable = e.target.value;
                    });
                  }}
                  name="AccountNo"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Bank</strong>
                </p>
                <Input
                  value={CurrentProject.RequestforPayment.BankName}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.RequestforPayment.BankName = e.target.value;
                    });
                  }}
                  name="Bank"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Account number</strong>
                </p>
                <Input
                  value={CurrentProject.RequestforPayment.AccountNo}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.RequestforPayment.AccountNo = e.target.value;
                    });
                  }}
                  name="AccountNO"
                  disabled={allowed}
                />
              </IonCol>
            </Row>

            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>Authorization</strong>
                </h6>
              </IonCol>
            </IonRow>
            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong> Name </strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.PFAName}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.PFAName = e.target.value;
                    });
                  }}
                  name="PFAName"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Capacity</strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.PFACapacity}
                  type="text"
                  disabled={allowed}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.PFACapacity = e.target.value;
                    });
                  }}
                  name="PFACapacity"

                  placeholder="Project Finance Administrator (PFA)"
                />
              </IonCol>
            </Row>
            <IonRow>
              <IonCol>
                <p className="ion-text-center">
                  <strong>Signature: </strong>
                </p>
                {CurrentProject.PaymentInstruction.PFAsignature.length > 5 ? (
                  <img src={CurrentProject.PaymentInstruction.PFAsignature} alt="signature" />
                ) : (
                  <SignatureCanvas
                    ref={(ref) => {
                      sigCanvas = ref;
                    }}
                    backgroundColor="#a9a9a9"
                    penColor="black"
                    canvasProps={{
                      width: 1300,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    onEnd={() => {
                      let signature = sigCanvas.toDataURL();
                      UIStore.update((s) => {
                        s.CurrentProject.PaymentInstruction.PFAsignature =
                          signature;
                      });
                    }}
                  />)}
              </IonCol>
            </IonRow>
            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong> Name </strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.FMEName}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.FMEName = e.target.value;
                    });
                  }}
                  name="FMEName"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Capacity</strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.FMRCapacity}
                  type="text"
                  disabled={allowed}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.FMRCapacity = e.target.value;
                    });
                  }}
                  name="FMECapacity"
                  placeholder="Fund Management Executive (FME)"

                />
              </IonCol>
            </Row>
            <IonRow>
              <IonCol>
                <p className="ion-text-center">
                  <strong>Signature: </strong>
                </p>
                {CurrentProject.PaymentInstruction.FMRSignature.length > 5 ? (
                  <img src={CurrentProject.PaymentInstruction.FMRSignature} alt="signature" />
                ) : (
                  <SignatureCanvas
                    ref={(ref) => {
                      sigCanvas1 = ref;
                    }}
                    backgroundColor="#a9a9a9"
                    penColor="black"
                    canvasProps={{
                      width: 1300,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    onEnd={() => {
                      let signature = sigCanvas1.toDataURL();
                      UIStore.update((s) => {
                        s.CurrentProject.PaymentInstruction.FMRSignature =
                          signature;
                      });
                    }}
                  />)}
              </IonCol>
            </IonRow>
            <Row>
              <IonCol class="ion-padding">
                <p>
                  <strong> Name </strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.CEName}
                  type="text"
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.CEName = e.target.value;
                    });
                  }}
                  name="CEName"
                  disabled={allowed}
                />
              </IonCol>
              <IonCol class="ion-padding">
                <p>
                  <strong>Capacity</strong>
                </p>
                <Input
                  value={CurrentProject.PaymentInstruction.CECapacity}
                  type="text"
                  disabled={allowed}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.PaymentInstruction.CECapacity = e.target.value;
                    });
                  }}
                  name="CECapacity"
                  placeholder="Chief Executive (CE)"
                />
              </IonCol>
            </Row>
            <IonRow>
              <IonCol>
                <p className="ion-text-center">
                  <strong>Signature: </strong>
                </p>   {CurrentProject.PaymentInstruction.CESignature.length > 5 ? (
                  <img src={CurrentProject.PaymentInstruction.CESignature} alt="signature" />
                ) : (
                  <SignatureCanvas
                    ref={(ref) => {
                      sigCanvas2 = ref;
                    }}
                    backgroundColor="#a9a9a9"
                    penColor="black"
                    canvasProps={{
                      width: 1300,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    onEnd={() => {
                      let signature = sigCanvas2.toDataURL();
                      UIStore.update((s) => {
                        s.CurrentProject.PaymentInstruction.CESignature =
                          signature;
                      });
                    }}
                  />)}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <h6>
                  <strong>Accompanying Documents Checklist</strong>
                </h6>
                {getSelect(CurrentProject)}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <p>Have the below listed documents been attached?</p>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>Request For Payment </strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Radio.Group
                    options={options}
                    value={checkFile(CurrentProject.RequestforPayment.BankName)}
                    // onChange={() => {
                    //   UIStore.update((s) => {});
                    // }}
                    optionType="button"
                    buttonStyle="solid"
                    disabled={allowed}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>Drawdown Schedule</strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Radio.Group
                    options={options}
                    value={checkFile(
                      CurrentProject.RequestforPayment.AccountNo
                    )}
                    // onChange={() => {
                    //   UIStore.update((s) => {checkFile(CurrentProject.RequestforPayment.BankName)});
                    // }}
                    optionType="button"
                    buttonStyle="solid"
                    disabled={allowed}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>Suppliers Quotation</strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Radio.Group
                    options={options}
                    value={checkFile(CurrentProject.SuppliersQ)}
                    // onChange={() => {
                    //   UIStore.update((s) => {});
                    // }}
                    optionType="button"
                    buttonStyle="solid"
                    disabled={allowed}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center">
              <IonCol class="ion-padding">
                <p>
                  <strong>Supplier Bank Confirmation Letter</strong>
                </p>
              </IonCol>
              <IonCol class="ion-padding">
                <div>
                  <Radio.Group
                    options={options}
                    value={"No"}
                    // onChange={() => {
                    //   UIStore.update((s) => {});
                    // }}
                    optionType="button"
                    disabled={allowed}
                    buttonStyle="solid"
                  />
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                className="ion-text-center"
                style={{ paddingBottom: "2vw" }}
              >
                {content}
              </IonCol>
            </IonRow>
          </IonCard>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default PaymentInstruction;
