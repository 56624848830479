import React,{ useEffect } from "react";
import { IonContent, IonPage, IonRow, IonCol } from "@ionic/react";
import { Card, Input } from "antd";
import Luckysheet from "../components/luckysheet";
import "./LoginPage.css";


const TestingLucky: React.FC = () => {

  return (
    <div>
      <Luckysheet />
    </div>
  );
};

export default TestingLucky;
