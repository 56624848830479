import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Tooltip,
  Select,
  Empty,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import {
  PlusOutlined,
  ProjectOutlined,
  ReloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { setcurrentproject } from "../store/actions";
import { UIStore } from "../store/UIStore";

const Leads: React.FC = (props: any) => {
  const { Step } = Steps;
  const [LeadName, setLeadName] = useState("");
  const [LeadDescription, setLeadDescription] = useState("");
  const [ProjectValue, setProjectValue] = useState("");
  const [SelectedUser, setSelectedUser] = useState({});
  const [Users, setUsers] = useState<any[]>([]);
  const [projectmanagers, setProjectManagers] = useState<any[]>([]);
  const [data, setdata] = useState<any[]>([]);
  const createText = (
    <span>Create a project, if you do not have one created below.</span>
  );
  const loadText = <span>Sends this record to Projects table</span>;
  const loadText2 = <span>Assigns a Project manager to the selected Lead</span>;
  const buttonWidth = 70;
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  useIonViewDidEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/projects/projectslead")
      .then(function (response) {
        //23 items
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    axios
      .get("https://dev.northroom.co.za/api/spfund/users/projectmanagers/all")
      .then(function (response) {
        setProjectManagers(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    axios
      .get("https://dev.northroom.co.za/api/spfund/users")
      .then(function (response) {
        const applicants = response.data.filter(
          (user: { UserType: string }) => user.UserType === "APPLICANT"
        );
        setUsers(applicants);
        console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Lead Name");
  const [modalText2, setModalText2] = React.useState("Lead Description");

  const showModal = () => {
    setVisible(true);
  };

  const CreateProject = async () => {
    setModalText("Processing Request");
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));

    try {
      console.log(SelectedUser);
      const request = await axios
        .post("https://dev.northroom.co.za/api/spfund/users/createproject", {
          //@ts-ignore
          userid: SelectedUser.id,
          LeadName: LeadName,
          LeadDescription: LeadDescription,
          ProjectValue: ProjectValue,
        })
        .then(async (response) => {
          message.success(response.data);
          console.log(response);

          // props.history.push("/NewProject");

          // UIStore.update((s) => {
          //     s.CurrentProject = InitialProject;
          // });

          axios
            .get("https://dev.northroom.co.za/api/spfund/projects/projectslead")
            .then(function (response) {
              //23 items

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error occurred");
      console.log(error);
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const { Option } = Select;
  const options: {} | any | undefined = [];
  const Useroptions: {} | any | undefined = [];

  projectmanagers.forEach((data) => {
    options.push(
      <Option
        key={data.id}
        label={data.FullName}
        value={data.id}
        title={data.FullName}
      >
        {data.FullName}
      </Option>
    );
  });

  Users.forEach((data) => {
    Useroptions.push(
      <Option
        key={data.id}
        label={data.FullName}
        value={data.id}
        title={data.FullName}
      >
        {data.FullName}
      </Option>
    );
  });

  function handleChangeSelect(value: any, CurrentProject: any) {
    UIStore.update((s) => {
      s.CurrentProject.ProjectManagerName = value.label;
    });
    SaveRole(CurrentProject, value);
  }

  async function SaveRole(record: any, projectManager: any) {

    let index = projectmanagers.findIndex((x) => x.id === projectManager.value);
    let SelectedProjectManager = projectmanagers[index];
    console.log(projectManager)
    console.log(projectmanagers);
    let tempGroup = {
      SelectedProject: record,
      ProjectManager: SelectedProjectManager,
    };

    message
      .loading("Action in progress..", 4)
      .then(() => message.success("Project manager successfully assigned", 5));

    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate/setPM",
          tempGroup
        )
        .then((response) => {
          console.log(response);
        });
    } catch (error: any) {
      console.log(error);
    }

    console.log(tempGroup);
  }

  const deleteProject = async (ID: any) => {
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Lead has been deleted", 5));

    try {
      await axios
        .post("https://dev.northroom.co.za/api/spfund/projects/delete/", {
          id: ID,
        })
        .then((response) => {
          axios
            .get("https://dev.northroom.co.za/api/spfund/projects/projectslead")
            .then(function (response) {
              //23 items

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
          message.success(response.data);
          console.log(response);
        });
    } catch (error: any) {
      message.error("An error occurred");
    }
    console.log(ID);
  };

  const markAsOngoing = async (ID: any) => {
    message
      .loading("Action in progress..", 5)
      .then(() =>
        message.success(
          "Lead has been generated and populated in the relevant applicants projects page",
          5
        )
      );

    try {
      const request = await axios
        .post("https://dev.northroom.co.za/api/spfund/project/markongoing", {
          id: ID,
        })
        .then((response) => {
          axios
            .get("https://dev.northroom.co.za/api/spfund/projects/projectslead")
            .then(function (response) {
              //23 items

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
          message.success(response.data);
          console.log(response);
        });
    } catch (error: any) {
      message.error("An error occurred");
    }
  };

  const handleChangeUserSelect = (value: any) => {
    let index = Users.findIndex((x) => x.id === value.value);
    let SelectedUsers = Users[index];
    setSelectedUser(SelectedUsers);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="Leads" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol class="ion-padding" size="12">
            <Steps current={0} status="error">
              <Step title="Assign Project manager" description="In Progress" />
              <Step
                title="Submission of initial documentation"
                description="No current submissions"
              />
              <Step
                title="Type of application"
                description="No current selection"
              />
              <Step
                title="Submit proposal"
                description="No current submissions"
              />
            </Steps>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <Card>
              <Tooltip
                style={{ width: buttonWidth, marginLeft: buttonWidth * 4 + 24 }}
                placement="right"
                title={createText}
              >
                <Button
                  type="primary"
                  onClick={showModal}
                  icon={<PlusOutlined />}
                >
                  Create New Lead
                </Button>
              </Tooltip>
              <Modal
                title="New Lead"
                visible={visible}
                onOk={CreateProject}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
              >
                <p>{modalText}</p>
                <IonRow>
                  <Input
                    value={LeadName}
                    size="large"
                    placeholder=""
                    name={"ProjectName"}
                    onChange={(e) => {
                      setLeadName(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                </IonRow>
                <br></br>
                <p>{modalText2}</p>
                <IonRow>
                  <Input
                    value={LeadDescription}
                    size="large"
                    placeholder=""
                    name={"ProjectDescription"}
                    onChange={(e) => {
                      setLeadDescription(e.target.value);
                    }}
                    prefix={<ProjectOutlined />}
                  />
                </IonRow>
                <br></br>
                <p>Project Value</p>
                <IonRow>
                  <Input
                    value={ProjectValue}
                    size="large"
                    placeholder=""
                    name={"ProjectValue"}
                    onChange={(e) => {
                      setProjectValue(e.target.value);
                    }}
                    prefix={"R"}
                  />
                </IonRow>
                <br></br>
                <p>Assign Applicant</p>
                <IonRow>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Individual"
                    labelInValue
                    onChange={(value) => {
                      handleChangeUserSelect(value);
                    }}
                    optionLabelProp="label"
                  >
                    {Useroptions}
                  </Select>
                </IonRow>
                <br></br>
                <br></br>
                {/* <p>{modalText2}</p>
  
                  <Input 
                  size="large" 
                  placeholder="" 
                  prefix={<UserOutlined />} /> */}
              </Modal>
            </Card>
          </IonCol>
        </IonRow>

        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[{}]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Lead Name:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                        </p>
                      }
                    // description="Application in Progress"
                    />
                    {/* <IonCol>
                                            <Tag color="geekblue">
                                                Project Stage: {dataobject.CurrentStatus}
                                            </Tag>
                                        </IonCol> */}

                    <IonCol>
                      <div style={{ paddingBottom: "2%", paddingLeft: "65%" }}>
                        <Tooltip
                          style={{
                            width: buttonWidth,
                            marginLeft: buttonWidth * 4 + 24,
                          }}
                          placement="right"
                          title={loadText}
                        >
                          <Button
                            style={{ width: "10vw" }}
                            onClick={() => {
                              markAsOngoing(dataobject.id);
                            }}
                            type="primary"
                          // icon={<ReloadOutlined />}
                          >
                            Mark as ongoing project
                          </Button>
                        </Tooltip>
                      </div>
                    </IonCol>

                    <IonCol>
                      <div style={{ paddingLeft: "30%" }}>
                        <p>Assign project manager</p>
                        <Tooltip
                          style={{
                            width: buttonWidth,
                            marginLeft: buttonWidth * 4 + 24,
                          }}
                          placement="right"
                          title={loadText2}
                        >
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select Individual"
                            labelInValue
                            //@ts-ignore
                            defaultValue={{
                              value: dataobject.ProjectManagerName,
                            }}
                            onChange={(value) => {
                              handleChangeSelect(value, dataobject);
                            }}
                            optionLabelProp="label"
                          >
                            {options}
                          </Select>
                        </Tooltip>
                      </div>
                    </IonCol>

                    <IonCol>
                      <div style={{ paddingBottom: "2%", marginLeft: "40%" }}>
                        <Tooltip
                          style={{
                            width: buttonWidth,
                            marginLeft: buttonWidth * 4 + 24,
                          }}
                          placement="right"
                          title={"Deleted the selected Project"}
                        >
                          <Button
                            style={{ width: "10vw" }}
                            onClick={() => {
                              deleteProject(dataobject.id);
                            }}
                            type="primary"
                            danger
                          >
                            Delete
                          </Button>
                        </Tooltip>
                      </div>
                    </IonCol>
                  </List.Item>
                </>
              ))
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Leads;
