import React, { useEffect } from "react";
const Luckysheet = () => {
  useEffect(() => {
    const creator = "";

    if (window) {
      const name = "Tier_2_Schedule_Of_Evidence_.xlsx";

      //@ts-ignore
      const luckysheet = window["luckysheet"];
      luckysheet.create({
        container: "luckysheet",
        showinfobar: false,
        data: [
          {
            name: "1. Long Term Jobs",
            order: 0,
          },
          {
            name: "2. Short Term Jobs",
            order: 1,
          },
        ],
        title: name,
        userInfo: creator,
      });
    }
  }, []);

  const luckyCss = {
    margin: "0px",
    padding: "0px",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0px",
    top: "0px",
  };
  //@ts-ignore
  return <div id="luckysheet" style={luckyCss}></div>;
};

export default Luckysheet;
