import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { checklogin } from "./store/actions";
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu2 from './components/Menu';

import ApplicationForm from './pages/ApplicationForm';
import BeneficiaryContact from './pages/BeneficiaryContact';
// import MonthlyProgressReport from './pages/MonthlyProgressReport';
import ClientBrief from './pages/ClientBrief';
import ProjectBrief from './pages/ProjectBrief';
import BaselineReport from './pages/BaselineReport';
import JointVentureAgreement from './pages/JointVentureAgreement';
import ComplienceChecklist from './pages/ComplienceChecklist';
import CreditAgreement from './pages/CreditAgreement';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import ComplienceOfficer from './pages/ComplienceOfficer';
import EligibilityCriteria from './pages/EligibilityCriteria';
import TECVoting from './pages/TECVoting';
import ProjectManager from './pages/ProjectManager';
import ApplicationsOfficer from './pages/ApplicationsOfficer';
import TDRiskOfficer from './pages/TDRiskOfficer';
import ComplianceView from './pages/ComplianceView';
import TypeOfApplication from './pages/TypeOfApplication';
import PMAssign from './pages/PMAssign';
import CICApprovalStatus from './pages/CICApprovalStatus';
import DeclarationPage from './pages/DeclarationPage';
import Projects from './pages/Projects';
import AssignRoles from './pages/AssignRoles';
import HelpPage  from "./pages/HelpPage";
import Addendums from "./pages/Addendums";
import CicStatus  from "./pages/CicStatus";
import Leads from "./pages/Leads";
import BankingDocs from "./pages/BankingDocs";
import Rulings from './pages/Rulings';
import Screening from "./pages/Screening";
import Proposal from "./pages/Proposal";
import Vetting from "./pages/Vetting";
import PaymentInstruction from "./pages/PaymentInstruction";
import RFP from "./pages/RFP";
import FinalRiskAssesment from "./pages/FinalRiskAssesment";
import FinanceDash from "./pages/FinanceDash";
import RiskMatrix from './pages/RiskMatrix';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import RiskAssessment from './pages/RiskAssessment';
import ProposalForm from './pages/ProposalForm';
import InitalRiskAssessment from './pages/InitalRiskAssessment';
import testingLucky from './pages/testingLucky';







const Loginroutes: React.FC = (props) => {
  return (
    <IonApp>
      <IonReactRouter>
      <Route path="/" exact={true}>
              <Redirect to="/Login" />
        </Route>
        <Route path="/Login" exact={true} component={LoginPage} />

        <Route path="/RegistrationPage" component={RegistrationPage} />

      </IonReactRouter>
    </IonApp>
  );
};

const Internalroutes: React.FC = (props) => {
  const user = JSON.parse(localStorage.getItem("userobject")||'{}');
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu2 />
          <IonRouterOutlet id="main">
          <Route path="/" exact={true} render={() => {
              console.log(checklogin());

              if(user.UserType === "APPLICANT"){

                return  <HelpPage/>;
              }
             
             

              
            }} />

            <Route path="/ApplicationForm" exact={true} component={ApplicationForm} />
            <Route path="/JointVentureAgreement" exact={true} component={JointVentureAgreement} />
            <Route path="/BaselineReport" exact={true} component={BaselineReport} />
            {/* <Route path="/MonthlyProgressReport" exact={true} component={MonthlyProgressReport} /> */}
            <Route path="/ClientBrief" exact={true} component={ClientBrief} />
            <Route path="/ProjectBrief" exact={true} component={ProjectBrief} />
            <Route path="/BeneficiaryContact" exact={true} component={BeneficiaryContact} />
            <Route path="/ComplienceChecklist" exact={true} component={ComplienceChecklist} />
            <Route path="/CreditAgreement" exact={true} component={CreditAgreement} />
            <Route path="/EligibilityCriteria" exact={true} component={EligibilityCriteria} />
            <Route path="/TECVoting" exact={true} component={TECVoting} />
            <Route path="/ComplienceOfficer" exact={true} component={ComplienceOfficer} />
            <Route path="/TypeOfApplication" exact={true} component={TypeOfApplication} />
            <Route path="/ProjectManager" exact={true} component={ProjectManager} />
            <Route path="/ApplicationsOfficer" exact={true} component={ApplicationsOfficer} />
            <Route path="/TDRiskOfficer" exact={true} component={TDRiskOfficer} />
            <Route path="/ComplianceView" exact={true} component={ComplianceView} />
            <Route path="/PMAssign" exact={true} component={PMAssign} />
            <Route path="/CICApprovalStatus" exact={true} component={CICApprovalStatus} />
            <Route path="/DeclarationPage" exact={true} component={DeclarationPage} />
            <Route path="/Projects" exact={true} component={Projects} />
            <Route path="/AssignRoles" exact={true} component={AssignRoles} />
            <Route path="/HelpPage" exact={true} component={HelpPage} />
            <Route path="/CicStatus" exact={true} component={CicStatus} />
            <Route path="/Addendums" exact={true} component={Addendums} />
            <Route path="/Leads" exact={true} component={Leads} />
            <Route path="/BankingDocs" exact={true} component={BankingDocs} />
            <Route path="/Rulings" exact={true} component={Rulings} />
            <Route path="/Screening" exact={true} component={Screening} />
            <Route path="/RiskAssessment" exact={true} component={RiskAssessment} />
            <Route path="/Proposal" exact={true} component={Proposal} />
            <Route path="/proposalForm" exact={true} component={ProposalForm}/>
            <Route path="/Vetting" exact={true} component={Vetting} />
            <Route path="/InitalRisk" exact={true} component={InitalRiskAssessment}/>
            <Route path="/PaymentInstruction" exact={true} component={PaymentInstruction}/>
            <Route path="/RFP" exact={true} component={RFP}/>
            <Route path="/FinalRiskAssesment" exact={true} component={FinalRiskAssesment}/>
            <Route path="/FinanceDash" exact={true} component={FinanceDash}/>
            <Route path="/RiskMatrix" exact={true} component={RiskMatrix} />
            <Route path="/JobFundReport" exact={true} component={testingLucky} />
          </IonRouterOutlet>
        </IonSplitPane>

      </IonReactRouter>
    </IonApp>
  );
};
const App: React.FC = (props) => {



  return (
    <IonApp>
      <IonReactRouter >
  
        <IonRouterOutlet id="main">
        {/* <Route path="/RegistrationPage" component={RegistrationPage} />
        <Route path="/LoginPage" exact={true} component={LoginPage} />
          <Route
            exact
            path="/"
            render={props => {
              console.log(checklogin());

              return checklogin() ? < Internalroutes /> : <   Loginroutes />;
            }}
          /> */}
        {checklogin() ? < Internalroutes />: <Loginroutes />}

        </IonRouterOutlet>

      </IonReactRouter>
    </IonApp>
  );
};

export default App;