import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import { Logout } from "../store/actions";
import "./Menu.css";
import { Avatar } from "antd";
import logo from "../pages/images/image6.jpg";
import {
  AntDesignOutlined,
  ProfileOutlined,
  ScanOutlined,
  RotateRightOutlined,
  SafetyCertificateOutlined,
  ScheduleOutlined,
  TabletOutlined,
  SubnodeOutlined,
  QrcodeOutlined,
  MessageOutlined,
  FileAddOutlined,
  CrownOutlined,
  PlusSquareOutlined,
  DatabaseOutlined,
  SolutionOutlined,
  FullscreenExitOutlined,
  SnippetsOutlined,
  CreditCardOutlined,
  UsergroupAddOutlined,
  CheckSquareOutlined,
  EditOutlined,
  ProjectOutlined,
  UserOutlined,
  LogoutOutlined,
  ShrinkOutlined,
  DeliveredProcedureOutlined,
  FundProjectionScreenOutlined,
  AppstoreAddOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  LikeOutlined,
  LineOutlined,
  IdcardOutlined,
  PlayCircleOutlined,
  FormOutlined,
  FontColorsOutlined,
  PicCenterOutlined,
} from "@ant-design/icons";
import React from "react";
interface AppPage {
  url: string;
  iosIcon: any;
  mdIcon: any;
  title: string;
}
const user = JSON.parse(localStorage.getItem("userobject") || "{}");
const project = JSON.parse(localStorage.getItem("CurrentProject") || "{}");
const appPages: AppPage[] = [
  {
    title: "My Projects ",
    url: "/Projects",
    iosIcon: <ProfileOutlined style={{ fontSize: 80, padding: "10px" }} />,
    mdIcon: <ProfileOutlined style={{ fontSize: 80, padding: "10px" }} />,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  console.log(user.UserType);

  return (
    <IonMenu contentId="main" type="overlay" >
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader lines="full">
            <IonRow style={{ marginBottom: "45px" }}>
              <IonCol>
                <div></div>
              </IonCol>

              <IonCol>
                <img src={logo} alt="logo" />
              </IonCol>

              <IonCol>
                <div></div>
              </IonCol>
            </IonRow>
          </IonListHeader>

          {appPages.map((appPage, index) => {
            // if(user.UserType != "COMPLIANCEOFFICER" && appPage.url != "/ComplienceOfficer" ){

            //   return (
            //     <IonMenuToggle    key={index} autoHide={false}>
            //       <IonItem  className={location.pathname === appPage.url ? 'selected' : ''}   routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>

            //         {appPage.iosIcon}
            //         <IonLabel >{appPage.title}</IonLabel>

            //       </IonItem>
            //     </IonMenuToggle>
            //   );

            // }
            if (user.UserType === "PROJECTMANAGER") {
              appPages.length = 1;

              return (
                <>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ProjectManager"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/ProjectManager"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ScanOutlined style={{ fontSize: 40, padding: "10px" }} />
                      <IonLabel>Project Manager</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/BaselineReport"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/BaselineReport"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Baseline Report</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Addendums" ? "selected" : ""
                      }
                      routerLink={"/Addendums"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Addendums</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/CicStatus" ? "selected" : ""
                      }
                      routerLink={"/CicStatus"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ScheduleOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>CIC Status</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Leads" ? "selected" : ""
                      }
                      routerLink={"/Leads"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ScheduleOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Leads</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </>
              );
            }
            if (user.UserType === "CONTRACTSOFFICER") {
              appPages.length = 1;

              return (
                <>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/CreditAgreement"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/CreditAgreement"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProjectOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Credit Agreement</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/JointVentureAgreement"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/JointVentureAgreement"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Joint Venture Agreement</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </>
              );
            }
            if (user.UserType === "RISKOFFICER") {
              appPages.length = 1;

              return (
                <IonMenuToggle key={98} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === "/RiskAssessment" ? "selected" : ""
                    }
                    routerLink={"/RiskAssessment"}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <RotateRightOutlined
                      style={{ fontSize: 40, padding: "10px" }}
                    />
                    <IonLabel>Risk Assessment</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            }
            if (user.UserType === "TECHNICALDIRECTOR") {
              appPages.length = 1;

              return (
                <>
                  {/* <IonMenuToggle key={98} autoHide={false}>
                    <IonItem className={location.pathname === "/TDRiskOfficer" ? 'selected' : ''} routerLink={"/TDRiskOfficer"} routerDirection="none" lines="none" detail={false}>


                      <ProjectOutlined style={{ fontSize: 40, padding: "10px" }} />
                      <IonLabel >Technical/Risk verify application</IonLabel>

                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem className={location.pathname === "/PMAssign" ? 'selected' : ''} routerLink={"/PMAssign"} routerDirection="none" lines="none" detail={false}>


                      <ProfileOutlined style={{ fontSize: 40, padding: "10px" }} />
                      <IonLabel >Assign Project Manager</IonLabel>

                    </IonItem>
                  </IonMenuToggle> */}
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/AssignRoles" ? "selected" : ""
                      }
                      routerLink={"/AssignRoles"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <SolutionOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Assign Roles</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </>
              );
            }
            if (user.UserType === "APPLICATIONOFFICER") {
              appPages.length = 1;

              return (
                <IonMenuToggle key={98} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === "/ApplicationsOfficer"
                        ? "selected"
                        : ""
                    }
                    routerLink={"/ApplicationsOfficer"}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <UserSwitchOutlined
                      style={{ fontSize: 40, padding: "10px" }}
                    />
                    <IonLabel>Applications Dashboard</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            }
            if (user.UserType === "APPLICANT") {
              let status = project.CurrentStatus;
              appPages.length = 1;
              if (status === "ELIGIBILITY") {
                return (
                  <>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/HelpPage" ? "selected" : ""
                        }
                        routerLink={"/HelpPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <MessageOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Help Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/Projects" ? "selected" : ""
                        }
                        routerLink={"/Projects"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <ProfileOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>My Projects</IonLabel>
                      </IonItem>
                    </IonMenuToggle>

                    {/* <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/EligibilityCriteria"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/EligibilityCriteria"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <UserSwitchOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Eligibility Criteria</IonLabel>
                      </IonItem>
                    </IonMenuToggle> */}

                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ApplicationForm"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ApplicationForm"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <DatabaseOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Application Form</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ComplienceChecklist"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ComplienceChecklist"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <CheckSquareOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Supporting Documents</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/DeclarationPage"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/DeclarationPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <LineOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Declaration Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  </>
                );
              }

              if (status === "APPLICATION") {
                return (
                  <>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/HelpPage" ? "selected" : ""
                        }
                        routerLink={"/HelpPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <MessageOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Help Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/Projects" ? "selected" : ""
                        }
                        routerLink={"/Projects"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <ProfileOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>My Projects</IonLabel>
                      </IonItem>
                    </IonMenuToggle>

                    {/* <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/EligibilityCriteria"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/EligibilityCriteria"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <UserSwitchOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Eligibility Criteria</IonLabel>
                      </IonItem>
                    </IonMenuToggle> */}
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ApplicationForm"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ApplicationForm"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <DatabaseOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Application Form</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ComplienceChecklist"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ComplienceChecklist"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <CheckSquareOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Supporting Documents</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/DeclarationPage"
                            ? "selected"
                            : ""
                        }
                        disabled
                        routerLink={"/DeclarationPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <LineOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Declaration Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  </>
                );
              }

              if (status === "DECLARATION") {
                return (
                  <>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/HelpPage" ? "selected" : ""
                        }
                        routerLink={"/HelpPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <MessageOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Help Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/Projects" ? "selected" : ""
                        }
                        routerLink={"/Projects"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <ProfileOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>My Projects</IonLabel>
                      </IonItem>
                    </IonMenuToggle>

                    {/* <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/EligibilityCriteria"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/EligibilityCriteria"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <UserSwitchOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Eligibility Criteria</IonLabel>
                      </IonItem>
                    </IonMenuToggle> */}
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ApplicationForm"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ApplicationForm"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <DatabaseOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Application Form</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ComplienceChecklist"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ComplienceChecklist"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <CheckSquareOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Supporting Documents</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/DeclarationPage"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/DeclarationPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <LineOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Declaration Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  </>
                );
              }
              if (status === null || status === undefined) {
                return (
                  <>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/HelpPage" ? "selected" : ""
                        }
                        routerLink={"/HelpPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <MessageOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Help Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/Projects" ? "selected" : ""
                        }
                        routerLink={"/Projects"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <ProfileOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>My Projects</IonLabel>
                      </IonItem>
                    </IonMenuToggle>

                    {/* <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/EligibilityCriteria"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/EligibilityCriteria"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <UserSwitchOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Eligibility Criteria</IonLabel>
                      </IonItem>
                    </IonMenuToggle> */}
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ApplicationForm"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ApplicationForm"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <DatabaseOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Application Form</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/ComplienceChecklist"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/ComplienceChecklist"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <CheckSquareOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Supporting Documents</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key={98} autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === "/DeclarationPage"
                            ? "selected"
                            : ""
                        }
                        routerLink={"/DeclarationPage"}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <LineOutlined
                          style={{ fontSize: 40, padding: "10px" }}
                        />
                        <IonLabel>Declaration Page</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  </>
                );
              }
            }
            if (user.UserType === "TECMEMBER") {
              // appPages.length = 1;

              return (
                <>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/TECVoting" ? "selected" : ""
                      }
                      routerLink={"/TECVoting"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <SafetyCertificateOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>TEC Voting Dashboard</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/CICApprovalStatus"  ? 'selected' : ''}   routerLink={"/CICApprovalStatus"}  routerDirection="none" lines="none" detail={false}>

                <PlayCircleOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >CIC Approval status</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                </>
              );
            }
            if (user.UserType === "COMPLIANCEOFFICER") {
              // appPages.length = 1;

              return (
                <IonMenuToggle key={98} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === "/ComplianceView" ? "selected" : ""
                    }
                    routerLink={"/ComplianceView"}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <PlayCircleOutlined
                      style={{ fontSize: 40, padding: "10px" }}
                    />
                    <IonLabel>Compliance Dashboard</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            }
            if (user.UserType === "SUPERADMIN") {
              appPages.length = 1;

              return (
                <>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Addendums" ? "selected" : ""
                      }
                      routerLink={"/Addendums"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Addendums </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/FinanceDash" ? "selected" : ""
                      }
                      routerLink={"/FinanceDash"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Finance Admin </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/CicStatus" ? "selected" : ""
                      }
                      routerLink={"/CicStatus"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Cic Status </IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ClientBrief" ? "selected" : ""
                      }
                      routerLink={"/ClientBrief"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Client Brief</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ProjectBrief" ? "selected" : ""
                      }
                      routerLink={"/ProjectBrief"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Project Brief</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/PaymentInstruction"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/PaymentInstruction"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Payment Instruction</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  {/* <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Proposal" ? "selected" : ""
                      }
                      routerLink={"/Proposal"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Proposal </IonLabel>
                    </IonItem>
                  </IonMenuToggle> */}
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Vetting" ? "selected" : ""
                      }
                      routerLink={"/Vetting"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Vetting </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/HelpPage" ? "selected" : ""
                      }
                      routerLink={"/HelpPage"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Help Page </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Projects" ? "selected" : ""
                      }
                      routerLink={"/Projects"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>My Projects</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Leads" ? "selected" : ""
                      }
                      routerLink={"/Leads"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Leads</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/EligibilityCriteria"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/EligibilityCriteria"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Eligibility Criteria</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ApplicationForm"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/ApplicationForm"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <SolutionOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Application Form</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ComplienceChecklist"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/ComplienceChecklist"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <CheckSquareOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Supporting Documents</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/DeclarationPage"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/DeclarationPage"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <LineOutlined style={{ fontSize: 40, padding: "10px" }} />
                      <IonLabel>Declaration Page</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ProjectManager"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/ProjectManager"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <TeamOutlined style={{ fontSize: 40, padding: "10px" }} />
                      <IonLabel>Project Manager</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/BaselineReport"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/BaselineReport"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <RotateRightOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Baseline Report</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/CreditAgreement"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/CreditAgreement"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProjectOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Credit Agreement</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/JointVentureAgreement"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/JointVentureAgreement"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Joint Venture Agreement</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/TDRiskOfficer" ? "selected" : ""
                      }
                      routerLink={"/TDRiskOfficer"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <RotateRightOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Technical/Risk verify application</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/PMAssign" ? "selected" : ""
                      }
                      routerLink={"/PMAssign"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Assign Project Manager</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/AssignRoles" ? "selected" : ""
                      }
                      routerLink={"/AssignRoles"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <SolutionOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Assign Roles</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ApplicationsOfficer"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/ApplicationsOfficer"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <UserSwitchOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Applications Dashboard</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/TECVoting" ? "selected" : ""
                      }
                      routerLink={"/TECVoting"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <LogoutOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>TEC Recommendations </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/ComplianceView"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/ComplianceView"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <PlayCircleOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Compliance Dashboard</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/TypeOfApplication"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/TypeOfApplication"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Type Of Application</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/BankingDocs" ? "selected" : ""
                      }
                      routerLink={"/BankingDocs"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Banking Details (FICA)</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Screening" ? "selected" : ""
                      }
                      routerLink={"/Screening"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Screening</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/RiskAssessment"
                          ? "selected"
                          : ""
                      }
                      routerLink={"/RiskAssessment"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Risk Assessment</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={location.pathname === "/RFP" ? "selected" : ""}
                      routerLink={"/RFP"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Request for Payment</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/CICApprovalStatus"  ? 'selected' : ''}   routerLink={"/CICApprovalStatus"}  routerDirection="none" lines="none" detail={false}>

                <PlayCircleOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >CIC Approval status</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                </>
              );
            }

            if (user.UserType === "FUNDMANAGER") {
              appPages.length = 1;

              return (
                <>
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/Addendums"  ? 'selected' : ''}   routerLink={"/Addendums"}  routerDirection="none" lines="none" detail={false}>
             

                <ProfileOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Addendums </IonLabel>

                </IonItem>
                  </IonMenuToggle> */}

                  <IonMenuToggle key={99} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Screening" ? "selected" : ""
                      }
                      routerLink={"/Screening"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Screening </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  {/* <IonMenuToggle key={99} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Proposal" ? "selected" : ""
                      }
                      routerLink={"/Proposal"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Proposal </IonLabel>
                    </IonItem>
                  </IonMenuToggle> */}

                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/HelpPage"  ? 'selected' : ''}   routerLink={"/HelpPage"}  routerDirection="none" lines="none" detail={false}>
             

                <ProfileOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Help Page </IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/Projects"  ? 'selected' : ''}   routerLink={"/Projects"}  routerDirection="none" lines="none" detail={false}>
             

                <ProfileOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >My Projects</IonLabel>

                </IonItem>
              </IonMenuToggle> */}

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/Leads" ? "selected" : ""
                      }
                      routerLink={"/Leads"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Leads</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/JobFundReport" ? "selected" : ""
                      }
                      routerLink={"/JobFundReport"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ProfileOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Job Fund Report</IonLabel>
                    </IonItem>
                  </IonMenuToggle>

                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/EligibilityCriteria"  ? 'selected' : ''}   routerLink={"/EligibilityCriteria"}  routerDirection="none" lines="none" detail={false}>
             

                <ShrinkOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Eligibility Criteria</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/ApplicationForm"  ? 'selected' : ''}   routerLink={"/ApplicationForm"}  routerDirection="none" lines="none" detail={false}>
             

                <SolutionOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Application Form</IonLabel>

                </IonItem>
              </IonMenuToggle>
                <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/ComplienceChecklist"  ? 'selected' : ''}   routerLink={"/ComplienceChecklist"}  routerDirection="none" lines="none" detail={false}>
             

                <CheckSquareOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Compliance Checklist</IonLabel>

                </IonItem>
              </IonMenuToggle>
                <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/DeclarationPage"  ? 'selected' : ''}   routerLink={"/DeclarationPage"}  routerDirection="none" lines="none" detail={false}>
             

                <LineOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Declaration Page</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/ProjectManager"  ? 'selected' : ''}   routerLink={"/ProjectManager"}  routerDirection="none" lines="none" detail={false}>
             

                <TeamOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Project Manager</IonLabel>

                </IonItem>
              </IonMenuToggle>

                <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/BaselineReport"  ? 'selected' : ''}   routerLink={"/BaselineReport"}  routerDirection="none" lines="none" detail={false}>
             

                <RotateRightOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Baseline Report</IonLabel>

                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/CreditAgreement"  ? 'selected' : ''}   routerLink={"/CreditAgreement"}  routerDirection="none" lines="none" detail={false}>
             

                <ProjectOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Credit Agreement</IonLabel>

                </IonItem>
              </IonMenuToggle>

                <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/JointVentureAgreement"  ? 'selected' : ''}   routerLink={"/JointVentureAgreement"}  routerDirection="none" lines="none" detail={false}>
             

                <ShrinkOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Joint Venture Agreement</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/TDRiskOfficer"  ? 'selected' : ''}   routerLink={"/TDRiskOfficer"}  routerDirection="none" lines="none" detail={false}>

                <RotateRightOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Technical/Risk verify application</IonLabel>

                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/PMAssign"  ? 'selected' : ''}   routerLink={"/PMAssign"}  routerDirection="none" lines="none" detail={false}>
             

                <ShrinkOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Assign Project Manager</IonLabel>

                </IonItem>
              </IonMenuToggle>
                <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/AssignRoles"  ? 'selected' : ''}   routerLink={"/AssignRoles"}  routerDirection="none" lines="none" detail={false}>
             

                <SolutionOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Assign Roles</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/ApplicationsOfficer"  ? 'selected' : ''}   routerLink={"/ApplicationsOfficer"}  routerDirection="none" lines="none" detail={false}>

                <UserSwitchOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Applications Dashboard</IonLabel>

                </IonItem>
                
              </IonMenuToggle> */}
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/TECVoting" ? "selected" : ""
                      }
                      routerLink={"/TECVoting"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <LogoutOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>TEC Recommendations </IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/ComplianceView"  ? 'selected' : ''}   routerLink={"/ComplianceView"}  routerDirection="none" lines="none" detail={false}>

                <PlayCircleOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Compliance Dashboard</IonLabel>

                </IonItem>
                  </IonMenuToggle> */}
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/TypeOfApplication"  ? 'selected' : ''}   routerLink={"/TypeOfApplication"}  routerDirection="none" lines="none" detail={false}>
             

                <ShrinkOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >Type Of Application</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                  {/* <IonMenuToggle key={98} autoHide={false}>
                <IonItem  className={location.pathname === "/CICApprovalStatus"  ? 'selected' : ''}   routerLink={"/CICApprovalStatus"}  routerDirection="none" lines="none" detail={false}>

                <PlayCircleOutlined style={{fontSize:40, padding:"10px"}} />
                  <IonLabel >CIC Approval status</IonLabel>

                </IonItem>
              </IonMenuToggle> */}
                </>
              );
            }

            if (user.UserType === "FINANCEADMINSTRATOR") {
              appPages.length = 1;

              return (
                <>
                  <IonMenuToggle key={98} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === "/FinanceDash" ? "selected" : ""
                      }
                      routerLink={"/FinanceDash"}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <ShrinkOutlined
                        style={{ fontSize: 40, padding: "10px" }}
                      />
                      <IonLabel>Finance Admin Dashboard</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                </>
              );
            }
          })}
          <IonMenuToggle key={99} autoHide={false}>
            <IonItem
              onClick={() => {
                Logout();
              }}
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <LogoutOutlined style={{ fontSize: 40, padding: "10px" }} />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
