import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import { Radio, Typography, Upload } from "antd";
import { Row, TreeSelect, Table, InputNumber } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input, Select } from "antd";
import { DatePicker, Space, Button, Tooltip } from "antd";
import "./Page2.css";
import moment from "moment";
import { Steps, message } from "antd";
import React, { useRef, useState } from "react";
import { PageHeader } from "antd";
import SignatureCanvas from "react-signature-canvas";
import ReactDOM from "react-dom";
import axios from "axios";
import { setConstantValue } from "typescript";
import { TreeNode } from "antd/lib/tree-select";
import Modal from "antd/lib/modal/Modal";
import { PlusOutlined, ProjectOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { UIStore } from "../store/UIStore";
import TextArea from "antd/lib/input/TextArea";
import { Menu, Dropdown } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import ScrollToTop from "react-scroll-to-top";

const Vetting: React.FC = (props: any) => {
  const style = { paddingLeft: "130px" };
  const style2 = { paddingLeft: "40px" };
  const label2 = { paddingLeft: "25px" };
  const { Option } = Select;
  const img = { padding: "40px" };
  const [fileList, setFileList] = useState([]);

  function handleButtonClick(e: any) {
    message.info("Click on left button.");
    console.log("click left button", e);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Manufacturer/Producer" icon={<UserOutlined />}>
        Manufacturer/Producer
      </Menu.Item>
      <Menu.Item key="Distributor" icon={<UserOutlined />}>
        Distributor
      </Menu.Item>
      <Menu.Item key="Importer" icon={<UserOutlined />}>
        Importer
      </Menu.Item>
      <Menu.Item key="Other" icon={<UserOutlined />}>
        Other
      </Menu.Item>
    </Menu>
  );

  var sigCanvas: any = {};
  var sigCanvas2: any = {};

  var sigCanvasSupplier: any = {};
  var sigCanvasClient: any = {};
  var sigCanvasEntitySignature: any = {};

  const options = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
  ];
  const options2 = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
    { label: "NA", value: "NA" },
  ];
  const [vettings, setVetting] = useState({
    id: "",
    DateSiteVisit: "",
    SiteLocation: "",
    SiteWIP: "",
    SiteCondition: "",
    SitePhotoDescriptionOne: "",
    SitePhotoDescriptionTwo: "",
    SitePhotoDescriptionThree: "",
    SitePhotoOne: "",
    SitePhotoTwo: "",
    SitePhotoThree: "",
    SiteObservation: "",
    SiteAdditionalObservationDetails: "",
    SiteConstructionObservations: "",
    EntityConfirmation: "",
    EntityAuthority: "",
    EntitySignature: "",
    SiteSignatureDate: "",
    ProjectManagerSignature: "",
    ProjectManagerClientSignature: "",
    ProjectManagerSupplierSignature: "",
    ProjectManagerEntitySignature: "",
    DateClientVisit: "",
    DirectorOneAddress: "",
    YearsAtPremisesOne: "",
    PremisesTypeOne: "",
    DirectorOneObservations: "",
    DirectorTwoAddress: "",
    YearsAtPremisesTwo: "",
    PremisesTypeTwo: "",
    DirectorTwoObservations: "",
    ClientAddObservations: "",
    ClientPhotoOneDescription: "",
    ClientPhotoTwoDescription: "",
    ClientPhotoThreeDescription: "",
    ClientPhotoOne: "",
    ClientPhotoTwo: "",
    ClientPhotoThree: "",
    ClientEntity: "",
    ClientEntityAuthority: "",
    ClientEntitySignature: "",
    ClientSignatureDate: "",
    DateSuppliervisit: "",
    SupplierName: "",
    SupplierGoodsDescription: "",
    SupplierSiteLocation: "",
    SupplierAddress: "",
    SupplierPremisesType: "",
    SupplierObservations: "",
    SupplierType: "",
    TypeOfSupplierObservation: "",
    SupplierInfrustructure: "",
    SupplierStock: "",
    QualityControl: "",
    QualityStandards: "",
    CapacityQualityObservation: "",
    PaymentMethods: "",
    DaysToPay: "",
    DiscountConditions: "",
    SupplierSupportObservation: "",
    SupplierCIPC: "",
    SupplierBankLetter: "",
    SupplierRef: "",
    SupplierCert: "",
    SupplierPhotoDescriptionOne: "",
    SupplierPhotoDescriptionTwo: "",
    SupplierPhotoDescriptionThree: "",
    SupplierPhotoOne: "",
    SupplierPhotoTwo: "",
    SupplierPhotoThree: "",
    SupplierEntity: "",
    SupplierEntityAuthority: "",
    SupplierEntitySignature: "",
    SupplierDateSigning: "",
    ProposalDate: "",
  });

  function handleMenuClick(e: any) {
    setVetting((prevState) => ({
      ...prevState,
      SupplierType: e.key,
    }));
    console.log(vettings.SupplierType);
  }
  const dateFormat = "YYYY/MM/DD";
  const { Title } = Typography;
  const [signatureResult, setSignatureResult] = useState("");
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const [Projects, SetProjects] = useState<any>([
    { ProjectName: "", Year: "", Description: "", Value: "", Client: "" },
  ]);
  const section = <span>Next section</span>;
  const submit = <span>Submit and proceed to compliance checklist.</span>;
  const back = <span>Previous section</span>;
  const create = <span>Fill in project history </span>;
  const buttonWidth = 70;

  useIonViewDidEnter(() => { });

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return file.type === "image/jpeg" && file.size / 1024 / 1024 < 2
      ? true
      : Upload.LIST_IGNORE;
  }

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setVetting((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSigChange = (sig: any, name: any) => {
    console.log(sig);
    setVetting((prevState) => ({
      ...prevState,
      [name]: sig,
    }));
  };
  const handleDateChange = (date: any, name: any) => {
    setVetting((prevState) => ({
      ...prevState,
      [name]: date,
    }));
  };
  const handleRadioChange = (e: any, name: any) => {
    setVetting((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };
  async function submitVetting() {
    console.log(vettings);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/projects/submitvetting/" +
          CurrentProject.id,
          vettings
        )
        .then((response) => {
          UIStore.update((s) => {
            s.CurrentProject = response.data;
          });
          console.log(response.data);
          //props.history.push("/ProjectManager");
        })
        .finally(() => {
          props.history.push("/ProjectManager");
        });
    } catch (error: any) {
      console.error(error);
    }
  }

  const [author, setAuthor] = useState("");
  const [sigModal, setSigModal] = useState(false);

  const showModal = () => {
    setSigModal(true);
  };

  const handleSigCancel = () => {
    setSigModal(false);
  };

  function loadSignature(author: any) {
    showModal();
    setAuthor(author);
  }

  let content = <></>;

  async function onChange(info: any) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
            CurrentProject
          )
          .then((response) => {
            // message.success(response.data);
            console.log(response);
          });
      } catch (error: any) {
        console.error(error);
      }

      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  function handleSelectChange(value: any, name: any) {
    //console.log(name + value.value)
    setVetting((prevState) => ({
      ...prevState,
      [name]: value.value,
    }));
  }

  const todayDate = new Date();

  const { Step } = Steps;

  const steps = [
    {
      title: "Site Visits",
      content: (
        <div style={{ padding: "20px" }}>
          <IonRow>
            <h4>
              <strong>PRE - FUNDING: SITE VISIT REPORT</strong>
            </h4>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Manager:</strong>
              </p>
              <Input
                value={CurrentProject.ProjectManagerName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectManagerName = e.target.value;
                  });
                }}
                name="ProjectManagerName"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Client Company:</strong>
              </p>
              <Input
                value={CurrentProject.Client}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Client = e.target.value;
                  });
                }}
                name="Client"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Date of Visit:</strong>
              </p>

              <DatePicker
                value={
                  vettings.DateSiteVisit.length === 0
                    ? moment(Date.now())
                    : moment(vettings.DateSiteVisit)
                }
                defaultValue={moment("2015/01/01", dateFormat)}
                onChange={(date, dateString) => {
                  handleDateChange(dateString, "DateSiteVisit");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Name:</strong>
              </p>
              <Input
                value={CurrentProject.LeadName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.LeadName = e.target.value;
                  });
                }}
                name="ProjectName"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Site Location:</strong>
              </p>
              <Input
                value={CurrentProject.SiteLocation}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SiteLocation"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>SITE CONDITION AND WORK IN PROGRESS</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Work in Progress</strong>
              </p>
              <TextArea
                value={vettings.SiteWIP}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SiteWIP"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Site Condition</strong>
              </p>
              <TextArea
                value={vettings.SiteCondition}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SiteCondition"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>SITE CONDITION AND PROGRESS PHOTOS</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description:</strong>
              </p>
              <Input
                value={vettings.SitePhotoDescriptionOne}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SitePhotoDescriptionOne"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name={"SitePhotoOne"}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description</strong>
              </p>
              <Input
                value={vettings.SitePhotoDescriptionTwo}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SitePhotoDescriptionTwo"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name="SitePhotoTwo"
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description</strong>
              </p>
              <Input
                value={vettings.SitePhotoDescriptionThree}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SitePhotoDescriptionThree"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name="SitePhotoThree"
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>OBSERVATIONS</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Observations made against the Progress Report Submitted by a
                  Client{" "}
                </strong>
              </p>
              <TextArea
                value={vettings.SiteObservation}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SiteObservation"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Additional Report Details</strong>
              </p>
              <TextArea
                value={vettings.SiteAdditionalObservationDetails}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SiteAdditionalObservationDetails"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>CONSTRUCTION AND OTHER RELATED CHALLENGES</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Observations made against the Progress Report Submitted by a
                  Client{" "}
                </strong>
              </p>
              <TextArea
                value={vettings.SiteConstructionObservations}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SiteConstructionObservations"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>CONFIRMATION OF SITE VISIT</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Client/Business Owner/Project Manager: </strong>
              </p>
              <Input
                value={vettings.EntityConfirmation}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="EntityConfirmation"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Authority: </strong>
              </p>
              <Input
                value={vettings.EntityAuthority}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="EntityAuthority"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                <strong>Signature: </strong>
              </p>
              {vettings.EntitySignature.length > 5 ? (
                <img src={vettings.EntitySignature} alt="signature" />
              ) : (
                <SignatureCanvas
                  ref={(ref) => {
                    sigCanvasEntitySignature = ref;
                  }}
                  backgroundColor="#a9a9a9"
                  penColor="black"
                  canvasProps={{
                    width: 1300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    let signature = sigCanvasEntitySignature.toDataURL();
                    handleSigChange(signature, "EntitySignature");
                  }}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Date:</strong>
              </p>

              <DatePicker
                value={
                  vettings.SiteSignatureDate.length === 0
                    ? moment(Date.now())
                    : moment(vettings.SiteSignatureDate)
                }
                onChange={(date, dateString) => {
                  handleDateChange(dateString, "SiteSignatureDate");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>SP Impact Fund Project Manager: </strong>
              </p>
              <Input
                value={CurrentProject.ProjectManagerName}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="ProjectManagerName"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                <strong>Signature: </strong>
              </p>
              {vettings.ProjectManagerEntitySignature.length > 5 ? (
                <img
                  src={vettings.ProjectManagerEntitySignature}
                  alt="signature"
                />
              ) : (
                <SignatureCanvas
                  ref={(ref) => {
                    sigCanvas = ref;
                  }}
                  backgroundColor="#a9a9a9"
                  penColor="black"
                  canvasProps={{
                    width: 1300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    let signature = sigCanvas.toDataURL();
                    sigCanvas.clear();
                    handleSigChange(signature, "ProjectManagerEntitySignature");
                  }}
                />
              )}
            </IonCol>
          </IonRow>
        </div>
      ),
    },
    {
      title: "Client Vetting",
      content: (
        <div style={{ padding: "20px" }}>
          <IonRow>
            <h4>
              <strong>CLIENT VETTING REPORT</strong>
            </h4>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Client:</strong>
              </p>
              <Input
                value={CurrentProject.Client}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Client = e.target.value;
                  });
                }}
                name="Client"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Manager:</strong>
              </p>
              <Input
                value={CurrentProject.ProjectManagerName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectManagerName = e.target.value;
                  });
                }}
                name="ProjectManagerName"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Date of Visit:</strong>
              </p>

              <DatePicker
                value={
                  vettings.DateClientVisit.length === 0
                    ? moment(Date.now())
                    : moment(vettings.DateClientVisit)
                }
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.DateClientVisit = dateString;
                  });
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>CLIENT PREMISES:</strong>
            </h4>
          </IonRow>
          <IonRow>
            <h6>Confirmation of Client's Residence (Location) </h6>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Address (Director 1)</strong>
              </p>
              <Input
                value={vettings.DirectorOneAddress}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="DirectorOneAddress"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Number of Years at Premises</strong>
              </p>
              <Input
                value={vettings.YearsAtPremisesOne}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="YearsAtPremisesOne"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Type of Working Premises (i.e., Townhouse)</strong>
              </p>
              <Input
                value={vettings.PremisesTypeOne}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="PremisesTypeOne"
                placeholder=""
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Observations/Comments</strong>
              </p>
              <TextArea
                value={vettings.DirectorOneObservations}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="DirectorOneObservations"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h6>Confirmation of Client's Residence (Location) </h6>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Address (Director 2)</strong>
              </p>
              <Input
                value={vettings.DirectorTwoAddress}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="DirectorTwoAddress"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Number of Years at Premises</strong>
              </p>
              <Input
                value={vettings.YearsAtPremisesTwo}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="YearsAtPremisesTwo"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Type of Working Premises (i.e., Townhouse)</strong>
              </p>
              <Input
                value={vettings.PremisesTypeTwo}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="PremisesTypeTwo"
                placeholder=""
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Observations/Comments</strong>
              </p>
              <TextArea
                value={vettings.DirectorTwoObservations}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="DirectorTwoObservations"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>ADDITIONAL REPORT DETAILS</strong>
            </h4>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Observations/Comments</strong>
              </p>
              <TextArea
                value={vettings.ClientAddObservations}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="ClientAddObservations"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>PHOTOS OF PREMISES</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description:</strong>
              </p>
              <Input
                value={vettings.ClientPhotoOneDescription}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="ClientPhotoOneDescription"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name={"ClientPhotoOne"}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description</strong>
              </p>
              <Input
                value={vettings.ClientPhotoTwoDescription}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="ClientPhotoTwoDescription"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name={"ClientPhotoTwo"}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description</strong>
              </p>
              <Input
                value={vettings.ClientPhotoThreeDescription}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="ClientPhotoThreeDescription"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name={"ClientPhotoThree"}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>CONFIRMATION OF CLIENT SITE VISIT</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Supplier/Business Owner/Project Manager: </strong>
              </p>
              <Input
                value={vettings.ClientEntity}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="ClientEntity"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Authority: </strong>
              </p>
              <Input
                value={vettings.ClientEntityAuthority}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="ClientEntityAuthority"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                <strong>Signature: </strong>
              </p>
              {vettings.ClientEntitySignature.length > 5 ? (
                <img src={vettings.ClientEntitySignature} alt="signature" />
              ) : (
                <SignatureCanvas
                  ref={(ref) => {
                    sigCanvasClient = ref;
                  }}
                  backgroundColor="#a9a9a9"
                  penColor="black"
                  canvasProps={{
                    width: 1300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    let signature = sigCanvasClient.toDataURL();
                    handleSigChange(signature, "ClientEntitySignature");
                  }}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Date:</strong>
              </p>

              <DatePicker
                value={
                  vettings.ProposalDate.length === 0
                    ? moment(Date.now())
                    : moment(vettings.ProposalDate)
                }
                onChange={(date, dateString) => {
                  handleDateChange(dateString, "ProposalDate");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>SP Impact Fund Project Manager: </strong>
              </p>
              <Input
                value={CurrentProject.ProjectManagerName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectManagerName = e.target.value;
                  });
                }}
                name="ProjectManagerName"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                <strong>Signature: </strong>
              </p>
              {vettings.ProjectManagerClientSignature.length > 5 ? (
                <img
                  src={vettings.ProjectManagerClientSignature}
                  alt="signature"
                />
              ) : (
                <SignatureCanvas
                  ref={(ref) => {
                    sigCanvas2 = ref;
                  }}
                  backgroundColor="#a9a9a9"
                  penColor="black"
                  canvasProps={{
                    width: 1300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    let signature = sigCanvas2.toDataURL();
                    handleSigChange(signature, "ProjectManagerClientSignature");
                  }}
                />
              )}
            </IonCol>
          </IonRow>

          <div style={{ paddingLeft: "20px" }}></div>
        </div>
      ),
    },
    {
      title: "Supplier Vetting",
      content: (
        <div style={{ padding: "20px" }}>
          <IonRow>
            <h4>
              <strong>PRE - FUNDING: SITE VISIT REPORT</strong>
            </h4>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Manger:</strong>
              </p>
              <Input
                value={CurrentProject.ProjectManagerName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectManagerName = e.target.value;
                  });
                }}
                name="ProjectManagerName"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Supplier:</strong>
              </p>
              <Input
                value={vettings.SupplierName}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierName"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Date of Visit:</strong>
              </p>

              <DatePicker
                value={
                  vettings.DateSuppliervisit.length === 0
                    ? moment(Date.now())
                    : moment(vettings.DateSuppliervisit)
                }
                onChange={(date, dateString) => {
                  handleDateChange(dateString, "DateSuppliervisit");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Description of Goods:</strong>
              </p>
              <Input
                value={vettings.SupplierGoodsDescription}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierGoodsDescription"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Site Location:</strong>
              </p>
              <Input
                value={vettings.SupplierSiteLocation}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierSiteLocation"
                placeholder=""
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <h4>
              <strong>SUPPLIER SITE AND PREMISES</strong>
            </h4>
          </IonRow>
          <IonRow>
            <h6>Confirmation of Supplier’s Business Location</h6>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Address - Supplier’s Location </strong>
              </p>
              <Input
                value={vettings.SupplierAddress}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierAddress"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Type of Working Premises of the Supplier (i.e., Warehouse)
                </strong>
              </p>
              <Input
                value={vettings.SupplierPremisesType}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierPremisesType"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Observations/Comments</strong>
              </p>
              <TextArea
                value={vettings.SupplierObservations}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierObservations"
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <Dropdown overlay={menu} placement="bottomRight" arrow>
                <Button style={{ width: "20vw" }}>
                  {vettings.SupplierType
                    ? vettings.SupplierType
                    : "Type of Supplier"}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Observations/Comments</strong>
              </p>
              <TextArea
                value={vettings.TypeOfSupplierObservation}
                placeholder=""
                rows={4}
                onChange={(e) => {
                  handleRadioChange(e, "TypeOfSupplierObservation");
                }}
                name="TypeOfSupplierObservation"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h6>Confirmation of Capacity and Quality</h6>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Does the Supplier have the necessary infrastructure to
                  produce/manufacture/distribute the order?
                </strong>
              </p>
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Select
                  labelInValue
                  placeholder="Yes/No"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    handleSelectChange(e, "SupplierInfrustructure");
                  }}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
                {/* <Radio.Group
                  options={options}
                  value={vettings.SupplierInfrustructure}
                  onChange={(e) => {
                    handleRadioChange(e, "SupplierInfrustructure");
                  }}
                  optionType="button"
                  buttonStyle="solid"
                /> */}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Is the relevant stock (as per quotation) available on site?
                </strong>
              </p>
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Select
                  labelInValue
                  placeholder="Yes/No"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    handleSelectChange(e, "SupplierStock");
                  }}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
                {/* <Radio.Group
                  options={options}
                  value={vettings.SupplierStock}
                  onChange={(e) => {
                    handleRadioChange(e, "SupplierStock");
                  }}
                  optionType="button"
                  buttonStyle="solid"
                /> */}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Are there available quality control measures?</strong>
              </p>
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Select
                  labelInValue
                  placeholder="Yes/No"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    handleSelectChange(e, "QualityControl");
                  }}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
                {/* <Radio.Group
                  options={options}
                  value={vettings.QualityControl}
                  onChange={(e) => {
                    handleRadioChange(e, "QualityControl");
                  }}
                  optionType="button"
                  buttonStyle="solid"
                /> */}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Do the products on Supplier’s Quotation meet the quality
                  standards as per Project Specifications?
                </strong>
              </p>
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Select
                  labelInValue
                  placeholder="Yes/No"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    handleSelectChange(e, "QualityStandards");
                  }}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
                {/* <Radio.Group
                  options={options}
                  value={vettings.QualityStandards}
                  onChange={(e) => {
                    handleRadioChange(e, "QualityStandards");
                  }}
                  optionType="button"
                  buttonStyle="solid"
                /> */}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Observations/Comments</strong>
              </p>
              <TextArea
                value={vettings.CapacityQualityObservation}
                placeholder=""
                rows={4}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="CapacityQualityObservation"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>PAYMENT TERMS</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Expected payment methods?</strong>
              </p>
              <TextArea
                value={vettings.PaymentMethods}
                placeholder=""
                rows={4}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="PaymentMethods"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Days allowable to pay an invoice?</strong>
              </p>
              <Input
                value={vettings.DaysToPay}
                type="text"
                style={{ width: "10vw" }}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="DaysToPay"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Conditions for discounts?</strong>
              </p>
              <TextArea
                value={vettings.DiscountConditions}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="DiscountConditions"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>ADDITIONAL REPORT DETAILS</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Observations Or Comments</strong>
              </p>
              <TextArea
                value={vettings.SupplierSupportObservation}
                placeholder=""
                rows={4}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierSupportObservation"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>
                REQUIRED SUPPORTING DOCUMENTS TO BE EMAILED TO PROJECT MANGER
              </strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Supplier CIPC Registration Certificate</strong>
              </p>
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Select
                  labelInValue
                  placeholder="Yes/No"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    handleSelectChange(e, "SupplierCIPC");
                  }}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
                {/* <Radio.Group
                  options={options2}
                  value={vettings.SupplierCIPC}
                  onChange={(e: any) => {
                    handleRadioChange(e, "SupplierCIPC");
                  }}
                  optionType="button"
                  buttonStyle="solid"
                /> */}
              </div>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol class="ion-padding">
             
                <strong>Supplier Bank Confirmation Letter</strong>
              
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Radio.Group
                  options={options2}
                  value={vettings.SupplierBankLetter}
                  onChange={(e: any) => {
                    handleRadioChange(e,"SupplierBankLetter")
                  }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
              </IonRow> */}

          <IonRow>
            <IonCol class="ion-padding" >
              <p style={{ visibility: "visible" }}>
                <strong >Supplier Profile and previous clients’ references</strong>
              </p>
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Select
                  labelInValue
                  placeholder="Yes/No"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    handleSelectChange(e, "SupplierRef");
                  }}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
                {/* <Radio.Group
                  options={options2}
                  value={vettings.SupplierRef}
                  onChange={(e: any) => {
                    handleRadioChange(e, "SupplierRef");
                  }}
                  optionType="button"
                  buttonStyle="solid"
                /> */}
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Relevant Supplier Certifications (If Applicable)
                </strong>
              </p>
            </IonCol>
            <IonCol class="ion-padding">
              <div>
                <Select
                  labelInValue
                  placeholder="Yes/No"
                  style={{ width: 120 }}
                  onChange={(e) => {
                    handleSelectChange(e, "SupplierCert");
                  }}
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
                {/* <Radio.Group
                  options={options2}
                  value={vettings.SupplierCert}
                  onChange={(e: any) => {
                    handleRadioChange(e, "SupplierCert");
                  }}
                  optionType="button"
                  buttonStyle="solid"
                /> */}
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <h4>
              <strong>SITE CONDITION AND PROGRESS PHOTOS</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description:</strong>
              </p>
              <Input
                value={vettings.SupplierPhotoDescriptionOne}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierPhotoDescriptionOne"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name={"SupplierPhotoOne"}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description</strong>
              </p>
              <Input
                value={vettings.SupplierPhotoDescriptionTwo}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierPhotoDescriptionTwo"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name={"SupplierPhotoTwo"}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Photo Description</strong>
              </p>
              <Input
                value={vettings.SupplierPhotoDescriptionThree}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierPhotoDescriptionThree"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <label>Upload site photo</label>
              <IonCol class="ion-padding">
                <ImgCrop>
                  <Upload
                    action="https://dev.northroom.co.za/api/spfund/uploadimage/main"
                    listType="picture-card"
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    style={img}
                    name={"SupplierPhotoThree"}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </ImgCrop>
              </IonCol>
            </IonCol>
          </IonRow>
          <IonRow>
            <h4>
              <strong>CONFIRMATION OF SITE VISIT</strong>
            </h4>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Supplier/Business Owner/Project Manager: </strong>
              </p>
              <Input
                value={vettings.SupplierEntity}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierEntity"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Authority: </strong>
              </p>
              <Input
                value={vettings.SupplierEntityAuthority}
                type="text"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                name="SupplierEntityAuthority"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                <strong>Signature: </strong>
              </p>
              {vettings.SupplierEntitySignature.length > 5 ? (
                <img src={vettings.SupplierEntitySignature} alt="signature" />
              ) : (
                <SignatureCanvas
                  ref={(ref) => {
                    sigCanvasSupplier = ref;
                  }}
                  backgroundColor="#a9a9a9"
                  penColor="black"
                  canvasProps={{
                    width: 1300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    let signature = sigCanvasSupplier.toDataURL();

                    handleSigChange(signature, "SupplierEntitySignature");
                  }}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>Date:</strong>
              </p>

              <DatePicker
                value={
                  vettings.SupplierDateSigning.length === 0
                    ? moment(Date.now())
                    : moment(vettings.SupplierDateSigning)
                }
                onChange={(date, dateString) => {
                  handleDateChange(dateString, "SupplierDateSigning");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <p>
                <strong>SP Impact Fund Project Manager: </strong>
              </p>
              <Input
                value={CurrentProject.ProjectManagerName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectManagerName = e.target.value;
                  });
                }}
                name="ProjectManagerName"
                placeholder=""
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p>
                <strong>Signature: </strong>
              </p>
              {vettings.ProjectManagerSupplierSignature.length > 5 ? (
                <img
                  src={vettings.ProjectManagerSupplierSignature}
                  alt="signature"
                />
              ) : (
                <SignatureCanvas
                  ref={(ref) => {
                    sigCanvas = ref;
                  }}
                  backgroundColor="#a9a9a9"
                  penColor="black"
                  canvasProps={{
                    width: 1300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    let signature = sigCanvas.toDataURL();
                    handleSigChange(
                      signature,
                      "ProjectManagerSupplierSignature"
                    );
                  }}
                />
              )}
            </IonCol>
          </IonRow>
        </div>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  return (
    <IonPage>
      <ScrollToTop smooth />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="Project Vetting Procedure"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen ref={contentRef} scrollEvents={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">test</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCol class="ion-padding">
          <hr></hr>
          <IonRow>
            <IonCol class="ion-padding">
              <Steps style={{ padding: "20px" }} current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </IonCol>
          </IonRow>

          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current > 0 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={back}>
                  <Button
                    size={"large"}
                    style={{ margin: "0 8px" }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                </Tooltip>
              </IonCol>
            )}
            {current < steps.length - 1 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={section}>
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={(CheckObject) => {
                      next();
                      scrollTop();
                    }}
                  >
                    Next
                  </Button>
                </Tooltip>
              </IonCol>
            )}
            {current === steps.length - 1 && (
              <IonCol class="ion-padding">
                {/* <Link to="/ComplienceChecklist"> */}
                <Tooltip placement="top" title={submit}>
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={() => {
                      submitVetting();
                    }}
                  >
                    Submit Form
                  </Button>
                </Tooltip>
                {/* </Link> */}
              </IonCol>
            )}

          </div>
        </IonCol>
        <Modal
          title="Please sign here"
          visible={sigModal}
          onCancel={handleSigCancel}
          onOk={handleSigCancel}
          style={{ paddingBottom: "0px", height: 1000 }}
          width={1360}
        >
          <IonCol>{content}</IonCol>
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default Vetting;
