import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonGrid,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  message,
  Typography,
  Table,
  Tag,
  Radio,
} from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import moment from "moment";
import { PageHeader } from "antd";
import axios from "axios";
import { ProjectStatus, UIStore } from "../store/UIStore";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import React, { useState } from "react";

const dateFormat = "YYYY/MM/DD";
const { TextArea } = Input;

const InitalRiskAssessment: React.FC = (props: any) => {
  const { name } = useParams<{ name: string }>();

  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }

  async function SubmitRisk() {
    console.log(CurrentProject);


    message
      .loading("Action in progress..", 2)
      .then(() => message.success("Submission Succeeded", 3));

    if (CurrentProject.CurrentStatus === "INITIALRISK") {
      try {
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
            CurrentProject
          )
          .then((response) => {
            // message.success(response.data);
            console.log(response);
            //props.history.push("/RiskAssessment");
          }).finally(() => {
            props.history.push("/RiskMatrix");
          });
      } catch (error: any) {
        console.error(error);
        props.history.push("/RiskMatrix");
      }

    } else {
      try {
        //CurrentProject.CurrentStatus===ProjectStatus.
        const request = await axios
          .post(
            "https://dev.northroom.co.za/api/spfund/projects/riskassessmentdetailsupdateFinal/" +
            CurrentProject.id,
          )
          .then((response) => {
            // message.success(response.data);
            console.log(response);
            //props.history.push("/RiskAssessment");
          }).finally(() => {
            props.history.push("/RiskMatrix");
          });;
      } catch (error: any) {
        console.error(error);
        props.history.push("/RiskMatrix");
      }
    }
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const RiskAssessment = UIStore.useState((s) => s.RiskAssessment);

  const { TextArea } = Input;
  const style = { name: { paddingLeft: "2vw", paddingRight: "2vw" } };
  const { Title } = Typography;

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  useIonViewDidEnter(() => {
    console.log(CurrentProject);

    if (CurrentProject.CurrentStatus === "INITIALRISK") {
      axios
        .get(
          "https://dev.northroom.co.za/api/spfund/projects/riskassessmentdetailsfetchInitial/" +
          CurrentProject.id
        )
        .then(function (response) {
          console.log(response.data)
          UIStore.update((s) => {
            s.RiskAssessment = response.data[0];
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    } else {
      axios
        .get(
          "https://dev.northroom.co.za/api/spfund/projects/riskassessmentdetailsfetchFinal/" +
          CurrentProject.id
        )
        .then(function (response) {
          UIStore.update((s) => {
            s.RiskAssessment = response.data[0];
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  });

  const checkFile = (file: any) => {
    try {
      if (file.length > 5) {
        return "YES"
      } else {
        return "NO"
      }
    } catch (error: any) {
      return "NO"
    }
  }

  const options = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
    { label: "NA", value: "NA" },

  ];
  const optionsVerifeid = [
    { label: "Verified", value: "Verified" },
    { label: "Not Verified", value: "Not Verified" },
  ];

  let content = <></>;

  if (CurrentProject.CurrentStatus === "") {
    content = <IonRow className="ion-text-center" style={{ margin: "1vw" }}>
      <IonCol className="ion-text-center">
        <h3>Qualitative Comment</h3>
        <TextArea
          style={{ height: "10vw" }}
          placeholder=""
          rows={2}
          value={CurrentProject.InitialQualitativeComment}
          onChange={(e) => {
            UIStore.update((s) => {
              s.CurrentProject.InitialQualitativeComment = e.target.value;
            });
          }}
        />
      </IonCol>
    </IonRow>
  } else {
    content = <IonRow className="ion-text-center" style={{ margin: "1vw" }}>
      <IonCol className="ion-text-center">
        <h3>Qualitative Comment</h3>
        <TextArea
          style={{ height: "10vw" }}
          placeholder=""
          rows={2}
          value={CurrentProject.FinalQualitativeComment}
          onChange={(e) => {
            UIStore.update((s) => {
              s.CurrentProject.FinalQualitativeComment = e.target.value;
            });
          }}
        />
      </IonCol>
    </IonRow>
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            onBack={() => props.history.goBack()}
            title="Initial Risk Assessment"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid style={{ padding: "2vw" }}>
          <IonRow>
            <IonCol>
              <div>
                <h6>
                  <strong>ITEM</strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>YES/NO/NA</strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>MEANS OF VERIFICATION</strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>VERIFICATION STATUS </strong>
                </h6>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <h6>
                  <strong>COMMENTS</strong>
                </h6>
              </div>
            </IonCol>
          </IonRow>
          <br></br>
          <IonRow>
            <IonCol>
              <div>
                <p>Company Profile</p>
              </div>
            </IonCol>

            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.companyprofile)}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Company Profile</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.CompanyProfileVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.CompanyProfileVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.CompanyProfileComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.CompanyProfileComment = e.target.value.toString();
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Company registration documents</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.Companyregdocuments)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.CompanyRegistrationExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Independent verification with CIPC office</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.CompanyRegistrationVerification
                  value={"Verified"}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.CompanyRegistrationVerification =
                        e.target.value;
                    });
                  }}

                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.CompanyRegistrationComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.CompanyRegistrationComment =
                        e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>B-BBEE Certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.BBEECert)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.BbbeeCertificateExistance =
                  //       e.target.value;
                  //   });
                  // }}

                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Affidavit/Certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.Affidavit
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.Affidavit = e.target.value;
                  //   });
                  // }}

                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.AffidavitComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.Affidavit = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Marital status and type of marital contract</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.MartialSTatus)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.MaritalContractExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Signed declaration and certified marital certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.MaritalContractVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.MaritalContractVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.MaritalContractComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.MaritalContractComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Identity Documents of all owners or directors at group
                  level(within the last three months)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.AllDirectors)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.OwnersIDExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Clear certified copies</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.OwnersIDVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.OwnersIDExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.OwnersIDComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.OwnersIDComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Proof of Address(all directors,company)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.ProofAddress)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ProofOfAddressExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Certified proof of address</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.ProofOfAddressVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ProofOfAddressVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.ProofOfAddressComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.ProofOfAddressComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Authorized Personal</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.personnel)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.AuthorizedPersonalExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Director or Company Resolution giving the responsible person
                  authorization
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.AuthorizedPersonalVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.AuthorizedPersonalVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.AuthorizedPersonalComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.AuthorizedPersonalComment =
                        e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Department of Labour - Letter of Good Standing</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.DepartLabor)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.LetterGoodStandingExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Confirmation with the Department of Labour</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.LetterGoodStandingVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.LetterGoodStandingVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.LetterGoodStandingComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.LetterGoodStandingComment =
                        e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Tax Clearance Certificate and tax pin</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.TaxClearanceDoc)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.TaxClearanceExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>SARS Certificate - Expiry data checks</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.TaxClearanceVerification
                  value={"Verifed"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.TaxClearanceVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.TaxClearanceComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.TaxClearanceComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>NHBRC certificate (or relevant certification)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.NHBRC)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.NHBRCCertificateExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>NHBRC certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.NHBRCCertificateVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.NHBRCCertificateVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.NHBRCCertificateComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.NHBRCCertificateComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>CIDB Certificate</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.CIDBDoc)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.CIDBCertExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Confirmation with CIDB</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.CIDBCertVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.CIDBCertVerification = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.CIDBCertComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.CIDBCertComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  CSD registration report (generated within the last three
                  months)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.CSD)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.CSDRegExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>CSD registration report</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.CSDRegVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.CSDRegVerification = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.CSDRegComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.CSDRegComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Proof of insurance including liability cover(where applicable)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.Insurance)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ProofOfInsuranceExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Proof of cover and policy schedule</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.ProofOfInsuranceVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ProofOfInsuranceVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.ProofOfInsuranceComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.ProofOfInsuranceComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Financials/Management accounts (three-year historic financials
                  for a funding requirement of more than R15 million)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.ManagementAccounts)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.FinancialsExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Attached and Confirmed with Auditor or Accountant/A letter of
                  good standing from a bank without accountant/auditor services.
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.FinancialsVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.FinancialsVerification = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.FinancialsComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.FinancialsComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Bank confirmation letter</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  //DOC?
                  value={checkFile(CurrentProject.BankingStatements)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.BankConfirmationExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Attached Bank Confirmation Letter</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.BankConfirmationVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.BankConfirmationVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.BankConfirmationComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.BankConfirmationComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Banking statements (not older than 6 months)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.BankingStatements)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.BankingStatementsExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Banking Statements need to be attached</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.BankingStatementsVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.BankingStatementsVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.BankingStatementsComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.BankingStatementsComment =
                        e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Debtors Age Analysis</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.DebtAge)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.DebtorsAgeExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Debtors Age Analysis</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.DebtorsAgeVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.DebtorsAgeVerification = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.DebtorsAgeComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.DebtorsAgeComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Letter of appointment</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.CletterofAppointment)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.LetterAppointmentExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Attached and verified with the relevant project owner in
                  writing
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.LetterAppointmentVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.LetterAppointmentVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.LetterAppointmentComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.LetterAppointmentComment =
                        e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Signed Contract with Client</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.CAAddendum)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.SignedContractExistance = e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Attached and verified with the relevant project owner in
                  writing
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.SignedContractVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.SignedContractVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.SignedContractComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.SignedContractComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Implementation Plan</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.ImplementationPlan)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ImplementationPlanExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Implementation Plan</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.ImplementationPlanVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ImplementationPlanVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.ImplementationPlanComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.ImplementationPlanComment =
                        e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>Priced Bills of Quantities (or related purchase order)</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.BillofQuanties)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.BillsQuantitiesExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>
                  Confirmation of Validity Received from the project Operation
                  Teams
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.BillsQuantitiesVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.BillsQuantitiesVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.BillsQuantitiesComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.BillsQuantitiesComment = e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div>
                <p>
                  Project Completion Certificate of Similar Project (where
                  applicable)
                </p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={options}
                  value={checkFile(CurrentProject.CAAddendum)}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ProjectCompletionExistance =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <p>Project Completion Certificate of Similar</p>
              </div>
            </IonCol>
            <IonCol>
              <div>
                <Radio.Group
                  options={optionsVerifeid}
                  //RiskAssessment.ProjectCompletionVerification
                  value={"Verified"}
                  // onChange={(e) => {
                  //   UIStore.update((s) => {
                  //     s.RiskAssessment.ProjectCompletionVerification =
                  //       e.target.value;
                  //   });
                  // }}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            </IonCol>
            <IonCol>
              <div>
                <TextArea
                  placeholder=""
                  rows={2}
                  value={RiskAssessment.ProjectCompletionComment}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.RiskAssessment.ProjectCompletionComment =
                        e.target.value;
                    });
                  }}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        {content}

        <IonRow>
          <IonCol class="ion-padding">
            <Button
              size={"large"}
              type="primary"
              onClick={() => {
                SubmitRisk();
              }}
            >
              Submit
            </Button>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default InitalRiskAssessment;
