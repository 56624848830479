import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonCard,
  useIonViewDidEnter,
} from "@ionic/react";

import { useParams } from "react-router";

import { Descriptions, Row, Col, Table } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import moment from "moment";
import { Dropdown, Menu, message, InputNumber, Upload } from "antd";

import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import React, { useState } from "react";
import { PageHeader } from "antd";
import axios from "axios";
import { notification } from "antd";
import { UIStore } from "../store/UIStore";
import SignatureCanvas from "react-signature-canvas";
const dateFormat = "YYYY/MM/DD";
const { TextArea } = Input;

const ClientBrief: React.FC = (props: any) => {
  const { name } = useParams<{ name: string }>();
  const [count, setCount] = useState("");

  const openNotificationWithIcon = (type: any) => { };

  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }





  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const CurrentProjectRFP = UIStore.useState((s) => s.CurrentProject.RequestforPayment);


  useIonViewDidEnter(() => {
    console.log(CurrentProject);
  });

  async function SubmitRFP() {
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));

    if (user.UserType === "APPLICANT") {
      console.log(CurrentProject.id)
      try {
        const request = await axios.post('https://dev.northroom.co.za/api/spfund/projects/requestforpayment/' + CurrentProject.id, CurrentProject.RequestforPayment).then((response) => {

          console.log(response.data)
          props.history.push("/Projects");

        });

      } catch (error: any) {
        message.error("An error has occurred");
      }
    }
    else if (user.UserType === "FUNDMANAGER") {

      try {
        const request = await axios.post('https://dev.northroom.co.za/api/spfund/projects/requestforpaymentfm/' + CurrentProject.id, CurrentProject.RequestforPayment).then((response) => {


          props.history.push("/Screening");

        });

      } catch (error: any) {
        message.error("An error has occurred");
      }



    }

    // props.history.push("/Projects");
  }

  var sigCanvas: any = {};
  let content = <></>

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");


  if (user.UserType === "FUNDMANAGER") {
    content =
      <IonCol
        className="ion-text-left"
        style={{ paddingLeft: "4vw" }}
      >
        <p>
          <strong>Signature: </strong>
        </p>
        <SignatureCanvas
          ref={(ref) => {
            sigCanvas = ref;
          }}
          backgroundColor="#a9a9a9"
          penColor="black"
          canvasProps={{
            width: 1300,
            height: 200,
            className: "sigCanvas",
          }}
          onEnd={() => {
            let signature = sigCanvas.toDataURL();
            UIStore.update((s) => {
              s.CurrentProject.RequestforPayment.RFPSignature =
                signature;
            });
          }}
        />
      </IonCol>;
  } else if (user.UserType === "APPLICANT") {
    try {
      <img
        src={CurrentProject.RequestforPayment.RFPSignature}
        alt="Signature"
      ></img>;
    } catch (err) {
      console.log(err)
    }
  }



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            title="Request for Payment"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonCard
            style={{ margin: "2vw", width: "68vw", paddingright: "2vw" }}
          >
            <Descriptions
              className="ion-text-center"
              title="DRAW DOWN NOTICE"
            ></Descriptions>

            <IonRow>
              <IonCol class="ion-padding">
                <p style={{ paddingLeft: "10vw" }}>
                  <strong>Addressed to:</strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>SP IMPACT FUND (PTY) LTD</p>
                <p>11 Monza Close</p>
                <p>Kyalami Park</p>
                <p>Midrand</p>
                <p>1684</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className="ion-text-right"
                style={{ paddingRight: "25vw" }}
              >
                <DatePicker


                  value={CurrentProject.RequestforPayment.DateOne.length === 0 ? moment(Date.now()) : moment(CurrentProject.RequestforPayment.DateOne)}
                  onChange={(date, dateString) => {
                    UIStore.update((s) => {
                      s.CurrentProject.RequestforPayment.DateOne = dateString;
                    });
                  }}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  <strong>LOAN FACILITY AGREEMENT DATED:</strong>
                </p>
              </IonCol>
              <IonCol style={{ paddingRight: "35vw" }}>
                <DatePicker

                  value={CurrentProject.RequestforPayment.DateTwo.length === 0 ? moment(Date.now()) : moment(CurrentProject.RequestforPayment.DateTwo)}
                  onChange={(date, dateString) => {
                    UIStore.update((s) => {
                      s.CurrentProject.RequestforPayment.DateTwo = dateString;
                    });
                  }}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  1. We give this notice to you pursuant to the Master Loan
                  Agreement between ourselves and yourselves dated
                </p>
              </IonCol>
              <IonCol>
                <DatePicker

                  value={CurrentProject.RequestforPayment.DateThree.length === 0 ? moment(Date.now()) : moment(CurrentProject.RequestforPayment.DateThree)}
                  onChange={(date, dateString) => {
                    UIStore.update((s) => {
                      s.CurrentProject.RequestforPayment.DateThree = dateString;
                    });
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  2. Please take notice that we require the Capital in an amount
                  of R
                </p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.CapitalAmount}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.CapitalAmount = e.target.value }) }}
                  prefix="R"
                  name="CapitalAmount"
                  placeholder="000,000"
                />

              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>on:</p>
              </IonCol>
              <IonCol className="ion-text-left">
                <DatePicker
                  value={CurrentProject.RequestforPayment.Date4.length === 0 ? moment(Date.now()) : moment(CurrentProject.RequestforPayment.Date4)}
                  onChange={(date, dateString) => {
                    UIStore.update((s) => {
                      s.CurrentProject.RequestforPayment.Date4 = dateString;
                    });
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>in respect of the Drawdown and Repayment Schedule.</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>3. We hereby confirm that –</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  3.1 as at the date of this letter we comply, and will
                  thereafter continue to comply, with the obligations imposed on
                  us pursuant to the Master Loan Agreement;
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  3.2 we are aware that if we fail to comply with those
                  obligations we will not be entitled to the Capital requested
                  in this letter.
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>4. We also confirm that, as at the date of this letter –</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  4.1 no Event of Default has occurred or would occur as a
                  result of the Capital requested in this letter;
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  4.2 the Capital requested does not exceed total loan amount
                  approved as detailed in the Drawdown and Repayment Schedule.
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  5. We confirm that, the account details provided below are
                  accurate we also confirm that the account provided is active.
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  <strong>
                    (An account confirmation letter has been attached as
                    evidence)
                  </strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Beneficiary company name:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={
                    CurrentProject.RequestforPayment.BeneficiaryCompanyName
                  }
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.BeneficiaryCompanyName = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>
                  <strong>Beneficiary Bank Account Details </strong>
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Bank name:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.BankName}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.BankName = e.target.value }) }}
                  name="BankName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Branch name:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.BranchName}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.BranchName = e.target.value }) }}
                  name="BranchName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Account holder:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.AccountHolder}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.AccountHolder = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Account Type: </p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.AccountType}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.AccountType = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Account No:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.AccountNo}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.AccountNo = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Branch Code:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.BranchCode}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.BranchCode = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Yours faithfully,</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.SignedOffBy}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.SignedOffBy = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              {content}
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Capacity:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.RFPCapacity}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.RFPCapacity = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-left" style={{ paddingLeft: "10vw" }}>
                <p>Authority:</p>
              </IonCol>
              <IonCol>
                <Input
                  style={{ width: "9vw" }}
                  value={CurrentProject.RequestforPayment.RFPAuthority}
                  type="text"
                  onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RequestforPayment.RFPAuthority = e.target.value }) }}
                  name="TradingName"
                />
              </IonCol>
              <IonCol
                className="ion-text-left"
                style={{ paddingLeft: "4vw" }}
              >
                <p>
                  <strong>Signature: </strong>
                </p>
                {CurrentProject.RequestforPayment.RFPSignatureApplicant?.length > 5 ? (
                  <img src={CurrentProject.RequestforPayment.RFPSignatureApplicant} alt="signature" />
                ) : (
                  <SignatureCanvas
                    ref={(ref) => {
                      sigCanvas = ref;
                    }}
                    backgroundColor="#a9a9a9"
                    penColor="black"
                    canvasProps={{
                      width: 1300,
                      height: 200,
                      className: "sigCanvas",
                    }}
                    onEnd={() => {
                      let signature = sigCanvas.toDataURL();
                      UIStore.update((s) => {
                        s.CurrentProject.RequestforPayment.RFPSignatureApplicant =
                          signature;
                      });
                    }}
                  />
                )}

              </IonCol>;
            </IonRow>

            <IonRow>
              <IonCol class="ion-padding ion-text-center">
                <Button
                  size={"large"}
                  onClick={() => {
                    SubmitRFP();

                  }}
                  type="primary"
                >
                  Submit
                </Button>
              </IonCol>
            </IonRow>
          </IonCard>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default ClientBrief;
