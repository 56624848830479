import { Store } from "pullstate";

export const UIStore = new Store({
  CurrentProject: {
    id: "",
    ProjectName: "",
    CurrentStatus: "",
    CIDB: false,
    Ownership: false,
    ApplicationParticipation: false,
    MinimumWage: false,
    RingFenced: false,
    DevelopmentPlan: false,
    Training: false,
    Experience: false,
    DevelopmentAgreement: false,
    Assesment: false,
    MaterialsAgreement: false,
    CIDBCommentBox: "",
    OwnershipCommentBox: "",
    ApplicationParticipationBox: "",
    MinimumWageBox: "",
    RingFencedBox: "",
    DevelopmentPlanBox: "",
    TrainingBox: "",
    ExperienceBox: "",
    DevelopmentAgreementBox: "",
    AssesmentBox: "",
    MaterialsAgreementBox: "",
    ProjectNumber: "",
    ApplicationProjectName: "",
    BusinessName: "",
    BusinessOwners: "",
    Date: "",
    BusinessOwnerFirstName: "",
    BusinessOwnerSurname: "",
    BeneficiaryID: "",
    MaleFemale: "",
    Under36: "",
    Race: "",
    CellTell: "",
    PhysicalAddress: "",
    CompanyName: "",
    BbbeeRating: "",
    BlackOwnership: "",
    WomanOwnership: "",
    YouthOwnership: "",
    CompanyContact: "",
    BusinessSector: "",
    BusinessRegistrationDate: "",
    TradingStartDate: "",
    Classification: "",
    Services: "",
    GPSCoordinates: "",
    AnnualRevenue: "",
    FundingMethod: "",
    PermanentEmployees: "",
    SeasonalEmployees: "",
    TemporaryJobs: "",
    Date2: "", // not used
    BeneficiaryID2: "",//not used
    FemaleMale: "",
    Under36Years: "",
    Race1: "",
    BeneficiaryContact: "",
    TellCell: "",
    PhysicalAddress1: "",
    CompanyName1: "",
    BBBEERating1: "",
    BlackOwnership1: "",
    WomanOwnership1: "",
    YouthOwnership1: "",
    CompanyContact1: "",
    BusinessSector1: "",
    BusinessSize: "",
    BusinessRegistration: "",
    TradingStartDate1: "",
    ProductCommodity: "",
    FundingMethod1: "",
    PermanentEmployees1: "",
    SeasonalEmployees1: "",
    TempJobs: "",
    SmmeDate: "",
    LegalEntity: "",
    TaxClearance: "",
    PostalAddress: "",
    CIDBGrade: "",
    Title: "",
    Initials: "",
    IDPassport: "",
    CountryOfIssue: "",
    Designation: "",
    Telephone: "",
    Cellphone: "",
    Email: "",
    Projection: "",
    ValueOfProject: "",
    Client: "",
    ClientContact: "",
    ProjectDuration: "",
    ProjectStart: "",
    ProjectEnd: "",
    Location: "",
    PermanentJobs: "",
    BusinessProjects: "",
    Directors:"",
    SignatureDeclaration: "",
    DecDate: "",
    RepNameTitle: "",
    Consent: false,
    SignatureCredit: "",
    CAClient: "",
    RegistrationNumber: "",
    RepresentativeName: "",
    IdentityNumber: "",
    ClientName: "",
    OfficeBlock: "",
    Address: "",
    City: "",
    CADate: "",
    PostalCode: "",
    SigningLocation: "",
    Witnesses: "",
    WitnessesID:"",
    Witnesses2: "",
    Witnesses2ID: "",
    Witnesses3: "",
    Witnesses3ID: "",
    Witnesses4: "",
    Witnesses4ID: "",
    SignatureJV: "",
    PartnerName: "",
    BankConfirmation:"",
    JVRegistrationNumber: "",
    HeadOfficeLocation: "",
    JointVentureName: "",
    JVClientName: "",
    RepresentativeNameA: "",
    RepresentativeNameB: "",
    LocationOfSigning: "",
    JVDate: "",
    JointVenturePartnerName: "",
    NameTitle: "",
    Spif: "",
    BOQ: "",
    ImplementationPlan: "",
    Letterofappointment: "",
    BankingStatements: "",
    ManagementAccounts: "",
    Insurance: "",
    CSD: "",
    CIDBDoc: "",
    NHBRC: "",
    TaxClearanceDoc: "",
    Vat: "",
    DepartLabor: "",
    personnel: "",
    AllDirectors: "",
    MartialSTatus: "",
    BBEECert: "",
    Companyregdocuments: "",
    companyprofile: "",
    ProofAddress: "",
    ApplicationForm: "",
    BaselineForm: "",
    CIPCForm: "",
    CreditagreementForm: "",
    DeclarationForm: "",
    JVForm: "",
    EligibilityCriteriaForm: "",
    CompanyDescriptionExtendedServices: "",
    CompanyDescriptionExtendedArea: "",
    BusinessStructureFamilyBusiness: "",
    BusinessStructureOwners: "",
    BusinessStructureRolesResponsibilities: "",
    MarketTarget: "",
    MarketOffTakersCurrent: "",
    MarketOffTakersPipeline: "",
    MarketStrategyCurrent: "",
    GovernanceSystems: "",
    GovernanceFinancialManagement: "",
    GovernanceAccounting: "",
    GovernanceHR: "",
    SkillsGap: "",
    ReasonForIntervention: "",
    BusinessChallenges: "",
    OwnerShortMediumLongGoals: "",
    OwnerGoalsSuccess: "",
    OwnerGoalsSuccessionPlan: "",
    ProjectManagerName: "",
    ProjectDescription: "",
    CAAddendum: "",
    JVAddendum: "",
    DirectorKeyName: "",
    DirectorKeySurname: "",
    DirectorKeyIDNumber: "",
    DirectorKeyDesignation: "",
    PreviousFunding: "",
    FunderName: "",
    FunderContact: "",
    FundingTypePrevious: "",
    Repaid: "",
    OverdraftFacility: "",
    OverdraftAmount: "",
    FundingRequirement: "",
    ProposalCreatedBy: "",
    OrderValueIncl: "",
    PrimeInterestRate: "",
    AdministrationFeeIncl: "",
    LegalFeeIncl: "",
    InitiationFeeIncl: "",
    GrossProfit: "",
    FundingCost: "",
    FundingRepayment: "",
    RiskOfficerExceptioncomment: "",
    //Risk Assessment
    ProposalDate: "",
    ProposalSignedLocation: "",
    ProposalSignatureDeclaration: "",
    CompanyProfileExistance: "",
    CompanyProfileVerification: "",
    CompanyProfileComment: "",
    CompanyRegistrationExistance: "",
    CompanyRegistrationVerification: "",
    CompanyRegistrationComment: "",
    BbbeeCertificateExistance: "",
    BbbeeCertificateVerification: "",
    BbbeeCertificateComment: "",
    MaritalContractExistance: "",
    MaritalContractVerification: "",
    MaritalContractComment: "",
    OwnersIDExistance: "",
    OwnersIDVerification: "",
    OwnersIDComment: "",
    ProofOfAddressExistance: "",
    ProofOfAddressVerification: "",
    ProofOfAddressComment: "",
    AuthorizedPersonalExistance: "",
    AuthorizedPersonalVerification: "",
    AuthorizedPersonalComment: "",
    LetterGoodStandingExistance: "",
    LetterGoodStandingVerification: "",
    LetterGoodStandingComment: "",
    TaxClearanceExistanc: "",
    TaxClearanceVerification: "",
    TaxClearanceComment: "",
    NHBRCCertificateExistance: "",
    NHBRCCertificateVerification: "",
    NHBRCCertificateComment: "",
    CIDBCertExistance: "",
    CIDBCertVerification: "",
    CIDBCertComment: "",
    CSDRegExistance: "",
    CSDRegVerification: "",
    CSDRegComment: "",
    ProofOfInsuranceExistance: "",
    ProofOfInsuranceVerification: "",
    ProofOfInsuranceComment: "",
    FinancialsExistance: "",
    FinancialsVerification: "",
    FinancialsComment: "",
    BankConfirmationExistance: "",
    BankConfirmationVerification: "",
    BankConfirmationComment: "",
    BankingStatementsExistance: "",
    BankingStatementsVerification: "",
    BankingStatementsComment: "",
    DebtorsAgeExistance: "",
    DebtorsAgeVerification: "",
    DebtorsAgeComment: "",
    LetterAppointmentExistance: "",
    LetterAppointmentVerification: "",
    LetterAppointmentComment: "",
    SignedContractExistance: "",
    SignedContractVerification: "",
    SignedContractComment: "",
    ImplementationPlanExistance: "",
    ImplementationPlanVerification: "",
    ImplementationPlanComment: "",
    BillsQuantitiesExistance: "",
    BillsQuantitiesVerification: "",
    BillsQuantitiesComment: "",
    ProjectCompletionExistance: "",
    ProjectCompletionVerification: "",
    ProjectCompletionComment: "",
    Qualitative: "",
    //Vetting
    DateSiteVisit: "",
    SiteLocation: "",
    SiteWIP: "",
    SiteCondition: "",
    SitePhotoDescriptionOne: "",
    SitePhotoDescriptionTwo: "",
    SitePhotoDescriptionThree: "",
    SitePhotoOne: "",
    SitePhotoTwo: "",
    SitePhotoThree: "",
    SiteObservation: "",
    SiteAdditionalObservationDetails: "",
    SiteConstructionObservations: "",
    EntityConfirmation: "",
    EntityAuthority: "",
    EntitySignature: "",
    SiteSignatureDate: "",
    ProjectManagerSignature: "",
    DateClientVisit: "",
    DirectorOneAddress: "",
    YearsAtPremisesOne: "",
    PremisesTypeOne: "",
    DirectorOneObservations: "",
    DirectorTwoAddress: "",
    YearsAtPremisesTwo: "",
    PremisesTypeTwo: "",
    DirectorTwoObservations: "",
    ClientAddObservations: "",
    ClientPhotoOneDescription: "",
    ClientPhotoTwoDescription: "",
    ClientPhotoThreeDescription: "",
    ClientPhotoOne: "",
    ClientPhotoTwo: "",
    ClientPhotoThree: "",
    ClientEntity: "",
    ClientEntityAuthority: "",
    ClientEntitySignature: "",
    ClientSignatureDate: "",
    DateSuppliervisit: "",
    SupplierName: "",
    SupplierGoodsDescription: "",
    SupplierSiteLocation: "",
    SupplierAddress: "",
    SupplierPremisesType: "",
    SupplierObservations: "",
    SupplierType: "",
    TypeOfSupplierObservation: "",
    SupplierInfrustructure: "",
    SupplierStock: "",
    QualityControl: "",
    QualityStandards: "",
    CapacityQualityObservation: "",
    PaymentMethods: "",
    DaysToPay: "",
    DiscountConditions: "",
    SupplierSupportObservation: "",
    SupplierCIPC: "",
    SupplierBankLetter: "",
    SupplierRef: "",
    SupplierCert: "",
    SupplierPhotoDescriptionOne: "",
    SupplierPhotoDescriptionTwo: "",
    SupplierPhotoDescriptionThree: "",
    SupplierPhotoOne: "",
    SupplierPhotoTwo: "",
    SupplierPhotoThree: "",
    SupplierEntity: "",
    SupplierEntityAuthority: "",
    SupplierEntitySignature: "",
    SupplierDateSigning: "",
    InitialQualitativeComment: "",
    FinalQualitativeComment: "",
    CICComment: "",
    CurrentPermtEmpl:0,
    CurrentTempEmpl: 0,
    CompanyBackGround:"",
    PrimaryBusiness:"",
    EmploymentCreation:"",
    //TypeOfApplication
    DebtandCred: "",
    PaymentCert: "",
    CinvoicePaidbyPayMaster: "",
    ProjectImplentaionPlan: "",
    CletterofAppointment: "",
    CashFlowPro: "",
    SLA: "",
    BillofQuanties: "",
    MonthBankState: "",
    PoCl: "",
    EditorsAge: "",
    DebtAge: "",
    Cinvoice: "",
    SuppliersQ: "",
    RFQ: "",
    CPO: "",
    LeadName: "",
    LeadDescription: "",
    Proposal: {
      ProposalDate: "",
      ProposalCreatedBy: "",
      OrderValueIncl: "",
      PrimeInterestRate: "",
      AdministrationFeeIncl: "",
      LegalFeeIncl: "",
      InitiationFeeIncl: "",
      GrossProfit: "",
      FundingCost: "",
      FundingRepayment: "",
      ProposalSignedLocation: "",
      ProposalSignatureDeclaration: "",
    },
    RequestforPayment: {
      DateOne: "",
      DateTwo: "",
      DateThree: "",
      CapitalAmount: "",
      Date4: "",
      BeneficiaryCompanyName: "",
      BankName: "",
      BranchName: "",
      AccountHolder: "",
      AccountType: "",
      AccountNo: "",
      BranchCode: "",
      SignedOffBy: "",
      RFPSignature: "",
      RFPSignatureApplicant: "",
      RFPCapacity: "",
      RFPAuthority: "",
    },
    PaymentInstruction: {
       Client: "",
       ProjectDescription: "",
       TotalFundingApproved: "",
       DrawdownRequired: "",
       SupplierName: "",
       AmountPayable: "",
       Bank: "",
       AccountNumber: "",
       PFAName: "",
       FMEName: "",
       CEName: "",
       DocRequestForPayment: "",
       DocDrawdown: "",
       DocSuppliersQ: "",
       DocSupplierConfirmation: "",
       PFACapacity: "",
       FMESignature: "",
       CECapacity: "",
      CESignature: "",
      FMRSignature: "",
      FMRCapacity: "",
      PFAsignature:"",
    },
    //banking
    Resolution: "",
    ShareholdingConfirmation: "",
    TaxPin: "",
    BOQ2: "",
    CompletionCertificate: "",
    //NewEligibility
    PreviousSubmissionCIDB: "",
    CIDBApplying: "",
    SPAssistance: "",
    CIPCRegistered: "",
    FinancialStatementsSubmission: "",
    ResponsibleFinancialsRecord: "",
    FormalApplication: "",
    SPfundApplicationAssessmentDiligence: "",
    WillingTraining: "",
    WillingtoAttend: "",
    WillingMinWage: "",
    WillingMatsSpfund: "",
    WillingMatsSpfundCeding: "",
    WillingRingFenced: "",
  },
  ProjectsList: [],
  RiskAssessment: {
    id: "",
    CompanyProfileExistance: "",
    CompanyProfileVerification: "",
    CompanyProfileComment: "",
    CompanyRegistrationExistance: "",
    CompanyRegistrationVerification: "",
    CompanyRegistrationComment: "",
    BbbeeCertificateExistance: "",
    BbbeeCertificateVerification: "",
    BbbeeCertificateComment: "",
    MaritalContractExistance: "",
    MaritalContractVerification: "",
    MaritalContractComment: "",
    OwnersIDExistance: "",
    OwnersIDVerification: "",
    OwnersIDComment: "",
    ProofOfAddressExistance: "",
    ProofOfAddressVerification: "",
    ProofOfAddressComment: "",
    AuthorizedPersonalExistance: "",
    AuthorizedPersonalVerification: "",
    LetterGoodStandingExistance: "",
    LetterGoodStandingVerification: "",
    LetterGoodStandingComment: "",
    TaxClearanceExistance: "",
    TaxClearanceVerification: "",
    TaxClearanceComment: "",
    NHBRCCertificateExistance: "",
    NHBRCCertificateVerification: "",
    NHBRCCertificateComment: "",
    CIDBCertExistance: "",
    CIDBCertVerification: "",
    CIDBCertComment: "",
    CSDRegExistance: "",
    CSDRegVerification: "",
    CSDRegComment: "",
    ProofOfInsuranceExistance: "",
    ProofOfInsuranceVerification: "",
    ProofOfInsuranceComment: "",
    FinancialsExistance: "",
    FinancialsVerification: "",
    FinancialsComment: "",
    BankConfirmationExistance: "",
    BankConfirmationVerification: "",
    BankConfirmationComment: "",
    BankingStatementsExistance: "",
    BankingStatementsVerification: "",
    BankingStatementsComment: "",
    DebtorsAgeExistance: "",
    DebtorsAgeVerification: "",
    DebtorsAgeComment: "",
    LetterAppointmentExistance: "",
    LetterAppointmentVerification: "",
    LetterAppointmentComment: "",
    SignedContractExistance: "",
    SignedContractVerification: "",
    SignedContractComment: "",
    ImplementationPlanExistance: "",
    ImplementationPlanVerification: "",
    ImplementationPlanComment: "",
    BillsQuantitiesExistance: "",
    BillsQuantitiesVerification: "",
    BillsQuantitiesComment: "",
    ProjectCompletionExistance: "",
    ProjectCompletionVerification: "",
    ProjectCompletionComment: "",
    Affidavit: "",
    AffidavitComment: "",
    AuthorizedPersonalComment: "",
    ReasonForDecline: ""
  },
});

export enum ProjectStatus {
  PROCESSINGLEAD = "PROCESSINGLEAD",
  AWAITINGPM = "AWAITINGPM",
  AWAITINGINITIALDOCUMENTATIONSUBMISSION = "AWAITINGINITIALDOCUMENTATIONSUBMISSION",
  VERIFIEDDOCUMENTSFM = "VERIFIEDDOCUMENTSFM",
  AWAITINGPROPOSAL = "AWAITINGPROPOSAL",
  AWAITINGPROPOSALACCEPTANCE = "AWAITINGPROPOSALACCEPTANCE",
  PROPOSALACCEPTED = "PROPOSALACCEPTED",
  PROPOSALREJECTED = "PROPOSALREJECTED",
  AWAITINGFICA = "AWAITINGFICA",
  ELIGIBILITY = "ELIGIBILITY",
  APPLICATION = "APPLICATION",
  COMPLIANCE = "COMPLIANCE",
  DECLARATION = "DECLARATION",
  SCREENING = "SCREENING",
  SCREENINGEXCEPTION = "SCREENINGEXCEPTION",
  MARKSCREENED = "MARKSCREENED",
  APPOFFICER = "APPOFFICER",
  APPOFFICEREXCEPTION = "APPOFFICEREXCEPTION",
  COMPOFFICER = "COMPOFFICER",
  COMPOFFICEREXCEPTION = "COMPOFFICEREXCEPTION",
  ERC = "ERC",
  ERCOFFICEREXCEPTION = "ERCOFFICEREXCEPTION",
  AWAITINGVETTING = "AWAITINGVETTING",
  INITIALRISK = "INITIALRISK",
  PRESENTINGINITIALRISK = "PRESENTINGINITIALRISK",
  FMPROJECTVIABILITY = "FMPROJECTVIABILITY",
  FMRECCOMENDATION = "FMRECCOMENDATION",
  AWAITINGTEC = "AWAITINGTEC",
  TECEXCEPTION = "TECEXCEPTION",
  AWAITINGBRIEF = "AWAITINGBRIEF",
  AWAITINGFINALRISK = "AWAITINGFINALRISK",
  AWAITINGCIC = "AWAITINGCIC",
  AWAITINGCONTRACT = "AWAITINGCONTRACT",
  AWAITINGBASELINE = "AWAITINGBASELINE",
  AWAITINGREQUESTFORPAYMENT = "AWAITINGREQUESTFORPAYMENT",
  AWAITINGREQUESTFORPAYMENTAPPROVAL = "AWAITINGREQUESTFORPAYMENTAPPROVAL",
  AWAITINGPAYMENTINSTRUCTION = "AWAITINGPAYMENTINSTRUCTION",
  PAID = "PAID",     
 
}