import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Tooltip,
  Empty,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import {
  PlusOutlined,
  ProjectOutlined,
  ReloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { setcurrentproject } from "../store/actions";
import { UIStore } from "../store/UIStore";

const dateFormat = "YYYY/MM/DD";

const Projects: React.FC = (props: any) => {
  const { Step } = Steps;

  const [ProjectName, setProjectName] = useState("");

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};

  const createText = (
    <span>Create a project, if you do not have one created below.</span>
  );
  const loadText = <span>Load the project in its current status</span>;
  const buttonWidth = 70;

  const [ReasonForDecline, setReasonForDecline] = useState("");
  const [isDeclineModalVisible, setisDeclineModalVisible] = useState(false);
  const [projects, setprojects] = useState<any[]>([]);
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  const handleDeclineOk = (object: any) => {
    setisDeclineModalVisible(false);
  };

  const openNotification = () => {
    notification.open({
      message: "Callback Request Successful",
      description:
        "Your request has will be processed shortly.",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  const handleDeclineCancel = () => {
    setisDeclineModalVisible(false);
  };

  let InitialProject = {
    id: "",
    ProjectName: "",
    CurrentStatus: "",
    CIDB: false,
    Ownership: false,
    ApplicationParticipation: false,
    MinimumWage: false,
    RingFenced: false,
    DevelopmentPlan: false,
    Training: false,
    Experience: false,
    DevelopmentAgreement: false,
    Assesment: false,
    MaterialsAgreement: false,
    CIDBCommentBox: "",
    OwnershipCommentBox: "",
    ApplicationParticipationBox: "",
    MinimumWageBox: "",
    RingFencedBox: "",
    DevelopmentPlanBox: "",
    TrainingBox: "",
    ExperienceBox: "",
    DevelopmentAgreementBox: "",
    AssesmentBox: "",
    MaterialsAgreementBox: "",
    ProjectNumber: "",
    ApplicationProjectName: "",
    BusinessName: "",
    BusinessOwners: "",
    Date: "",
    BusinessOwnerFirstName: "",
    BusinessOwnerSurname: "",
    BeneficiaryID: "",
    MaleFemale: "",
    Under36: "",
    Race: "",
    CellTell: "",
    PhysicalAddress: "",
    CompanyName: "",
    BbbeeRating: "",
    BlackOwnership: "",
    WomanOwnership: "",
    YouthOwnership: "",
    CompanyContact: "",
    BusinessSector: "",
    BusinessRegistrationDate: "",
    TradingStartDate: "",
    Classification: "",
    Services: "",
    GPSCoordinates: "",
    AnnualRevenue: "",
    FundingMethod: "",
    PermanentEmployees: "",
    SeasonalEmployees: "",
    TemporaryJobs: "",
    Date2: "",
    BeneficiaryID2: "",
    FemaleMale: "",
    Under36Years: "",
    Race1: "",
    BeneficiaryContact: "",
    TellCell: "",
    PhysicalAddress1: "",
    CompanyName1: "",
    BBBEERating1: "",
    BlackOwnership1: "",
    WomanOwnership1: "",
    YouthOwnership1: "",
    CompanyContact1: "",
    BusinessSector1: "",
    BusinessSize: "",
    BusinessRegistration: "",
    TradingStartDate1: "",
    ProductCommodity: "",
    FundingMethod1: "",
    PermanentEmployees1: "",
    SeasonalEmployees1: "",
    TempJobs: "",
    SmmeDate: "",
    LegalEntity: "",
    TaxClearance: "",
    PostalAddress: "",
    CIDBGrade: "",
    Title: "",
    Initials: "",
    IDPassport: "",
    CountryOfIssue: "",
    Designation: "",
    Telephone: "",
    Cellphone: "",
    Email: "",
    Projection: "",
    ValueOfProject: "",
    Client: "",
    ClientContact: "",
    ProjectDuration: "",
    ProjectStart: "",
    ProjectEnd: "",
    Location: "",
    PermanentJobs: "",
    BusinessProjects: "",
    Directors: "",
    SignatureDeclaration: "",
    DecDate: "",
    RepNameTitle: "",
    Consent: false,
    SignatureCredit: "",
    CAClient: "",
    RegistrationNumber: "",
    RepresentativeName: "",
    IdentityNumber: "",
    ClientName: "",
    OfficeBlock: "",
    Address: "",
    City: "",
    CADate: "",
    PostalCode: "",
    SigningLocation: "",
    Witnesses: "",
    WitnessesID: "",
    Witnesses2: "",
    Witnesses2ID: "",
    Witnesses3: "",
    Witnesses3ID: "",
    Witnesses4: "",
    Witnesses4ID: "",
    SignatureJV: "",
    PartnerName: "",
    BankConfirmation: "",
    JVRegistrationNumber: "",
    HeadOfficeLocation: "",
    JointVentureName: "",
    JVClientName: "",
    RepresentativeNameA: "",
    RepresentativeNameB: "",
    LocationOfSigning: "",
    JVDate: "",
    JointVenturePartnerName: "",
    NameTitle: "",
    Spif: "",
    BOQ: "",
    ImplementationPlan: "",
    Letterofappointment: "",
    BankingStatements: "",
    ManagementAccounts: "",
    Insurance: "",
    CSD: "",
    CIDBDoc: "",
    NHBRC: "",
    TaxClearanceDoc: "",
    Vat: "",
    DepartLabor: "",
    personnel: "",
    AllDirectors: "",
    MartialSTatus: "",
    BBEECert: "",
    Companyregdocuments: "",
    companyprofile: "",
    ProofAddress: "",
    ApplicationForm: "",
    BaselineForm: "",
    CIPCForm: "",
    CreditagreementForm: "",
    DeclarationForm: "",
    JVForm: "",
    EligibilityCriteriaForm: "",
    CompanyDescriptionExtendedServices: "",
    CompanyDescriptionExtendedArea: "",
    BusinessStructureFamilyBusiness: "",
    BusinessStructureOwners: "",
    BusinessStructureRolesResponsibilities: "",
    MarketTarget: "",
    MarketOffTakersCurrent: "",
    MarketOffTakersPipeline: "",
    MarketStrategyCurrent: "",
    GovernanceSystems: "",
    GovernanceFinancialManagement: "",
    GovernanceAccounting: "",
    GovernanceHR: "",
    SkillsGap: "",
    ReasonForIntervention: "",
    BusinessChallenges: "",
    OwnerShortMediumLongGoals: "",
    OwnerGoalsSuccess: "",
    OwnerGoalsSuccessionPlan: "",
    ProjectManagerName: "",
    ProjectDescription: "",
    CAAddendum: "",
    JVAddendum: "",
    DirectorKeyName: "",
    DirectorKeySurname: "",
    DirectorKeyIDNumber: "",
    DirectorKeyDesignation: "",
    PreviousFunding: "",
    FunderName: "",
    FunderContact: "",
    FundingTypePrevious: "",
    Repaid: "",
    OverdraftFacility: "",
    OverdraftAmount: "",
    FundingRequirement: "",
    ProposalCreatedBy: "",
    OrderValueIncl: "",
    PrimeInterestRate: "",
    AdministrationFeeIncl: "",
    LegalFeeIncl: "",
    InitiationFeeIncl: "",
    GrossProfit: "",
    FundingCost: "",
    FundingRepayment: "",
    RiskOfficerExceptioncomment: "",
    //Risk Assessment
    ProposalDate: "",
    ProposalSignedLocation: "",
    ProposalSignatureDeclaration: "",
    CompanyProfileExistance: "",
    CompanyProfileVerification: "",
    CompanyProfileComment: "",
    CompanyRegistrationExistance: "",
    CompanyRegistrationVerification: "",
    CompanyRegistrationComment: "",
    BbbeeCertificateExistance: "",
    BbbeeCertificateVerification: "",
    BbbeeCertificateComment: "",
    MaritalContractExistance: "",
    MaritalContractVerification: "",
    MaritalContractComment: "",
    OwnersIDExistance: "",
    OwnersIDVerification: "",
    OwnersIDComment: "",
    ProofOfAddressExistance: "",
    ProofOfAddressVerification: "",
    ProofOfAddressComment: "",
    AuthorizedPersonalExistance: "",
    AuthorizedPersonalVerification: "",
    AuthorizedPersonalComment: "",
    LetterGoodStandingExistance: "",
    LetterGoodStandingVerification: "",
    LetterGoodStandingComment: "",
    TaxClearanceExistanc: "",
    TaxClearanceVerification: "",
    TaxClearanceComment: "",
    NHBRCCertificateExistance: "",
    NHBRCCertificateVerification: "",
    NHBRCCertificateComment: "",
    CIDBCertExistance: "",
    CIDBCertVerification: "",
    CIDBCertComment: "",
    CSDRegExistance: "",
    CSDRegVerification: "",
    CSDRegComment: "",
    ProofOfInsuranceExistance: "",
    ProofOfInsuranceVerification: "",
    ProofOfInsuranceComment: "",
    FinancialsExistance: "",
    FinancialsVerification: "",
    FinancialsComment: "",
    BankConfirmationExistance: "",
    BankConfirmationVerification: "",
    BankConfirmationComment: "",
    BankingStatementsExistance: "",
    BankingStatementsVerification: "",
    BankingStatementsComment: "",
    DebtorsAgeExistance: "",
    DebtorsAgeVerification: "",
    DebtorsAgeComment: "",
    LetterAppointmentExistance: "",
    LetterAppointmentVerification: "",
    LetterAppointmentComment: "",
    SignedContractExistance: "",
    SignedContractVerification: "",
    SignedContractComment: "",
    ImplementationPlanExistance: "",
    ImplementationPlanVerification: "",
    ImplementationPlanComment: "",
    BillsQuantitiesExistance: "",
    BillsQuantitiesVerification: "",
    BillsQuantitiesComment: "",
    ProjectCompletionExistance: "",
    ProjectCompletionVerification: "",
    ProjectCompletionComment: "",
    Qualitative: "",
    //Vetting
    DateSiteVisit: "",
    SiteLocation: "",
    SiteWIP: "",
    SiteCondition: "",
    SitePhotoDescriptionOne: "",
    SitePhotoDescriptionTwo: "",
    SitePhotoDescriptionThree: "",
    SitePhotoOne: "",
    SitePhotoTwo: "",
    SitePhotoThree: "",
    SiteObservation: "",
    SiteAdditionalObservationDetails: "",
    SiteConstructionObservations: "",
    EntityConfirmation: "",
    EntityAuthority: "",
    EntitySignature: "",
    SiteSignatureDate: "",
    ProjectManagerSignature: "",
    DateClientVisit: "",
    DirectorOneAddress: "",
    YearsAtPremisesOne: "",
    PremisesTypeOne: "",
    DirectorOneObservations: "",
    DirectorTwoAddress: "",
    YearsAtPremisesTwo: "",
    PremisesTypeTwo: "",
    DirectorTwoObservations: "",
    ClientAddObservations: "",
    ClientPhotoOneDescription: "",
    ClientPhotoTwoDescription: "",
    ClientPhotoThreeDescription: "",
    ClientPhotoOne: "",
    ClientPhotoTwo: "",
    ClientPhotoThree: "",
    ClientEntity: "",
    ClientEntityAuthority: "",
    ClientEntitySignature: "",
    ClientSignatureDate: "",
    DateSuppliervisit: "",
    SupplierName: "",
    SupplierGoodsDescription: "",
    SupplierSiteLocation: "",
    SupplierAddress: "",
    SupplierPremisesType: "",
    SupplierObservations: "",
    SupplierType: "",
    TypeOfSupplierObservation: "",
    SupplierInfrustructure: "",
    SupplierStock: "",
    QualityControl: "",
    QualityStandards: "",
    CapacityQualityObservation: "",
    PaymentMethods: "",
    DaysToPay: "",
    DiscountConditions: "",
    SupplierSupportObservation: "",
    SupplierCIPC: "",
    SupplierBankLetter: "",
    SupplierRef: "",
    SupplierCert: "",
    SupplierPhotoDescriptionOne: "",
    SupplierPhotoDescriptionTwo: "",
    SupplierPhotoDescriptionThree: "",
    SupplierPhotoOne: "",
    SupplierPhotoTwo: "",
    SupplierPhotoThree: "",
    SupplierEntity: "",
    SupplierEntityAuthority: "",
    SupplierEntitySignature: "",
    SupplierDateSigning: "",
    InitialQualitativeComment: "",
    FinalQualitativeComment: "",
    CICComment: "",
    CurrentPermtEmpl: 0,
    CurrentTempEmpl: 0,
    CompanyBackGround: "",
    PrimaryBusiness: "",
    EmploymentCreation: "",
    //TypeOfApplication
    DebtandCred: "",
    PaymentCert: "",
    CinvoicePaidbyPayMaster: "",
    ProjectImplentaionPlan: "",
    CletterofAppointment: "",
    CashFlowPro: "",
    SLA: "",
    BillofQuanties: "",
    MonthBankState: "",
    PoCl: "",
    EditorsAge: "",
    DebtAge: "",
    Cinvoice: "",
    SuppliersQ: "",
    RFQ: "",
    CPO: "",
    LeadName: "",
    LeadDescription: "",
    Proposal: {
      ProposalDate: "",
      ProposalCreatedBy: "",
      OrderValueIncl: "",
      PrimeInterestRate: "",
      AdministrationFeeIncl: "",
      LegalFeeIncl: "",
      InitiationFeeIncl: "",
      GrossProfit: "",
      FundingCost: "",
      FundingRepayment: "",
      ProposalSignedLocation: "",
      ProposalSignatureDeclaration: "",
    },
    RequestforPayment: {
      DateOne: "",
      DateTwo: "",
      DateThree: "",
      CapitalAmount: "",
      Date4: "",
      BeneficiaryCompanyName: "",
      BankName: "",
      BranchName: "",
      AccountHolder: "",
      AccountType: "",
      AccountNo: "",
      BranchCode: "",
      SignedOffBy: "",
      RFPSignature: "",
      RFPSignatureApplicant: "",
      RFPCapacity: "",
      RFPAuthority: "",
    },
    PaymentInstruction: {
      Client: "",
      ProjectDescription: "",
      TotalFundingApproved: "",
      DrawdownRequired: "",
      SupplierName: "",
      AmountPayable: "",
      Bank: "",
      AccountNumber: "",
      PFAName: "",
      FMEName: "",
      CEName: "",
      DocRequestForPayment: "",
      DocDrawdown: "",
      DocSuppliersQ: "",
      DocSupplierConfirmation: "",
      PFACapacity: "",
      FMESignature: "",
      CECapacity: "",
      CESignature: "",
      FMRSignature: "",
      FMRCapacity: "",
      PFAsignature: "",
    },
    //banking
    Resolution: "",
    ShareholdingConfirmation: "",
    TaxPin: "",
    BOQ2: "",
    CompletionCertificate: "",
    //NewEligibility
    PreviousSubmissionCIDB: "",
    CIDBApplying: "",
    SPAssistance: "",
    CIPCRegistered: "",
    FinancialStatementsSubmission: "",
    ResponsibleFinancialsRecord: "",
    FormalApplication: "",
    SPfundApplicationAssessmentDiligence: "",
    WillingTraining: "",
    WillingtoAttend: "",
    WillingMinWage: "",
    WillingMatsSpfund: "",
    WillingMatsSpfundCeding: "",
    WillingRingFenced: "",
  };

  useIonViewDidEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/users/projectlist/" + user.id
      )
      .then(function (response) {
        //23 items
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });
  const [IsCICVisible, setIsCICVisible] = useState(false);
  const [globalObject, setGlobalObject] = useState({});
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Project Name");
  // const [modalText2, setModalText2] = React.useState('Some other input');

  const showModal = () => {
    setVisible(true);
  };

  /*
  useEffect(() => {
    console.log(CurrentProject);
  }, [CurrentProject]);
*/

  function loadProject(projectobject: any) {
    UIStore.update((s) => {
      s.CurrentProject = projectobject;
    });
    console.log(CurrentProject);
    console.log(CurrentProject.CurrentStatus);

    switch (projectobject.CurrentStatus) {
      case "AWAITINGPROPOSALACCEPTANCE":
        props.history.push("/Proposal");
        break;
      case "ELIGIBILITY":
        props.history.push("/EligibilityCriteria");
        break;

      case "AWAITINGINITIALDOCUMENTATIONSUBMISSION":
        props.history.push("/Typeofapplication");
        break;
      case "COMPLIANCE":
        props.history.push("/ComplienceChecklist");
        break;

      case "APPLICATION":
        props.history.push("/ApplicationForm");
        break;
      case "DECLARATION":
        props.history.push("/DeclarationPage");
        break;

      default:
        props.history.push("/Projects");
        break;
    }
  }

  const CreateProject = async () => {
    setModalText("Processing Request");
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));

    try {
      const request = await axios
        .post("https://dev.northroom.co.za/api/spfund/users/createproject", {
          userid: user.id,
          ProjectName: ProjectName,
        })
        .then((response) => {
          message.success(response.data);
          console.log(response);
          // props.history.push("/NewProject");

          UIStore.update((s) => {
            s.CurrentProject = InitialProject;
          });

          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/users/projectlist/" +
              user.id
            )
            .then(function (response) {
              //23 items

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error: any) {
      message.error("An error occurred");
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const checkForException = (object: any) => {
    switch (object.CurrentStatus) {
      case "APPOFFICEREXCEPTION":
        return object.AppOfficerExceptioncomment;

      case "ERCOFFICEREXCEPTION ":
        return object.RiskOfficerExceptioncomment;

      case "COMPOFFICEREXCEPTION ":
        return object.CompOfficerExceptioncomment;

      case "TECEXCEPTION":
        return object.Tecexceptioncoment;
      //ScreeningOfficerExceptioncomment
      default:
        break;
    }
  };

  const showCIC = () => {
    setIsCICVisible(true);
  };
  const handleCICCancel = () => {
    setIsCICVisible(false);
  };

  const checkUser = (dataobject: any) => {
    let content = <></>;
    if (user.UserType !== "APPLICANT") {
      content = (
        <IonCol>
          <Button
            type="primary"
            onClick={() => {
              showCIC();
              setGlobalObject(dataobject);
            }}
          >
            CIC
          </Button>
        </IonCol>
      );
    }
    return content;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="My Projects" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol>
            {/* <Card>
              <Tooltip
                style={{ width: buttonWidth, marginLeft: buttonWidth * 4 + 24 }}
                placement="right"
                title={createText}
              >
                <Button
                  type="primary"
                  onClick={showModal}
                  icon={<PlusOutlined />}
                >
                  Create New Project
                </Button>
              </Tooltip>
              <Modal
                title="New Project"
                visible={visible}
                onOk={CreateProject}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
              >
                <p>{modalText}</p>
                <Input
                  value={ProjectName}
                  size="large"
                  placeholder=""
                  name={"ProjectName"}
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                  prefix={<ProjectOutlined />}
                />

                <br></br>
                <br></br>
            
              </Modal>
            </Card> */}
          </IonCol>
        </IonRow>
        {data.length === 0 ? (
          <Empty imageStyle={{ alignSelf: "center" }} />
        ) : (
          <List
            dataSource={[{}]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>
                          Project Application Name:
                          <Tag color="blue">{dataobject.LeadName}</Tag>
                        </p>
                      }
                    />
                    <IonCol>
                      <Tag color="geekblue">
                        Project Stage: {dataobject.CurrentStatus}
                      </Tag>
                    </IonCol>

                    <IonCol>
                      <Tooltip
                        style={{
                          width: buttonWidth,
                          marginLeft: buttonWidth * 4 + 24,
                        }}
                        placement="right"
                        title={loadText}
                      >
                        <Button
                          onClick={() => {
                            loadProject(dataobject);
                          }}
                          type="primary"
                          icon={<ReloadOutlined />}
                        >
                          Load
                        </Button>
                      </Tooltip>
                    </IonCol>
                    <IonCol>
                      <Button type="primary" onClick={openNotification}>
                        Request Callback
                      </Button>
                    </IonCol>
                    <IonCol>
                      <Tooltip
                        style={{
                          width: buttonWidth,
                          marginLeft: buttonWidth * 4 + 24,
                        }}
                        placement="right"
                        title={"Contact documents for your project"}
                      >
                        {
                          <Button
                            onClick={() => {
                              UIStore.update((s) => {
                                s.CurrentProject = dataobject;
                                props.history.push("/CreditAgreement");
                              });
                            }}
                            type="primary"
                            disabled={
                              dataobject.CurrentStatus === "AWAITINGCONTRACT"
                                ? false
                                : true
                            }
                          >
                            Contract Documents
                          </Button>
                        }
                      </Tooltip>
                    </IonCol>
                    <IonCol>
                      <Tooltip
                        style={{
                          width: buttonWidth,
                          marginLeft: buttonWidth * 4 + 24,
                        }}
                        placement="right"
                        title={"Conduct RFP"}
                      >
                        <Button
                          onClick={() => {
                            console.log(dataobject);
                            UIStore.update((s) => {
                              s.CurrentProject = dataobject;
                            });
                            if (dataobject.RequestforPayment === null) {
                              UIStore.update((s) => {
                                s.CurrentProject.RequestforPayment = {
                                  DateOne: "",
                                  DateTwo: "",
                                  DateThree: "",
                                  CapitalAmount: "",
                                  Date4: "",
                                  BeneficiaryCompanyName: "",
                                  BankName: "",
                                  BranchName: "",
                                  AccountHolder: "",
                                  AccountType: "",
                                  AccountNo: "",
                                  BranchCode: "",
                                  SignedOffBy: "",
                                  RFPSignature: "",
                                  RFPSignatureApplicant: "",
                                  RFPCapacity: "",
                                  RFPAuthority: "",
                                };
                              });
                            }
                            props.history.push("/RFP");
                          }}
                          type="primary"
                          disabled={
                            dataobject.CurrentStatus ===
                              "AWAITINGREQUESTFORPAYMENT"
                              ? false
                              : true
                          }
                        >
                          Conduct RFP
                        </Button>
                      </Tooltip>
                    </IonCol>
                    {checkUser(dataobject)}

                    <IonCol>
                      <Tag color="red">{checkForException(dataobject)}</Tag>
                    </IonCol>
                  </List.Item>
                </>
              ))
            }
          />
        )}
        <Modal
          title="CIC Approval Status"
          visible={IsCICVisible}
          onOk={() => {
            handleCICCancel();
          }}
          onCancel={handleCICCancel}
        >
          <TextArea
            //@ts-ignore
            value={globalObject.VotingComment}
            placeholder=""
            rows={4}
            disabled
          />
        </Modal>

        <Modal
          title="Decline Reason"
          visible={isDeclineModalVisible}
          onOk={handleDeclineOk}
          onCancel={handleDeclineCancel}
          style={{ paddingBottom: "0px", height: 1000 }}
          width={900}
        >
          <TextArea
            rows={6}
            //@ts-ignore
            value={CurrentProject.ReasonForDecline}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default Projects;
