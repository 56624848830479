import { store } from "./store";
import axios from "axios";
import { message } from "antd";

export function LoginAPI(username: string, password: string) {
  message.loading({ content: "Loading..." });
  const API = "https://dev.northroom.co.za/api/spfund";
  axios
    .post(API + "/users/login", {
      email: username,
      password: password,
    })
    .then(function (response) {
      console.log();
      if (response.data.status == false) {
        message.error("Please check your Login credentials");
      }
      if (response.data.status == true) {
        setuserObject(response.data.user);
        Login();
        window.location.href = "/";
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  store.setloginstatus(true);
}
export function RegisterAPI(
  username: string,
  password1: string,
  password2: string,
  entireobject: any
) {
  // console.log(entireobject);
  // return;

  if (password1 !== password2) {
    message.error("Passwords do not match!");
    return;
  }
  message.loading({ content: "Submitting Register..." });
  const API = "https://dev.northroom.co.za";
  axios
    .post(API + "/api/spfund/users/registeruser", {
      email: username,
      password: password1,
      ProfilePicture: entireobject.ProfilePicture,
      ID: entireobject.ID,
      FullName: entireobject.FullName,
    })
    .then(function (response) {
      console.log(response.data);
      setuserObject(response.data);

      message.success("Registration Successful");
      Login();

      window.location.href = "/";
    })
    .catch(function (error) {
      message.error(error);
    });
}
export function RegisterAPIRoles(entireobject: any, lead: any) {


  if (entireobject.password1 !== entireobject.password2) {
    message.error("Passwords do not match!");
    return;
  }
  console.log(entireobject)
  message.loading({ content: "Submitting Register..." });
  const API = "https://dev.northroom.co.za";
  try {
    axios
      .post(API + "/api/spfund/users/registeruser", {
        email: entireobject.Email.toLowerCase(),
        password: entireobject.Password,
        ProfilePicture: entireobject.ProfilePicture,
        ID: entireobject.ID,
        FullName: entireobject.FullName,
        cellphone: entireobject.cellphone,
        CompanyName: entireobject.CompanyName,
        FundingRequirement: entireobject.FundingRequirement,
        PurchaseDocument: entireobject.PurchaseDocument,
        FundingRequirementSummary: entireobject.FundingRequirementSummary,
      })
      .then(function (response) {
        console.log(response.data);
        setuserObject(response.data);

        //  axios
        //   .post("https://dev.northroom.co.za/api/spfund/users/createproject", {
        //     //@ts-ignore
        //     userid: response.data.id,
        //     LeadName: lead.LeadName,
        //     LeadDescription: lead.LeadDescription
        //   })
        //    .then((response) => {
        //      console.log(response)
        //      Login()
        //      window.location.href = "/Projects";
        //    });
        Login()
        window.location.href = "/Projects";
        message.success("Registration Successful");
      });
  } catch (error: any) {
    console.log(error);
  }
  return true;

}

export function Login() {
  store.setloginstatus(true);
}
export function Logout() {
  store.setloginstatus(false);
  window.location.href = "/";
}
export function checklogin(): boolean {
  let storedLoginstate = localStorage.getItem("LoggedinState");
  console.log(storedLoginstate);

  if (storedLoginstate == "true") {
    return true;
  }
  return store.getloginstate();
}
export function getuserObject() {
  store.getuserobject();
}
export function getcurrentproject() {
  return store.getcurrentproject();
}
export function setcurrentproject(projectobject: any) {
  store.setcurrentProject(projectobject);
  console.log("SettingCurrentProject:" + projectobject);
}
export function setuserObject(userobject: any) {
  store.setuser(userobject);
}

export function checkProjectEmpty(object: any) {
  try {
    if (object.id === null || object.id.length <= 0) {
      return true;
    } else {
      return false;
    }
  } catch {
    return true;
  }
}

// for turning docs into array for Doc viewer
export function getDocsUriArray(project: any) {

  let docs = [
    {
      uri: project.ApplicationForm
    },
    { uri: project.BaselineForm },
    { uri: project.CIPCForm },
    { uri: project.CreditagreementForm },
    { uri: project.DeclarationForm },
    { uri: project.JVForm },
    { uri: project.EligibilityCriteriaForm },
    { uri: project.companyprofile },
    { uri: project.Companyregdocuments },
    { uri: project.BBEECert },
    { uri: project.MartialSTatus },
    { uri: project.AllDirectors },
    { uri: project.ProofAddress },
    { uri: project.personnel },
    { uri: project.DepartLabor },
    { uri: project.Vat },
    { uri: project.TaxClearanceDoc },
    { uri: project.NHBRC },
    { uri: project.CSD },
    { uri: project.Insurance },
    { uri: project.ManagementAccounts },
    { uri: project.BankingStatements },
    { uri: project.Letterofappointment },
    { uri: project.ImplementationPlan },
    { uri: project.BOQ },
    { uri: project.Spif },
    { uri: project.CPO },
    { uri: project.RFQ },
    { uri: project.SuppliersQ },
    { uri: project.Cinvoice },
    { uri: project.DebtAge },
    { uri: project.EditorsAge },
    { uri: project.PoCl },
    { uri: project.MonthBankState },
    { uri: project.BillofQuanties },
    { uri: project.SLA },
    { uri: project.CashFlowPro },
    { uri: project.CletterofAppointment },
    { uri: project.ProjectImplentaionPlan },
    { uri: project.CinvoicePaidbyPayMaster },
    { uri: project.PaymentCert },
    { uri: project.DebtandCred },
    { uri: project.Resolution },
    { uri: project.ShareholdingConfirmation },
    { uri: project.TaxPin },
    { uri: project.BOQ2 },
    { uri: project.CompletionCertificate },
  ];

  let temp = [];

  for (let i of docs)
    i && temp.push(i);

  docs = temp;

  return docs;
}
export function getDocsArray(project: any) {

  let docs = [
    {
      uri: project.ApplicationForm,
      name: "Application Form"
    },
    { uri: project.BaselineForm, name: "Baseline Form" },
    { uri: project.CIPCForm, name: "CIPC Form" },
    { uri: project.CreditagreementForm, name: "Credit Agreement" },
    { uri: project.DeclarationForm, name: "Declaration Form" },
    { uri: project.JVForm, name: "Joint Venture Form" },
    { uri: project.EligibilityCriteriaForm, name: "Eligibility Criteria Form" },
    { uri: project.companyprofile, name: "Company Profile" },
    { uri: project.Companyregdocuments, name: "Company Registration Documents" },
    { uri: project.BBEECert, name: "BBEE Certification" },
    { uri: project.MartialSTatus, name: "Martial Status" },
    { uri: project.AllDirectors, name: "All Directors" },
    { uri: project.ProofAddress, name: "Proof  of Address" },
    { uri: project.personnel, name: "Personnel" },
    { uri: project.DepartLabor, name: "Depart Labor" },
    { uri: project.Vat, name: "Vat" },
    { uri: project.TaxClearanceDoc, name: "Tax Clearance" },
    { uri: project.NHBRC, name: "NHBRC" },
    { uri: project.CSD, name: "CSD" },
    { uri: project.Insurance, name: "Insurance" },
    { uri: project.ManagementAccounts, name: "Management Accounts" },
    { uri: project.BankingStatements, name: "Banking Statements" },
    { uri: project.Letterofappointment, name: "Letter of Appointment" },
    { uri: project.ImplementationPlan, name: "Implementaion Plan" },
    { uri: project.BOQ, name: "BOQ" },
    { uri: project.Spif, name: "Spif" },
    { uri: project.CPO, name: "CPO" },
    { uri: project.RFQ, name: "RFQ" },
    { uri: project.SuppliersQ, name: "Suppliers Quote" },
    { uri: project.Cinvoice, name: "Credit Invoice" },
    { uri: project.DebtAge, name: "DebtAge" },
    { uri: project.EditorsAge, name: "Editors age" },
    { uri: project.PoCl, name: "PoCl" },
    { uri: project.MonthBankState, name: "Month Bank State" },
    { uri: project.BillofQuanties, name: "Bill of Quanties" },
    { uri: project.SLA, name: "SLA" },
    { uri: project.CashFlowPro, name: "Cash Flow Pro" },
    { uri: project.CletterofAppointment, name: "Copy of Letter of Appointment" },
    { uri: project.ProjectImplentaionPlan, name: "Project Implentation Plan" },
    { uri: project.CinvoicePaidbyPayMaster, name: "Copy of Invoice paid by Pay Master" },
    { uri: project.PaymentCert, name: "Payment Certification" },
    { uri: project.DebtandCred, name: "Debt and Cred" },
    { uri: project.Resolution, name: "Resolution" },
    { uri: project.ShareholdingConfirmation, name: "Shareholding Confirmation" },
    { uri: project.TaxPin, name: "Tax Pin" },
    { uri: project.BOQ2, name: "BOQ2" },
    { uri: project.CompletionCertificate, name: "Completion Certificate" },
  ];

  let temp = [];

  for (let i of docs)
    i.uri && temp.push(i);

  docs = temp;


  return docs;
}