import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Tooltip,
  Empty,
  Modal,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag, Steps } from "antd";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { ProjectStatus, UIStore } from "../store/UIStore";
const dateFormat = "YYYY/MM/DD";

const CicStatus: React.FC = (props: any) => {

  const [data, setdata] = useState<any[]>([]);
  const { Step } = Steps;
  const { TextArea } = Input;

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  useIonViewDidEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/fetch/cicstatus")
      .then(function (response) {
        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  async function saveCIC(object: any) {
    object.CurrentStatus = ProjectStatus.AWAITINGCONTRACT;
    object.CICComment = CICComment;
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/ProjectUpdate",
          object
        )
        .then((response) => {
          console.log(response);
          props.history.push("/ProjectManager");
        });
    } catch (error: any) {
      console.log(error)
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [CICComment, setCICComment] = useState("");
  const [globalObject, setGlobalObject] = useState({});
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const showModal = () => {
    setIsModalVisible(true);
  };

  function handleOk(object: any) {
    saveCIC(object);
    setIsModalVisible(false);
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleComment = (e: any) => {
    setCICComment(e.target.value);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="CIC Status" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <>
          {data.length === 0 ? (
            <Empty imageStyle={{ alignSelf: "center" }} />
          ) : (
            <List
              dataSource={[
                {
                  name: "Application 1",
                },
              ]}
              bordered
              renderItem={(item) =>
                data.map((dataobject) => (
                  <>
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={logo} />}
                        title={
                          <p>Project Application ID:{dataobject.LeadName}</p>
                        }
                        description={dataobject.LeadDescription}
                      />

                      <Row style={{ paddingLeft: "50px" }}>
                        <IonCol>
                          <Button
                            onClick={() => {
                              showModal();
                              setGlobalObject(dataobject);
                            }}
                            type="primary"
                          >
                            CIC Comment
                          </Button>
                        </IonCol>

                      </Row>
                    </List.Item>
                  </>
                ))
              }
            />
          )}
        </>
        <Modal
          title="Provide CIC Comment"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button
              onClick={(e) => {
                handleOk(globalObject);
              }}
              type="primary"
            >
              Submit
            </Button>,
          ]}
          style={{ paddingBottom: "0px" }}
        >
          <TextArea
            value={CICComment}
            placeholder="CIC Comment"
            rows={4}
            onChange={(e) => {
              handleComment(e);
            }}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default CicStatus;
